import RequestComponent from "../../component/Request";
const RequestContainer = () => {
  return (
    <>
      <RequestComponent />
    </>
  );
};

export default RequestContainer;
