/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";
import style from "./style.module.scss";
import PropertyCard from "../../component/PropertyCard";
import OwnerListDD from "../../component/Home/ownerListDD";
import AddPropertyComponent from "../../component/AddProperty";
import { NoDataComponent } from "../../component/NoDataComponent";

const PropertiesContainer: FC = () => {
  const [displayDrawer, setdisplayDrawer] = useState(false);
  const [config, setConfig] = useState({});
  const params = {
    role: "MANAGER",
  };
  const [owners, setOwners] = useState([]);
  const [cities, setCities] = useState([]);
  const [cardData, setCardData] = useState<Array<any>>([]);

  useEffect(() => {
    fetchConfigData();
    fetchOwners();
    fetchCities();
    getData(params);
  }, []);

  const fetchConfigData = async () => {
    const res: any = await API.get(APIS.PROPERTY_PARAMS.CONFIG);
    setConfig(res.data.data);
  };
  const fetchOwners = async () => {
    const res: any = await API.get(APIS.PROPERTY.GET_ALL_OWNERS);
    setOwners(res.data.data);
  };
  const fetchCities = async () => {
    const res: any = await API.get(APIS.PROPERTY_PARAMS.GET_CITIES + "0");
    setCities(
      res.data.data.map((m: any) => {
        return { id: m.id, first_name: m.city };
      })
    );
  };

  const getData = async (params: any) => {
    const res = await API.post(APIS.HOME.PROPERTY_LIST, params);
    setCardData(res.data.data.properties);
  };

  const updateData = () => {
    getData(params);
  };

  return (
    <div>
      <div className={style.property_page}>
        <div className={style.header}>My properties &#40; {cardData ? cardData.length : ""} &#41;</div>
        <div className={style.drop_down_container}>
          <div className={style.left}>
            <OwnerListDD
              title={" owner"}
              width={"192px"}
              customClass={style.custom_dd}
              data={owners}
              getData={(ids: any) => {
                const p: any = { ...params };
                p["owners"] = ids;
                getData(p);
              }}
              showClear={true}
              multi={true}
            />
            <OwnerListDD
              title={" city"}
              width={"180px"}
              height={"11rem"}
              customClass={style.custom_dd}
              data={cities}
              getData={(ids: any) => {
                const p: any = { ...params };
                p["cities"] = ids;
                getData(p);
              }}
              showClear={true}
              multi={true}
            />
          </div>
          <div className={style.right}>
            <div className={style.add_property} onClick={(e) => setdisplayDrawer(true)}>
              <i className="icon-add-01"></i>
              Add New Property
            </div>
          </div>
        </div>
        {cardData.length > 0 ? (
          <div className={style.body}>
            {cardData &&
              cardData.length > 0 &&
              cardData.map((val: any, ind: any) => {
                return (
                  <div
                    key={ind}
                    className={style.property_card_container}
                    onClick={() => {
                      // setopenCardDetail(true);
                      // setPropertyCardConfig({
                      //   id: val.id,
                      //   img: val.details.files[0].file.urls.bucket.large,
                      // });
                    }}
                  >
                    <PropertyCard config={config} data={val} onupdate={updateData} />
                  </div>
                );
              })}
          </div>
        ) : (
          <NoDataComponent property="property" />
        )}
        {displayDrawer && (
          <AddPropertyComponent
            config={config}
            showDrawer={displayDrawer}
            setShowDrawer={(x: any) => {
              getData(params);
              setdisplayDrawer(x);
            }}
            onUpdate={updateData}
          />
        )}
      </div>
    </div>
  );
};

export default PropertiesContainer;
