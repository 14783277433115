/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import RadioDropdown from "../../DropDown/RadioDropdown";
import { requestCardComp } from "../type";
import Card from "./card";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import OwnerListDD from "../../Home/ownerListDD";
import { NoDataComponent } from "../../NoDataComponent";
import moment from "moment";
const PropertyRequestCard: FC<requestCardComp> = ({ property_id }) => {
  let defaultParam = { limit: 500, owners: [] };
  const [params, setParams] = useState<any>(defaultParam);
  const statusArray = ["All", "Pending", "Resolved"];
  const [titleId, setTitleId] = useState(0);
  const [title, setTitle] = useState<any>(null);
  const [owners, setOwners] = useState([]);
  const [data, setData] = useState<Array<any>>([]);
  const [ownerClear, setOwnerClear] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  useEffect(() => {
    fetchOwners();
    getData(params);
  }, []);

  const clearFilter = () => {
    setParams(defaultParam);
    getData(defaultParam);
    setTitle(null);
    setOwnerClear(true);
    setSelectedDate(null);
  };

  const fetchOwners = async () => {
    const res: any = await API.get(APIS.PROPERTY.GET_OWNERS + property_id);
    setOwners(res.data.data);
  };

  const getData = async (filter: any) => {
    const res = await API.post(APIS.PROPERTY.REQUEST + property_id, filter);
    setData(res.data.data.rows);
  };

  const onDateChange: DatePickerProps["onChange"] = (
    date: any,
    dateString: any,
  ) => {
    const p = { ...params };
    if(dateString == null || date == null) delete p["month"] 
    else p["month"] = dateString + "-" + "01";
    setParams(p);
    getData(p);
    setSelectedDate(dateString);
  };

  return (
    <div className={style.request_card}>
      <div className={style.filter_container}>
        <div className={style.left}>
          <RadioDropdown
            title={title}
            width={"10rem"}
            data={statusArray}
            getData={(index: number) => {
              setTitleId(index);
              setTitle(statusArray[index]);
              const p: any = { ...params };
              if (index == 0) {
                delete p.status;
              } else if (index == 1) {
                p.status = 0;
              } else if (index == 2) {
                p.status = 1;
              } else {
                p.status = 2;
              }

              getData(p);
              setParams(p);
            }}
            customClass={style.property_detail_dd}
          />
        </div>
        <div className={style.right}>
          <DatePicker
            picker="month"
            value={
              selectedDate && selectedDate != "" ? moment(selectedDate) : null
            }
            onChange={onDateChange}
            format={"YYYY-MM"}
            className={style.property_detail_date_picker}
          />
          <OwnerListDD
            title={" owner"}
            width={"180px"}
            customClass={style.property_detail_dd}
            data={owners}
            getData={(ids: any) => {
              const p: any = { ...params };
              p["owners"] = ids;
              getData(p);
              setParams(p);
            }}
            clearAll={ownerClear}
            setClearAll={setOwnerClear}
            showClear={true}
            multi={true}
          />
          {(params["month"] ||
            params["owners"].length > 0 ||
            params["status"] == 0 ||
            params["status"] == 1) && (
            <span className={style.clear_selected} onClick={clearFilter}>
              Clear filters
            </span>
          )}
        </div>
      </div>
      {data.length > 0 ? (
        <div className={style.cards_request}>
          {data.map((val: any, index: any) => {
            return (
              <Card
                key={index}
                data={val}
                property_id={property_id}
                onUpdate={(resData: any) => {
                  const d = [...data];
                  d[index] = resData;
                  setData(d);
                }}
              />
            );
          })}
        </div>
      ) : (
        <NoDataComponent request="request" title={statusArray[titleId]} />
      )}
    </div>
  );
};

export default PropertyRequestCard;
