import React, { FC } from "react";
import { formatDateMonthYear } from "../../../utils/helper";
import style from "./style.module.scss";

export interface bookingDetail {
  data?: any;
}

const BookingDaysCard: FC<bookingDetail> = ({ data }) => {
  const dateNow = new Date();

  const start = new Date(data.start_date);

  const end = new Date(data.end_date);
  const getDayNight = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays + 1;
  };
  const getDayNight2 = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  const dayLeft = getDayNight2(start, dateNow);
  if (dayLeft < 0) {
    return <></>;
  }
  return (
    <>
      <div className={style.booking_days_card}>
        <div className={style.booking_days}>
          <div className={style.from}>
            <div className={style.date_box}>
              <div className={style.date}>{formatDateMonthYear(start)}</div>
              <div className={style.year}>{new Date(end).getFullYear()}</div>
            </div>
            <div className={style.circle_box}>
              <img src="/images/Home/owner_card_circle.svg" alt="" />
            </div>
          </div>
          <div className={style.days_number}>
            {" "}
            &#40; {getDayNight(end, start)} days | {getDayNight(end, start) - 1} night &#41;{" "}
          </div>
          <div className={style.to}>
            <div className={style.circle_box}>
              <img src="/images/Home/owner_card_circle.svg" alt="" />
            </div>
            <div className={style.date_box}>
              <div className={style.date}>{formatDateMonthYear(end)}</div>
              <div className={style.year}>{new Date(end).getFullYear()}</div>
            </div>
          </div>
        </div>
        {dayLeft >= 0 && (
          <div className={style.days_left}>
            <img src="/images/UserManag/clock.svg" alt="" />
            {dayLeft} Days Left
          </div>
        )}
      </div>
    </>
  );
};

export default BookingDaysCard;
