import React from "react";
import { INoDataModal } from "../../models";
import style from "./style.module.scss";

export const NoDataComponent: React.FC<INoDataModal> = ({
  property,
  request,
  complaint,
  upComing,
  title,
}: INoDataModal) => {
  return (
    <div className={style.noPending}>
      No {title !== "" && title !== "All" ? title : "Pending"} {complaint}{" "}
      {request} {property} {upComing} found.
    </div>
  );
};
