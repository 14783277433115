export const getPropertyDefault = () => {
  return {
    title: "",
    location: "",
    city: "",
    state: "",
    caretaker_name: "",
    caretaker_mobile: "",
    city_id: 0,
    state_id: 0,
    district_id: 0,
    country: "India",
    zipcode: "",
    lat: "0",
    long: "0",
    color: "",
    detail1: "",
    detail2: "",
    features: {},
    amenities: {},
    floor_plans: [],
    brochure: [],
    images: {
      PROPERTY_BANNER: [],
    },
    locality_details: "",
    nearby: {},
    activities: [],
    activitiesList: [],
    attachments: [],
    attachments_brochure: [],
    attachments_floor_plans: [],
  };
};

export const formatAmenities = (amn_data: any) => {
  let newData = {};
  amn_data.forEach((val: any) => {
    Object.assign(newData, { [val.att_key]: val.title });
  });
  return newData;
};

export const formatPropertyFeature = (feat_data: any) => {
  let newData = {};
  let detail1 = "";
  let detail2 = "";
  feat_data.forEach((val: any) => {
    if (!["DETAIL1", "DETAIL2"].includes(val.att_key)) {
      Object.assign(newData, {
        [val.att_key]: {
          title: val.title,
          value: val.att_val,
        },
      });
    } else {
      if (val.att_key === "DETAIL1") {
        detail1 = val.att_val;
      }
      if (val.att_key === "DETAIL2") {
        detail2 = val.att_val;
      }
    }
  });
  return {
    features: newData,
    detail1: detail1,
    detail2: detail2,
  };
};

export const formatNearby = (nearby_data: any) => {
  let newData = {};
  let locality_details = "";
  nearby_data.forEach((val: any) => {
    if (val.att_key !== "DETAILS") {
      Object.assign(newData, {
        [val.att_key]: {
          title: val.title,
          distance: val.att_val,
        },
      });
    } else {
      locality_details = val.att_val;
    }
  });
  return { locality: newData, locality_details };
};

export const formatImages = (images: Array<any>) => {
  let newData: any = { PROPERTY_BANNER: [] };
  let attachments: Array<any> = [];
  images.forEach((attachment: any) => {
    if (!newData[attachment.type]) {
      newData[attachment.type] = [];
    }
    newData[attachment.type].push({
      title: attachment.title,
      id: attachment.file.id,
    });
    attachments.push({
      img: attachment.file.urls.bucket.small,
      file: null,
      name: attachment.title,
      id: attachment.file.id,
    });
  });
  return { files: newData, attachments: attachments };
};

export const formatActivityImages = (images: Array<any>) => {
  const newData: Array<any> = [];
  let attachments: Array<any> = [];
  images.forEach((attachment: any) => {
    newData.push(attachment.file.id);
    attachments.push({
      img: attachment.file.urls.bucket.small,
      file: null,
      name: attachment.title,
      id: attachment.file.id,
    });
  });
  return { files: newData, attachments: attachments };
};

export const formatPDFFiles = (images: Array<any>) => {
  const newData: Array<any> = [];
  let attachments: Array<any> = [];
  images.forEach((attachment: any) => {
    newData.push(attachment.file.id);
    attachments.push({
      img: attachment.file.urls.bucket.file,
      file: null,
      name: attachment.file.name,
      id: attachment.file.id,
    });
  });
  return { files: newData, attachments: attachments };
};

export const formatActivities = (data: Array<any>) => {
  const activities: Array<any> = [];
  const activitiesList: Array<any> = [];
  data.forEach((category: any) => {
    category.activities.forEach((act: any) => {
      const images = formatActivityImages(act.activityDetails.activity_images);
      activities.push({
        category_id: act.activityCategory.id,
        activity_id: act.activityDetails.id,
      });
      activitiesList.push({
        ...act.activityDetails,
        ...{
          category_id: category.id,
          images: images.files,
          attachments: images.attachments,
        },
      });
    });
  });
  return { activities, activitiesList };
};

export const getUpdateFormat = (data: any) => {
  const images = formatImages(data.details.images);
  const floor_plans = formatPDFFiles(data.details.floor_plans);
  const brochure = formatPDFFiles(data.details.brochure);
  const activities = formatActivities(data.details.activities);
  const locality = formatNearby(data.details.attributes.LOCALITY);
  return {
    ...{
      title: data.title,
      location: data.location,
      city: data.city,
      state: data.state,
      country: data.country,
      zipcode: data.zipcode,
      caretaker_name: data.caretaker_name,
      caretaker_mobile: data.caretaker_mobile,
      lat: data.lat,
      long: data.long,
      city_id: data.city_id,
      state_id: data.state_id,
      district_id: data.district_id,
      color: data.color,
      amenities: formatAmenities(data.details.attributes.AMENTIES),
      floor_plans: floor_plans.files,
      brochure: brochure.files,
      images: images.files,
      locality_details: locality.locality_details,
      nearby: locality.locality,
      activities: activities.activities,
      activitiesList: activities.activitiesList,
      attachments: images.attachments,
      attachments_brochure: brochure.attachments,
      attachments_floor_plans: floor_plans.attachments,
    },
    ...formatPropertyFeature(data.details.attributes.INFO),
  };
};

export const getActivityDefault = () => {
  return {
    id: 0,
    category_id: 0,
    title: "",
    location: "",
    city: "",
    district: "",
    state: "",
    state_id: 0,
    district_id: 0,
    city_id: 0,
    country: "",
    zipcode: "",
    lat: "",
    long: "",
    description: "",
    weblink: "",
    ids: "+91",
    contact_number: "",
    images: [],
    attachments: [],
  };
};

export const propertyValidator = (data: any) => {
  if (
    data.state === "" ||
    data.city === "" ||
    data.location === "" ||
    data.zipcode === "" ||
    data.title === "" ||
    data.caretaker_name === "" ||
    data.caretaker_mobile === "" ||
    data.detail1 === "" ||
    data.detail2 === ""
  )
    return true;
  return false;
};

export const propertyFieldValidator = (data: any, key: any) => {
  if (data[key] == null || data[key] === "") return true;
  return false;
};
