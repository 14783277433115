/* eslint-disable eqeqeq */
import { Drawer, Modal } from "antd";
import React, { FC, useState } from "react";
import style from "./style.module.scss";
import { RequestCardType } from "../type";
import {
  formatAMPM,
  formatDateMonthYear,
  formatDotDate,
} from "../../../utils/helper";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
const RequestCardComponent: FC<RequestCardType> = ({
  customClass,
  data,
  onUpdate,
}) => {
  const createdDate = new Date(data.created_at);
  const updatedDate = new Date(data.updated_at);
  const [visible, setVisible] = useState(false);
  const start = new Date(data.bookingDetails.start_date);
  const [radioActive, setRadioActive] = useState(
    data.status === 0 ? 1 : data.status,
  );
  const [response, setResponse] = useState(data.response);
  const end = new Date(data.bookingDetails.end_date);
  let checkinTime = new Date("2022-01-01 " + data.bookingDetails.start_time);
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setEdit(data.status === 0 ? true : false);
  };

  const [edit, setEdit] = useState(data.status === 0 ? true : false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const addResponse = async () => {
    const res = await API.post(
      APIS.PROPERTY.UPDATE_REQUEST + data.property_id,
      {
        id: data.id,
        response: response,
        status: radioActive,
      },
    );
    onUpdate(res.data.data);
    setIsModalVisible(true);
    setVisible(false);
    setEdit(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  let icon = "/images/property/pending_icon.svg";
  let status = "Pending";

  if (data.status == 1) {
    icon = "/images/property/accepted_icon.svg";
    status = "Accepted";
  }

  if (data.status == 2) {
    icon = "/images/property/denied_icon.png";
    status = "Denied";
  }

  const getDayNight = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays + 1;
  };

 

  return (
    <>
      <div
        className={`${style.request_card} ${customClass}`}
        onClick={showDrawer}
      >
        <div className={style.request_heading}>
          <div className={style.request_header}>
            <div className={style.place_name}>
              {data.properties.title}
              {", "}
              {data.properties.location}
            </div>
            <div className={style.request_name}>
              {data.bookingDetails.user_details.first_name}{" "}
              {data.bookingDetails.user_details.last_name}{" "}
            </div>
          </div>
          <div className={`${style.request_heading_ticket} status`}>
            {status}
            <img src={icon} alt="" />
          </div>
        </div>{" "}
        <div className={style.request_content}>
          {/* We would require extra bedding in the master bedrooms */}
          {data.description}
        </div>
        <div className={style.request_footer}>
          <div className={style.date}>{formatDotDate(createdDate)}</div>
          <div className={style.ticket}>Ticket {data.id}</div>
        </div>
      </div>

      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        width={464}
        closable={false}
        bodyStyle={{
          padding: 0,
          position: "relative",
          overflow: "scroll",
          height: "100%",
        }}
      >
        <>
          <div className={style.drawer_header}>
            <div className={style.title}>
              <img src={icon} alt="" />

              {"Request " + status}
            </div>
            <div className={style.close_btn} onClick={onClose}>
              <img src="/images/cross.svg" alt="" />
            </div>
          </div>
          <div className={style.request_drawer}>
            <div className={style.ticket}>
              <div className={style.ticket_no}>Ticket #{data.id}</div>
              <div className={style.ticket_time}>
                {" "}
                {formatDotDate(createdDate)}
              </div>
            </div>
            <div className={style.address_name}>
              {data.properties.title}, {data.properties.city}
            </div>
            <div className={style.sub_address_name}>
              {data.properties.location}, {data.properties.state}
            </div>
            <div className={style.name}>
              {" "}
              Mr. {data.bookingDetails.user_details.first_name}{" "}
              {data.bookingDetails.user_details.last_name}
            </div>
            <div className={style.number}>
              {" "}
              <img
                src="/images/property/telephone_2_icon.svg"
                alt="telephone"
              />{" "}
              {data.bookingDetails.user_details.isd}{" "}
              {data.bookingDetails.user_details.mobile}{" "}
            </div>
            <hr className={style.request_line} />
            <div className={style.upcoming}>Current Bookings:</div>
            <div className={style.booking_dates}>
              <div className={style.start_date}>
                {formatDateMonthYear(start)}
              </div>
              <div className={style.total_stay}>
                {" "}
                &#40; {getDayNight(end, start)} days |{" "}
                {getDayNight(end, start) - 1 > 0
                  ? getDayNight(end, start) - 1
                  : 0}{" "}
                night &#41;{" "}
              </div>
              <div className={style.end_date}>{formatDateMonthYear(end)}</div>
            </div>
            <div className={style.request_time}>
              at {formatAMPM(checkinTime)}
            </div>
            <hr className={style.request_line} />
            <div className={style.special_request}>
              Special Request
              <div className={style.status}>
                {status}
                <img src={icon} alt="" />
              </div>
            </div>
            <div className={style.content}>{data.description}</div>
            <div className={style.request_time}>
              {formatDateMonthYear(createdDate)} | {formatAMPM(createdDate)}
            </div>
            {data.status === 0 && (
              <div className={style.checkbox}>
                <div className="chekbox_list_req">
                  <div
                    className={`radio_btn_options ${
                      radioActive == 1 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(1)}
                  >
                    <div className="radio_btn_input_container">
                      <img
                        src="/images/radio_active.svg"
                        className="radio_inactive"
                        alt=""
                      />
                      <img
                        src="/images/radio_inactive.svg"
                        className="radio_active"
                        alt=""
                      />
                    </div>
                    <div className="label">Accept</div>
                  </div>
                  <div
                    className={`radio_btn_options ${
                      radioActive == 2 ? "active" : ""
                    }`}
                    onClick={() => setRadioActive(2)}
                  >
                    <div className="radio_btn_input_container">
                      <img
                        src={`/images/radio_active.svg`}
                        className="radio_inactive"
                        alt=""
                      />
                      <img
                        src="/images/radio_inactive.svg"
                        className="radio_active"
                        alt=""
                      />
                    </div>
                    <div className="label">Deny</div>
                  </div>
                </div>
              </div>
            )}
            {edit && (
              <>
                <div className={style.special_request}>Add Response</div>

                <textarea
                  className={style.textfield}
                  value={response || data.response}
                  placeholder="Add a comment to let the the property owner know more regarding the complaint "
                  onChange={(e) => {
                    setResponse(e.target.value);
                  }}
                />
              </>
            )}

            {data.status !== 0 && !edit && (
              <>
                <div className={style.special_request}>Reason</div>
                <div className={style.response_container}>
                  <div className={style.reasoncontent}>{data.response}</div>
                  <div className={style.edit_res} onClick={() => setEdit(true)}>
                    Edit
                  </div>
                </div>
                <div className={style.request_time}>
                  at {formatAMPM(updatedDate)}
                </div>
              </>
            )}

            {edit && (
              <div className={style.buttongroup}>
                <button
                  className={style.requestbuttons}
                  style={{ marginRight: "25px" }}
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  onClick={addResponse}
                  className={style.requestbuttons}
                  style={{ background: "#082017", color: "white" }}
                >
                  {data.status === 0 ? "Confirm" : "Update"}
                </button>
              </div>
            )}
          </div>
        </>
      </Drawer>

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        keyboard={true}
      >
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/property/modal_success_icon.png" alt="" />
          <div className={style.modal_content} style={{}}>
            Your response has been sent successfully!
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RequestCardComponent;
