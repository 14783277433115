import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import "./style.scss";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
import Card from "../../Request/card";
import { NoDataComponent } from "../../NoDataComponent";

const RequestHome = () => {
  const [RequestData, setRequestData] = useState<any>({ rows: [], count: 0 });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await API.post(APIS.PROPERTY.REQUEST + "0", {
      limit: 50,
      status: 0,
    });
    setRequestData(res.data.data);
    const ele: any = window.document.querySelector(".req_pagi .swiper-pagination-total");
    ele.innerHTML = res.data.data.count;
  };

  const setTotalPagination = () => {
    const nextCount: any = window.document.querySelector(".req_pagi .swiper-pagination-total");
    nextCount.innerHTML = RequestData.count;
    const prevCount: any = window.document.querySelector(".req_pagi .swiper-pagination-current");
    if (prevCount.innerHTML == RequestData.count - 1) {
      prevCount.innerHTML = RequestData.count;
    }
  };

  return (
    <>
      <div className="card_header">
        <div className="card_main_head">Pending Request ({RequestData.count})</div>
        {RequestData.count > 0 && (
          <div className="card_slider_icons">
            <i className="icon-previous req_prev" onClick={setTotalPagination}></i>
            <div className="swiper_custom_pagi req_pagi"></div>
            <i className="icon-next2 req_next" onClick={setTotalPagination}></i>
          </div>
        )}
      </div>
      <div className="cards_request" id="slider2">
        {RequestData.rows.length > 0 ? (
          <Swiper
            pagination={{
              el: ".req_pagi",
              clickable: true,
              type: "fraction",
            }}
            spaceBetween={50}
            slidesPerView={2}
            navigation={{
              enabled: true,
              prevEl: ".req_prev",
              nextEl: ".req_next",
            }}
            onInit={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = ".icon-previous";
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = ".icon-next2";
              swiper.navigation.update();
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            onSwiper={(swiper) => console.log("swiper", swiper)}
          >
            {RequestData.rows.length > 0 &&
              RequestData.rows.map((val: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <Card
                      key={index}
                      data={val}
                      customClass="home_request_card"
                      onUpdate={(resData: any) => {
                        getData();
                      }}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <NoDataComponent request="request" />
        )}
      </div>
    </>
  );
};

export default RequestHome;
