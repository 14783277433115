import { useEffect, useState } from "react";
import style from "./style.module.scss";
import ProfileImageModal from "../Modals/profileImage";
import RemoveProfileImgModal from "../Modals/removeImage";
import { useNavigate } from "react-router-dom";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import { uploadBlobFile, uploadFile } from "../../../utils/helper";
import { notification } from "antd";
import { useAuth } from "../../../store/context/login";
import { routeMap } from "../../../utils/constants";

const ProfileComponent = () => {
  const [isButtonDisable, setisButtonDisable] = useState(false);
  const { user, getProfileData, logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  let defaultProfileData = user;
  let defaultProfilePicData = {
    file_id: "",
    path:
      user && user.profile_path !== null
        ? user.profile_path.urls.bucket.medium
        : "",
  };
  const [profilePicData, setProfilePicData] = useState(defaultProfilePicData);

  let navigate = useNavigate();
  const [profileData, setProfileData] = useState<any>(!!user ? user : {});

  const handleFileUpload = async (e: any) => {
    let newData = { ...profilePicData };
    let res: any, file_res: any;
    const file = e.target.files[0];
    if (!!file) res = await uploadFile(file);
    if (res.data.status === 1) {
      file_res = await API.post(APIS.PROFILE.ADD_PROFIE_PIC, {
        file_id: res.data.data.id,
      });
      newData.file_id = res.data.data.id;
    }
    if (file_res.data.status === 1) {
      newData.path = file_res.data.data.profile_path.urls.bucket.medium;
    }
    setProfilePicData(newData);
    // getProfileData();
  };

  const handleCameraFileUpload = async (blob: any) => {
    let newData = { ...profilePicData };
    let res, file_res: any;

    res = await uploadBlobFile(blob, "camera.jpg");
    if (res.data.status === 1) {
      file_res = await API.post(APIS.PROFILE.ADD_PROFIE_PIC, {
        file_id: res.data.data.id,
      });
      newData.file_id = res.data.data.id;
    }
    if (file_res.data.status === 1) {
      newData.path = file_res.data.data.profile_path.urls.bucket.medium;
    }

    // getProfileData();
  };

  const resetData = () => {
    setProfilePicData(defaultProfilePicData);
    // deleteProfiePic();
    setProfileData(defaultProfileData);
  };

  const onSaveData = async () => {
    setisButtonDisable(true);
    let newData = { ...profileData };
    const res = await API.post(APIS.PROFILE.UPDATE_PROFILE, {
      first_name: newData.first_name,
      last_name: newData.last_name,
      email: newData.email,
      mobile: newData.mobile,
    });
    if (res.data.status === 1) {
      setisButtonDisable(false);
      notification.info({
        message: "Profile has been saved successfully",
        placement: "top",
      });
      setProfilePicData(newData);
      getProfileData();
    } else {
      notification.info({
        message: "Error in saving profile please try again",
        placement: "top",
      });
    }
  };

  const deleteProfiePic = async () => {
    let newData = { ...profilePicData };

    const res = await API.delete(APIS.PROFILE.DELETE_PROFILE);
    if (res.data.status === 1) {
      newData.path = null;
    }
    setProfilePicData(newData);
  };

  const handleProfileInput = (value: any, key: any) => {
    let newData = { ...profileData };
    newData[key] = value;
    setProfileData(newData);
  };
  const handleLogOut = () => {
    logout();
    navigate(routeMap.login);
  };

  useEffect(() => {
    if (!!user) {
      // let newData = { ...profilePicData };
      setProfileData(user);
    }
  }, [user]);

  return (
    <div className={style.profile_page}>
      <div className={style.header}>
        <img
          src="/images/left_arrow.svg"
          alt=""
          onClick={(e) => {
            e.stopPropagation();
            navigate("/", { replace: false });
          }}
        />
        Profile
      </div>
      <div className={style.profile_body}>
        <div className={style.img_upload_container}>
          <img
            src={
              profilePicData.path !== null && profilePicData.path !== ""
                ? profilePicData.path
                : "/images/Profile/Avatar.svg"
            }
            className={style.upload_image}
            alt=""
          />
          <div className={style.action_btn_profile}>
            {(profilePicData.path === null || profilePicData.path === "") && (
              <div
                className={style.upload_img}
                onClick={() => setShowProfileModal(true)}
              >
                <img src="/images/Profile/camera.svg" alt="" />
                Upload Photo
              </div>
            )}
            {!!profilePicData.path && profilePicData.path !== "" && (
              <div
                className={style.delet_img}
                onClick={() => setShowModal(true)}
              >
                <img src="/images/Profile/trash.svg" alt="" />
                Remove photo
              </div>
            )}
          </div>
        </div>
        <div className={style.profile_detail_contaner}>
          <div className={style.input_box}>
            <div className={style.title}>First Name</div>
            <input
              type="text"
              value={profileData.first_name}
              onChange={(e) => {
                handleProfileInput(e.target.value, "first_name");
              }}
            />
          </div>
          <div className={style.input_box}>
            <div className={style.title}>Last name</div>
            <input
              type="text"
              value={profileData.last_name}
              onChange={(e) => {
                handleProfileInput(e.target.value, "last_name");
              }}
            />
          </div>
          <div className={style.input_box}>
            <div className={style.title}>Email</div>
            <input
              type="email"
              value={profileData.email}
              onChange={(e) => {
                handleProfileInput(e.target.value, "email");
              }}
            />
          </div>
          <div className={style.input_box}>
            <div className={style.title}>Contact number</div>
            <input
              type="text"
              value={profileData.mobile}
              onChange={(e) => {
                handleProfileInput(e.target.value, "mobile");
              }}
            />
          </div>
        </div>
        <div className={style.action_btn}>
          <div className={style.btn} onClick={resetData}>
            Reset
          </div>
          <div
            className={style.btnblk}
            style={{ opacity: isButtonDisable ? "0.5" : "1" }}
            onClick={() => {
              if (isButtonDisable === false) onSaveData();
            }}
          >
            Save
          </div>
        </div>
      </div>
      <div className={style.logout_container}>
        <div className={style.left}>
          <div className={style.title}>Logout</div>
          <div className={style.para}>
            You can login again using your mobile number.
          </div>
        </div>
        <div className={style.right}>
          <div
            className={style.logout_btn}
            onClick={() => {
              handleLogOut();
            }}
          >
            Logout
          </div>
        </div>
      </div>
      <RemoveProfileImgModal
        data={profileData}
        onDelete={deleteProfiePic}
        showModal={showModal}
        setShowModal={setShowModal}
        profiePicData={profilePicData}
      />
      <ProfileImageModal
        profiePicData={profilePicData}
        data={profileData}
        onUpload={handleFileUpload}
        onCameraUpload={handleCameraFileUpload}
        showModal={showProfileModal}
        setShowModal={setShowProfileModal}
      />
    </div>
  );
};

export default ProfileComponent;
