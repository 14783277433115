/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import Layout from "../../layout/default";
import "./home.scss";
import OwnerCard from "../../component/Home/ownerCard";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import ComplaintHome from "./complaint/index";
import RequestHome from "./requestHome/index";
import { getPropertyNameList } from "../../constants/config";
import { getOwnerList, upcommingBookings } from "../../constants/config";
import OwnerListDD from "./ownerListDD";
import PropertyListDD from "./propertyListDD";
import { useAuth } from "../../store/context/login";
import PropertyHome from "./propertyHome";
import { NoDataComponent } from "../NoDataComponent";
import BookingDrawerComponent from "./bookingdrawer";
import { BookingSlotCalendar } from "@impulsiveweb/react-booking-calendar";
import "@impulsiveweb/react-booking-calendar/index.css";

type LayoutProps = {
  newFileList?: any;
};
SwiperCore.use([Navigation]);
const HomeComponent: FC<LayoutProps> = () => {
  const [bookingCardDetails, setBookingCardDetails] = useState<Array<any>>([]);
  const [ownerList, setOwnerList] = useState<any>([]);
  const [propertyList, setPropertyList] = useState<any>([]);
  const { user, token } = useAuth();
  const [radioActive, setRadioActive] = useState(0);
  const [calendarData, setCalendarData] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const getDropDownData = async () => {
    const own = await getOwnerList();
    const prt = await getPropertyNameList();
    setOwnerList(own.data.data);
    setPropertyList(prt.data.data);
  };

  useEffect(() => {
    getDropDownData();
    getCardData();
    getCardData(-1);
  }, [token]);

  const showBookingDetails = (slot: any) => {
    const index = bookingCardDetails.findIndex((bc) => bc.id === slot.id);
    if (index > -1) {
      setDrawerData(bookingCardDetails[index]);
      setShowDrawer(true);
    }
  };

  const getCardData = async (ids?: any, value?: any, option?: any) => {
    let type = "owners";
    if (radioActive === 1 || option === 1) type = "properties";
    else type = "owners";

    const result = await upcommingBookings(type, ids, token);

    setBookingCardDetails(result.data.data.rows);

    const cd = result.data.data.rows.map((row: any) => {
      return {
        id: row.id,
        color: row.propertyDetails.color
          ? row.propertyDetails.color
          : "#eeeeee",
        startDate: row.start_date,
        endDate: row.end_date,
      };
    });
    setCalendarData(cd);
  };
  useEffect(() => {
    getCardData(-1);
  }, [radioActive]);
  const dateFormater = () => {
    let currentdate = new Date();
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var datetime =
      weekday[currentdate.getDay()] +
      ", " +
      currentdate.getDate() +
      "th " +
      month[currentdate.getMonth()] +
      " " +
      currentdate.getFullYear();
    return datetime;
  };

  return (
    <Layout>
      <div className="home_container">
        <div className="home_left_part">
          {!!user && (
            <div className="home_heading">
              Welcome {user.first_name} {user.last_name}
            </div>
          )}
          {!!user && (
            <div className="home_sub_heading">
              Here are your updates for
              <span className="home_sub_span"> {dateFormater()}</span>
            </div>
          )}
          <div className="complaints_card">
            <ComplaintHome />
            <RequestHome />
            <PropertyHome />
          </div>
        </div>
        <div className="home_right_part">
          <div className="heading_right">Upcoming bookings</div>
          <div className="container">
            <div className="checkbox_drop_down">
              <div className="checkbox">
                <div className="choose_view">Choose view </div>
                <div className="chekbox_list">
                  <div
                    className={`radio_btn_options ${
                      radioActive == 0 ? "active" : ""
                    }`}
                    onClick={() => {
                      setRadioActive(0);
                      getCardData(0, 0);
                    }}
                  >
                    <div className="radio_btn_input_container">
                      <img
                        src="/images/radio_active.svg"
                        className="radio_inactive"
                        alt=""
                      />
                      <img
                        src="/images/radio_inactive.svg"
                        className="radio_active"
                        alt=""
                      />
                    </div>
                    <div className="label">Owner</div>
                  </div>
                  <div
                    className={`radio_btn_options ${
                      radioActive == 1 ? "active" : ""
                    }`}
                    onClick={() => {
                      setRadioActive(1);
                      getCardData(0, 1);
                    }}
                  >
                    <div className="radio_btn_input_container">
                      <img
                        src="/images/radio_active.svg"
                        className="radio_inactive"
                        alt=""
                      />
                      <img
                        src="/images/radio_inactive.svg"
                        className="radio_active"
                        alt=""
                      />
                    </div>
                    <div className="label">Properties</div>
                  </div>
                </div>
              </div>
              <div className="drop_down">
                {radioActive === 0 && (
                  <OwnerListDD
                    width={"190.6px"}
                    data={ownerList}
                    getData={(id: any) => {
                      getCardData(id);
                    }}
                    showClear={true}
                    multi={true}
                    customClass={"drop_down_custom"}
                    title={" Owner"}
                  />
                )}
                {radioActive === 1 && (
                  <PropertyListDD
                    width={"190.6px"}
                    data={propertyList}
                    getData={(id: any) => {
                      getCardData(id);
                    }}
                    showClear={true}
                    customClass={"drop_down_custom"}
                    multi={true}
                  />
                )}
              </div>
            </div>
            <div className="booking_container_box">
              <BookingSlotCalendar
                slots={calendarData}
                activeMonth={new Date().getMonth() + 1}
                activeYear={new Date().getFullYear()}
                onSlotClick={showBookingDetails}
              />
              {/* <SlotCalendarComponent
                slots={calendarData}
                activeMonth={new Date().getMonth() + 1}
                activeYear={new Date().getFullYear()}
                hideOtherMonth={true}
                onSlotClick={showBookingDetails}
              ></SlotCalendarComponent> */}
            </div>

            {bookingCardDetails !== null && (
              <>
                {bookingCardDetails.length > 0 ? (
                  <div className="booking_container">
                    {bookingCardDetails.length > 0 &&
                      bookingCardDetails.map((val: any, ind: any) => {
                        return (
                          <div
                            onClick={() => {
                              setShowDrawer(true);
                              setDrawerData(val);
                            }}
                          >
                            {val.status !== 0 && (
                              <OwnerCard key={ind} data={val} />
                            )}
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  <NoDataComponent upComing="upcoming bookings" />
                )}
              </>
            )}

            <BookingDrawerComponent
              data={drawerData}
              setShowDrawer={setShowDrawer}
              showDrawer={showDrawer}
              onUpdate={getCardData}
              param={-1}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomeComponent;
