/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import style from "./style.module.scss";

const MapComponent: FC<any> = ({ lat, long, onChange }) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState<any>(null);
  const [marker, setMarker] = useState<any>(null);
  const [latLocal, setLatLocal] = useState<any>(lat);
  const [longLocal, setLongLocal] = useState<any>(long);

  useEffect(() => {
    let m: any = marker;
    let mp = map;
    if (!map) {
      mp = new window.google.maps.Map(mapRef.current as unknown as HTMLElement, {
        zoom: 15,
        center: { lat: lat ? lat : 0, lng: long ? long : 0 },
      });
      setMap(mp);
      let latLng = new google.maps.LatLng(lat ? lat : 0, long ? long : 0);
      m = new google.maps.Marker({
        position: latLng,
        title: "",
        map: mp,
        draggable: true,
      });
      google.maps.event.addListener(m, "dragend", () => {
        const lat = m.getPosition()?.lat();
        const long = m.getPosition()?.lng();

        setLatLocal(lat);
        setLongLocal(long);
      });
      setMarker(m);
    }
  }, []);

  useEffect(() => {
    if (map != null && marker !== null && lat && long) {
      map.setCenter(new google.maps.LatLng(latLocal, longLocal));
      marker.setPosition(new google.maps.LatLng(latLocal, longLocal));
      onChange(latLocal, longLocal);
    }
  }, [latLocal, longLocal]);

  useEffect(() => {
    if (lat && long && lat !== latLocal && long !== longLocal) {
      setLatLocal(lat);
      setLongLocal(long);
    }
  }, [lat, long]);

  return (
    <>
      <div className={style.map_box} ref={mapRef}></div>
    </>
  );
};

export default MapComponent;
