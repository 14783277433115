import Axios from "axios";

const API = Axios.create({
  // baseURL: "http://localhost:4000/",
  // baseURL: "https://api.yours.devstudioonline.com/",
  baseURL: "https://api.yours.house/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "",
  },
});

API.interceptors.request.use(
  function (config: any) {
    const token = window.localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default API;
