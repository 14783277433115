import { FC, useEffect, useState } from "react";
import "./sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { routeMap } from "../../utils/constants";
type TSidebarProps = {};

const Sidebar: FC<TSidebarProps> = () => {
  const location = useLocation();

  const [currActive, setCurrActive] = useState("");

  useEffect(() => {
    setCurrActive(location.pathname);
  }, [location.pathname]);

  return (
    <div className="sidebar sticky-align-header">
      <Link to={"/"}>
        <div className={`sidebar-option ${currActive === "/" ? "active" : ""}`}>
          <img src="/images/Home/HomeMain.svg" alt="" className="sidebard--option-icon" />
          <span className="sidebard--option-label">Home</span>
        </div>
      </Link>
      <Link to={routeMap.properties}>
        <div className={`sidebar-option dashboard ${currActive === "/properties" ? "active" : ""}`}>
          <img src="/images/Home/property.svg" alt="" className="sidebard--option-icon" />
          <span className="sidebard--option-label">Properties</span>
        </div>
      </Link>
      {/* <Link to={"/setting"}>
        <div className={`sidebar-option dashboard ${currActive==='/setting'?'active':''}`}>
          <img
            src="/images/Home/property.svg"
            alt=""
            className="sidebard--option-icon"
          />
          <span className="sidebard--option-label">Dashboards</span>
        </div>
      </Link> */}
      <Link to={"/complaint"}>
        <div className={`sidebar-option ${currActive === "/complaint" ? "active" : ""}`}>
          <img src="/images/Home/clipboard.svg" alt="" className="sidebard--option-icon" />
          <span className="sidebard--option-label">Complaints</span>
        </div>
      </Link>
      <Link to={"/request"}>
        <div className={`sidebar-option ${currActive === "/request" ? "active" : ""}`}>
          <img src="/images/Home/book-open.svg" alt="" className="sidebard--option-icon" />
          <span className="sidebard--option-label">Requests</span>
        </div>
      </Link>
    </div>
  );
};

export default Sidebar;
