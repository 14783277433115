/* eslint-disable eqeqeq */
import React, { FC } from "react";
import { ownderCardType } from "../type";
import style from "./style.module.scss";
const OwnerCard: FC<ownderCardType> = ({ data }) => {
  data.bookingRequest = data.details.special;
  data.guest = data.details.guest;
  let total_members = 0;
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const [visible, setVisible] = useState(false);

  const start = new Date(data.start_date);
  let guestArray: Array<any> = [];
  if (!!data) {
    total_members =
      data.guest.adults_count +
      data.guest.kids_count +
      data.guest.seniorcitizen_count +
      data.guest.infants_count;
    Object.keys(data.guest).map((value, index) => {
      let lastIndx, fielValue;
      if (
        data.guest[value] > 0 &&
        (value.includes("_count") || value.includes("s_count"))
      ) {
        if (value.includes("_count")) {
          lastIndx = value.indexOf("_count");
        }
        if (value.includes("s_count")) {
          lastIndx = value.indexOf("s_count");
        }
        fielValue = value.substring(0, lastIndx);
        guestArray.push({ field: fielValue, count: data.guest[value] });
      }
    });
  }
  const end = new Date(data.end_date);
  const getDayNight = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    return TotalDays + 1;
  };
  const showDrawer = () => {
    

    // setVisible(true);
  };

  // const onClose = () => {
  //   setVisible(false);
  // };

  // let total_members =
  //   data.guest.adults_count + data.guest.kids_count + data.guest.seniorcitizen_count + data.guest.infants_count;
  return (
    <>
      <div
        className={style.owner_card}
        style={{
          background: data.propertyDetails.color
            ? data.propertyDetails.color
            : "#f6f3ef",
        }}
        onClick={showDrawer}
      >
        <div className={style.date_section}>
          <div className={style.from}>
            <div className={style.date}>
              {start.getDate()} {months[start.getMonth()]}
            </div>
            <div className={style.year}>{start.getFullYear()}</div>
            <img
              src="/images/Home/owner_card_circle.svg"
              alt=""
              className={style.circle}
            />
            <div className={style.line_down}></div>
          </div>
          <div className={style.to}>
            <img
              src="/images/Home/owner_card_circle.svg"
              alt=""
              className={style.circle}
            />
            <div className={style.date}>
              {end.getDate()} {months[end.getMonth()]}
            </div>
            <div className={style.year}>{end.getFullYear()}</div>
          </div>
          <div className={style.line}></div>
          <div className={style.circle_up}>
            <img src="/images/Home/circle_up.png" alt="" />
          </div>
          <div className={style.circle_down}></div>
        </div>
        <div className={style.owner_info}>
          <div className={style.container_detail}>
            <div className={style.header}>
              {" "}
              <span>{data.guest.name}</span>{" "}
              {data.booking_for !== "MYSELF" ? <>&#40;Guest&#41;</> : " "}
              {total_members - 1 > 0
                ? total_members - 1 == 1
                  ? "+ " + (total_members - 1) + " Member"
                  : "+ " + (total_members - 1) + " Members"
                : ""}{" "}
            </div>
            <div className={style.para}>
              {guestArray.length > 2 &&
                guestArray.map((value: any, index: any) => {
                  return index !== guestArray.length - 1
                    ? index === guestArray.length - 2
                      ? `${value.count} ${
                          value.count > 1 ? value.field + "s" : value.field
                        } `
                      : `${value.count} ${
                          value.count > 1 ? value.field + "s" : value.field
                        }, `
                    : `and ${value.count} ${
                        value.count > 1 ? value.field + "s" : value.field
                      }`;
                })}

              {guestArray.length <= 2 &&
                guestArray.length > 0 &&
                guestArray.map((value: any, index: any) => {
                  return index !== guestArray.length - 1
                    ? `${value.count} ${
                        value.count > 1 ? value.field + "s" : value.field
                      } `
                    : index === 0
                    ? `${value.count} ${
                        value.count > 1 ? value.field + "s" : value.field
                      }`
                    : `and ${value.count} ${
                        value.count > 1 ? value.field + "s" : value.field
                      }`;
                })}
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.container_detail}>
            <div className={style.header}> {data.propertyDetails.title}</div>
            <div className={style.para}>
              &#40; {getDayNight(end, start)} days |{" "}
              {getDayNight(end, start) - 1} night &#41;{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerCard;
