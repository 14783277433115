import React from "react";
import SettingProfile from "../../component/SettingProfile";
const SettingProfileContainer = () => {
  return (
    <div>
      <SettingProfile />
    </div>
  );
};

export default SettingProfileContainer;
