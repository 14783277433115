export const routeMap = {
  home: "/",
  dashboard: "/dashboard",
  login: "/login",
  properties: "/properties",
  property: "/property/:id",
  complaint: "/complaint",
  request: "/request",
  profile: "/profile",
  setting: "/setting",
  bookingHistory: "/bookingHistory",
};
