import "./style.scss";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { propertyList } from "../../../constants/config";
import { useAuth } from "../../../store/context/login";
import PropertyCard from "../../PropertyCard";
import { NoDataComponent } from "../../NoDataComponent";

const PropertyHome = () => {
  const [data, setData] = useState<any>({
    properties_count: 0,
    properties: [],
  });
  const { token } = useAuth();
  useEffect(() => {
    const getData = async () => {
      const res = await propertyList(token);
      setData(res.data.data);
      const ele: any = window.document.querySelector(
        ".prop_pagi .swiper-pagination-total",
      );
      ele.innerHTML = res.data.data.properties_count;
    };
    getData();
  }, [token]);

  const setTotalPagination = () => {
    const nextCount: any = window.document.querySelector(
      ".prop_pagi .swiper-pagination-total",
    );
    nextCount.innerHTML = data.properties_count;
    const prevCount: any = window.document.querySelector(
      ".prop_pagi .swiper-pagination-current",
    );
    if (prevCount.innerHTML == data.properties_count - 1) {
      prevCount.innerHTML = data.properties_count;
    }
  };

  return (
    <>
      <div className="card_header">
        <div className="card_main_head">
          Properties ({data.properties_count})
        </div>

        {data.properties_count > 0 && (
          <div className="card_slider_icons">
            <i
              className="icon-previous prop_prev"
              onClick={setTotalPagination}
            ></i>
            <div className="swiper_custom_pagi prop_pagi"></div>
            <i
              className="icon-next2 prop_next"
              onClick={setTotalPagination}
            ></i>
          </div>
        )}
      </div>
      <div className="cards_property" id="slider2">
        {data.properties.length > 0 ? (
          <Swiper
            pagination={{
              el: ".prop_pagi",
              clickable: true,
              type: "fraction",
            }}
            spaceBetween={50}
            slidesPerView={2}
            navigation={{
              enabled: true,
              prevEl: ".prop_prev",
              nextEl: ".prop_next",
            }}
            onInit={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = ".icon-previous";
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = ".icon-next2";
              swiper.navigation.update();
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {data &&
              data.properties.length > 0 &&
              data.properties.map((val: any, ind: any) => {
                return (
                  <SwiperSlide>
                    <PropertyCard data={val} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <NoDataComponent property="property" />
        )}
      </div>
    </>
  );
};

export default PropertyHome;
