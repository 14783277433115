import Layout from '../../layout/default'
import PropertiesContainer from '../../container/PropertiesContainer'
const PropetiesPage = () => {
  return (
    <Layout>
        <PropertiesContainer />
    </Layout>
  )
}

export default PropetiesPage