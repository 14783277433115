import React, { FC, useEffect, useRef, useState } from "react";
import { ProfilePicModal } from "../../type";
import style from "./style.module.scss";
const ProfileImageModal: FC<ProfilePicModal> = ({
  showModal,
  setShowModal,
  data,
  profiePicData,
  onUpload,
  onCameraUpload,
}) => {
  const playerRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const uploaded = false;
  const [show, setShow] = useState(showModal);
  const [captureCamera, setCaptureCamera] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    setShow(false);
  };
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const processDevices = (devices: Array<any>) => {
    devices.forEach((device: any) => {
      setDevice(device);
    });
  };

  const setDevice = async (device: any) => {
    const { deviceId } = device;
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: { deviceId },
    });
    playerRef.current.srcObject = stream;
    playerRef.current.play();
  };

  const startCameraCapture = async () => {
    const cameras = await navigator.mediaDevices.enumerateDevices();
    processDevices(cameras);
    setCaptureCamera(true);
  };

  const capturePhoto = async () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(playerRef.current, 0, 0, 520, 400);
    canvasRef.current.toBlob((blob: any) => {
      onCameraUpload(blob);
    });
    setCaptureCamera(false);
  };

  return (
    <>
      {show && (
        <div className={style.profileimg_modal_container}>
          <div className={style.profileimg_modal}>
            <img src="/images/close_icon.svg" className={style.close_icon} onClick={() => closeModal()} alt="" />
            <div className={style.header}>Add profile picture</div>
            {uploaded && (
              <div className={style.drag_drop_text}>
                A picture helps people recognize you and lets you know when you’re signed in to your account
              </div>
            )}

            {(profiePicData.path == null || profiePicData.path === "") && (
              <div className={`${style.img_box} ${captureCamera ? style.hide : ""}`}>
                <img src="/images/Profile/Avatar.svg" alt="" />
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    onUpload(e);
                  }}
                />
              </div>
            )}
            {!!profiePicData.path && profiePicData.path !== "" && (
              <div className={`${style.img_box} ${captureCamera ? style.hide : ""}`}>
                <img src={profiePicData.path} alt="" />

                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    onUpload(e);
                  }}
                />
              </div>
            )}

            <div className={`${style.img_box} ${style.video_box} ${!captureCamera ? style.hide : ""}`}>
              <video ref={playerRef} width="auto" height="100%" />
            </div>
            <div style={{ display: "none" }}>
              <canvas width="520" height="400" ref={canvasRef} />
            </div>
            {!captureCamera && <div className={style.drag_drop_text}>Drag photo here</div>}
            <div className={style.or} style={{ visibility: captureCamera ? "hidden" : "visible" }}>
              <div className={style.line}></div>
              <div className={style.text}>or</div>
              <div className={style.line}></div>
            </div>
            {!captureCamera && (
              <div className={style.action_btns}>
                <div className={style.btn} onClick={startCameraCapture}>
                  Take a picture
                </div>
                <div className={style.btnblk}>
                  <input
                    type="file"
                    name=""
                    id=""
                    onChange={(e) => {
                      onUpload(e);
                    }}
                  />
                  Browse Photo
                </div>
                {uploaded && <div className={style.btnupld}>Upload as profile picture</div>}
              </div>
            )}
            {captureCamera && (
              <div className={style.action_btns}>
                <div className={style.btn} onClick={capturePhoto}>
                  Capture
                </div>
              </div>
            )}
          </div>
          <div className={style.overlay} onClick={() => closeModal()}></div>
        </div>
      )}
    </>
  );
};

export default ProfileImageModal;
