import React from "react";
import style from "./style.module.scss";
const ContactTeam = () => {
  return (
    <div>
      <div className={style.body}>
        <div className={`${style.contact_container}`}>
          <div className={style.rep_text}>Representative</div>
          <div className={style.header}>
            <div className={style.text}>Roshni</div>
            <div className={style.number}>
              <a href="tel:+">
                <img src="/images/call_icon.svg" alt="" />
                +91 9880775551
              </a>
            </div>
          </div>
          <div className={style.divider}></div>
          {/* 
          <div className={style.header}>
            <div className={style.text}>Customer Care 1</div>
            <div className={style.number}>
              <a href="tel:+">
                <img src="/images/call_icon.svg" alt="" />
                +91 97398297387
              </a>
            </div>
          </div>
          <div className={style.divider}></div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactTeam;
