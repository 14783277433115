import { useState } from "react";
import style from "./style.module.scss";
import BookingHistory from "../bookingHistory";
import RequestCardComponent from "../RequestCard";
import ComplaintComponent from "../complaintCard";
import { FC } from "react";
export interface propertiesDetail {
  id: any;
  booking_count: any;
  complaint_count: any;
  request_count: any;
}
const PropertyDetailTab: FC<propertiesDetail> = ({ id, booking_count, complaint_count, request_count }) => {
  const [toggleBooking, setToggleBooking] = useState(true);
  const [toggleRequest, setToggleRequest] = useState(false);
  const handleBookingHistoryTab = (e: any) => {
    setToggleBooking(true);
    setToggleRequest(false);
    const allSideTab = document.querySelectorAll(".prop_details_side_tab");
    for (let index = 0; index < allSideTab.length; index++) {
      allSideTab[index].classList.remove(style.active);
    }
    e.target.classList.add(style.active);
  };

  const handleRequestTab = (e: any) => {
    setToggleBooking(false);
    setToggleRequest(true);
    const allSideTab = document.querySelectorAll(".prop_details_side_tab");
    for (let index = 0; index < allSideTab.length; index++) {
      allSideTab[index].classList.remove(style.active);
    }
    e.target.classList.add(style.active);
  };

  const handleComplaints = (e: any) => {
    setToggleBooking(false);
    setToggleRequest(false);
    const allSideTab = document.querySelectorAll(".prop_details_side_tab");
    for (let index = 0; index < allSideTab.length; index++) {
      allSideTab[index].classList.remove(style.active);
    }
    e.target.classList.add(style.active);
  };

  return (
    <div className={style.peroperty_detail_tab}>
      <div className={style.side_tabs}>
        <div
          className={`${style.tab} prop_details_side_tab ${style.active}`}
          onClick={(e) => handleBookingHistoryTab(e)}
        >
          Booking History &#40;{booking_count}&#41;
        </div>
        <div className={`${style.tab} prop_details_side_tab`} onClick={(e) => handleRequestTab(e)}>
          Requests &#40;{request_count}&#41;
        </div>
        <div className={`${style.tab} prop_details_side_tab`} onClick={(e) => handleComplaints(e)}>
          Complaints &#40;{complaint_count}&#41;
        </div>
      </div>
      <div className={style.side_tab_body}>
        <div style={{ marginLeft: "25px" }}>
          {toggleBooking ? (
            <BookingHistory property_id={id} />
          ) : toggleRequest ? (
            <RequestCardComponent property_id={id} />
          ) : (
            <ComplaintComponent property_id={id} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetailTab;
