import React, { FC, useState, useEffect } from "react";
import { checkBoxDD } from "../type";
import style from "./style.module.scss";
const PropertyListDD: FC<checkBoxDD> = ({ data, getData, width, customClass, showClear, multi, border, height, clearAll, setClearAll }) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [dropDownSelect, setDropDownSelect] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<Array<any>>([]);
  const dropDownData = [
    {
      id: 0,
      city: "Tamil Nadu",
      location: "Nilgiri",
      title: "All Properties",
    },
    ...data,
  ];
  const [option, setOption] = useState("Select Property");
  const menuToggle = (index: any, value: any, id: any) => {
    setOption(value);
    let ids = [...selectedValues];
    if (!multi) {
      ids = [];
      setOption(value);
    }
    // eslint-disable-next-line eqeqeq
    if (id == 0 && multi) {
      selectAll();
      setOption("All Property selected");
    } else {
      if (ids.includes(0)) {
        const index = ids.indexOf(0);
        ids.splice(index, 1);
      }
      if (ids.includes(id)) {
        const index = ids.indexOf(id);
        if (index > -1) {
          ids.splice(index, 1);
        }
      } else {
        ids.push(id);
      }
      setSelectedValues(ids);
      if (multi) {
        setOption(ids.length + " Property selected");
      } else {
        setOption(value);
      }
    }
    callback(ids);
    setDropDownSelect(true);
  };

  const callback = (ids: Array<any>) => {
    if (multi) {
      getData(ids);
    } else {
      getData(ids.length > 0 ? ids[0] : 0);
    }
  };

  const selectAll = () => {
    const ids = dropDownData.map((m: any) => m.id);
    setSelectedValues(ids);
  };

  const clear = () => {
    setSelectedValues([]);
    callback([]);
    setOption("Select Property");
    // setDropDownActive(false);
    // setDropDownSelect(false);
    if (setClearAll) setClearAll(false);
  };

  useEffect(() => {
    if (clearAll) {
      clear();
    }
  }, [clearAll]);

  return (
    <div className={`${style.drop_down_container}  `} style={{ width: `${width}`, opacity:`${dropDownActive?"1":"0.5"}` }}>
      <div
        className={`${style.drop_down_selected} ${dropDownActive ? style.dd_active : ""} ${customClass}`}
        onClick={() => setDropDownActive(!dropDownActive)}
        style={{ border: border ? border : "1px solid #082017", opacity: dropDownActive ? 1 : 0.5 }}
      >
        {option}
        <img src="/images/drop_down_icon.svg" className={dropDownActive ? "" : style.rotate_icon} alt="" />
      </div>

      {
        <div className={style.drop_down_box} style={{ display: dropDownActive ? "block" : "none" }}>
          <div className={style.scorll_box} style={{ height: height ? height : "20rem" }}>
            {dropDownData.map((val: any, ind: any) => {
              return (
                <div key={ind}>
                  <div
                    id={`${ind}`}
                    className={`${style.menu} menu_active ${selectedValues.includes(val.id) ? style.active : ""} `}
                    onClick={(e) => menuToggle(`${ind}`, val.title, val.id)}
                  >
                    <img src="/images/DropDown/radio_active.svg" className={style.active_circle} alt="" />
                    <img src="/images/DropDown/radio_inactive.svg" className={style.inactive_circle} alt="" />
                    {val.title}
                  </div>
                  {ind !== dropDownData.length - 1 && <div className={style.divider}></div>}
                </div>
              );
            })}
          </div>
          {showClear && (
            <div className={style.clear} onClick={clear}>
              CLEAR
            </div>
          )}
        </div>
      }
      {dropDownActive && <div className={style.overlay} onClick={() => setDropDownActive(!dropDownActive)}></div>}
    </div>
  );
};

export default PropertyListDD;
