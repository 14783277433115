import React from "react";
import style from "./style.module.scss";
const SettingFaq = () => {
  const openAccordians: any = [];

  const activeAccordian = (e: any, id: any) => {
    e.stopPropagation();
    const ele = document.getElementById(`${id}`);
    const allAccordian = document.querySelectorAll(`.login_accordian`);
    for (let index = 0; index < allAccordian.length; index++) {
      allAccordian[index].classList.remove(style.active);
    }

    ele?.classList.add(style.active);
  };

  // const toggleAccordian = (index: any) => {
  //   if (openAccordians.includes(index)) {
  //     const i = openAccordians.findIndex((x) => x === index);

  //     if (i > -1) {
  //       const oa = openAccordians;
  //       oa.splice(i, 1);
  //       setopenAccordians(() => openAccordians.splice(i, 0));
  //     }
  //   } else {
  //     setopenAccordians([...openAccordians, index]);
  //   }
  // };

  const accordianData = [
    {
      text: "1.	How many days of stay is each owner entitled to in a calendar year",
      body: "	For each share held the owner is entitled to stay for 45 days in a calendar year. ",
    },
    {
      text: "2.	How many days can each owner stay at a stretch during “General Days”?",
      body: "Each owner can stay for 14 days at a stretch during “General Days”.  The gap between two bookings during “General Days” must be 7 days",
    },
    {
      text: "3.	How do you define “General Days”?",
      body: "General days are those which don’t fall under Special Days or Summer Season.",
    },
    {
      text: "4.	How do you define “Special Days”",
      body: "Festive Holidays and Government Holidays (which may give access to long weekends) are termed as “Special Days''. Link to the list of “Special Days” is shared in the document below and will always be available with our concierge team.",
    },
    {
      text: "5.	How do you define “Summer Season”?",
      body: "	Months of April, May & June are considered as the summer season. ",
    },
    {
      text: "6.	What are the general terms applicable to book a stay?",
      body: "	Co-owners can book their stay 2 days in advance and forward booking can be done up to 2 years in advance.  Each owner will have access to our YOURSAPP which will guide you through the booking process. The APP with its built-in Artificial Intelligence will manage allocations between General Days, Special Days and Summer Season in a democratic way.",
    },
    {
      text: "7.	How many General days can an owner pre-block?",
      body: "	A co-owner can hold a batch of 4 general day bookings at any given point of time. ",
    },
    {
      text: "8.	Can general day booking be extended by a co-owner?",
      body: "	Stay during general days can be extended subject to availability and provided such extension being within the overall limit of 45 days. ",
    },
    {
      text: "9.	What are the cancellation rules applicable for bookings done on General Days?",
      body: "	To give a fair chance for other co-owners, any general day booking must be canceled 7 days prior to the booking date. ",
    },
    {
      text: "10.	What happens if cancellation rules are not followed?",
      body: "	If cancellation rules are not followed, the days will be adjusted against the overall stay entitlement of the co-owner. This rule applies to all classifications (General days, Special Days & Summer Season). ",
    },
    {
      text: "11.	How many days can each co-owner book at a stretch during the summer season?",
      body: "	Maximum length of stay is 10 days during summer season and the gap between 2 bookings must be 12 days.      ",
    },
    {
      text: "12.	What are the rules applicable for booking during Special Days?",
      body: "	Every owner can hold only 2 “Special Days” in a calendar year at a time. After using one of these special days, you can book your next one. ",
    },
    {
      text: "13.	What is the cancellation policy applicable for Special Days?",
      body: "	To be fair to other co-owners, any special day booking must be canceled 30 days in advance. ",
    },
    {
      text: "14.	Are there any other terms and conditions to be considered?",
      body: "	14 days before a special date, if none of the co-owners have booked, it will no longer be treated as a special date. They will now fall under the General Days category. ",
    },
    {
      text: "15.	What dates are classified as “Special days”?",
      body: "	 List of special days for calendar year 2022, 2023 & 2024 are listed in the link.",
    },
  ];

  return (
    <div>
      <div className={style.body}>
        {accordianData.map((val, ind) => {
          return (
            <div
              className={`${style.accordian} login_accordian ${openAccordians.includes(ind) ? style.active : ""}`}
              id={`${ind}`}
            >
              <div className={style.header} onClick={(e) => activeAccordian(e, ind)}>
                <div className={style.text}>{val.text}</div>
                <div className={style.btn}>
                  <img src="/images/plus_icon.svg" className={style.plus_btn} alt="" />
                  <img src="/images/minus_icon.svg" className={style.minus_btn} alt="" />
                </div>
              </div>
              <div className={style.accordian_body}>
                {val.body}
              </div>
              <div className={style.divider}></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SettingFaq;
