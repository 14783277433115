/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APIS } from "../../constants/apis";
import { INotificationModal } from "../../models";
import API from "../../services/api.service";
import "./notification.scss";
import { message } from "antd";

const NotificationComponent: React.FC<INotificationModal> = ({
  showNotification,
  setShowNotification,
  getNotificationIcon,
}: INotificationModal) => {
  const [notiData, setNotiData] = useState<any>([]);
  const navigate = useNavigate();
  const [markRead, setMarkRead] = useState<any>({});

  useEffect(() => {
    getNotifyData();
  }, []);

  const getNotifyData = async () => {
    const res: any = await API.get(APIS.HOME.NOTIFICATION);
    if (res.data.status === 1 && res.data.data.length > 0) {
      setNotiData(res.data);
      getNotificationIcon(res.data.status);
    }
  };
  const dateFormater = (data: string) => {
    const currentdate = new Date(data);
    var today = new Date();
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let monthName = months[currentdate.getMonth()];
    let latestDate = today.getDate();
    let yesterdayDate = latestDate - 1;
    let latestTime = today.getHours();
    let latestMinute = today.getMinutes();
    let hours = currentdate.getHours();
    let minutes: any = currentdate.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    if (latestTime - currentdate.getHours() === 0 && latestDate === currentdate.getDate()) {
      return `at ${strTime}`;
    } else if (latestTime - currentdate.getHours() === 1 && latestDate === currentdate.getDate()) {
      return "1h ago";
    } else if (latestTime - currentdate.getHours() === 2 && latestDate === currentdate.getDate()) {
      return "2h ago";
    } else if (latestTime - currentdate.getHours() === 3 && latestDate === currentdate.getDate()) {
      return "3h ago";
    } else if (latestTime - currentdate.getHours() === 4 && latestDate === currentdate.getDate()) {
      return "4h ago";
    } else if (latestTime - currentdate.getHours() === 5 && latestDate === currentdate.getDate()) {
      return "5h ago";
    } else if (yesterdayDate === currentdate.getDate()) {
      return `yesterday at ${strTime}`;
    } else {
      return `${currentdate.getDate()} ${monthName} ${currentdate.getFullYear()} at ${strTime}`;
    }
  };
  const navigateNotification = (id: number) => {
    setShowNotification(!showNotification);
    navigate("/property/" + id, { replace: false });
  };
  const key = "updatable";

  const openMessage = async () => {
    const res: any = await API.post(APIS.HOME.NOTIFICATION_READ);
    setMarkRead(res.data);
    setShowNotification(!showNotification);
    message.loading({ content: "Loading...", key });
    setTimeout(() => {
      message.success({ content: markRead.data.Message, key, duration: 2 });
    }, 1000);
  };
  const sortNotifiction = () => {
    var sortNoti = [...notiData.data];
    sortNoti.sort((a, b) => b.time.localeCompare(a.time));
    setNotiData(sortNoti);
  };
  if (notiData.status === 1) {
    sortNotifiction();
  }
  return (
    <>
      <div className="header_notification_drop_down_contaner">
        {showNotification && (
          <div className="header_notification_drop_down">
            {notiData ? (
              <>
                {notiData.map((val: any, index: number) => {
                  return (
                    <>
                      <div className="notification_card" key={index}>
                        <div className="icon_section">
                          <img src="/images/Home/property.svg" alt="" className="sidebard--option-icon" />
                        </div>
                        <div className="card_body">
                          <div className="header">
                            <div className="req_type">{val.category}</div>
                            <div className="time">{dateFormater(val.time)}</div>
                          </div>
                          <div className="title">{val.description}</div>
                          <div className={`view_request`} onClick={(e) => navigateNotification(val.property_id)}>
                            <span>{val.type}</span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <div className="noNotiData">No Notification found</div>
            )}
            {notiData.length > 0 ? (
              <div className="footer">
                <span
                  onClick={() => {
                    openMessage();
                  }}
                >
                  Mark as read
                </span>
              </div>
            ) : (
              <div className="noNotiData">No Notification found</div>
            )}
          </div>
        )}
      </div>{" "}
    </>
  );
};

export default NotificationComponent;
