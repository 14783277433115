import { FC, useState } from "react";
import style from "./style.module.scss";
import ColorDropDown from "../../DropDown/colorDD";
import AmenitiesComponent from "../amenities";
import PropertyFeature from "../propertyFeature";
import { PropertyInfoType } from "../type";
import LocationComponent from "../../Location";
import UploadPDFComponent from "../uploadPDF";
import { propertyFieldValidator } from "../helper";
import { locationValidator } from "../../Location/helper";

const PropertInfo: FC<PropertyInfoType> = ({ config, propertyData, setPropertyData, isValidate }) => {
  // const [validation, isValidate] = useState(false);
  const [addLocationStatus, setaddLocationStatus] = useState(false);
  const [addLocationBtnStatus, setaddLocationBtnStatus] = useState(false);
  return (
    <div className={style.property_info}>
      <div className={style.input_group}>
        <div className={style.title}>Property Name</div>
        <div className={style.input_field}>
          <input
            type="text"
            value={propertyData.title}
            placeholder="Add Property Name"
            onChange={(e) => {
              if (setPropertyData) setPropertyData({ ...propertyData, title: e.target.value });
            }}
          />
        </div>
        {isValidate && propertyFieldValidator(propertyData, "title") && (
          <div className={style.error}>Please add Property name</div>
        )}
      </div>
      {/* Location */}
      <div className={style.location_btn_grp}>
        <div
          className={`${style.location_title} ${addLocationBtnStatus ? style.active : ""} `}
          style={{ marginBottom: `${addLocationBtnStatus ? "12px" : ""}` }}
        >
          Location
          {addLocationBtnStatus && (
            <>
              {!addLocationStatus && (
                <img src="/images/plus_icon.svg" alt="" onClick={() => setaddLocationStatus(!addLocationStatus)} />
              )}
              {addLocationStatus && (
                <img src="/images/minus_icon.svg" alt="" onClick={() => setaddLocationStatus(!addLocationStatus)} />
              )}
            </>
          )}
        </div>
        {isValidate && locationValidator(propertyData) && <div className={style.error}>Please enter location data</div>}
        {!addLocationStatus && !addLocationBtnStatus && (
          <div
          // className={`${style.input_field}`}
          >
            <button
              onClick={() => {
                setaddLocationStatus(true);
                setaddLocationBtnStatus(true);
              }}
            >
              Add Location
            </button>
          </div>
        )}
        {addLocationStatus && (
          <LocationComponent
            state_id={propertyData.state_id}
            district_id={propertyData.district_id}
            city_id={propertyData.city_id}
            state={propertyData.state}
            district={propertyData.district}
            city={propertyData.city}
            location={propertyData.location}
            pin={propertyData.zipcode}
            pickLatLong={false}
            validation={isValidate}
            onLocationChange={(loc: any) => {
              if (setPropertyData)
                setPropertyData({
                  ...propertyData,
                  ...{
                    zipcode: loc.pin,
                    state: loc.state,
                    city: loc.city,
                    district: loc.district,
                    state_id: loc.state_id,
                    district_id: loc.district_id,
                    city_id: loc.city_id,
                    location: loc.location,
                  },
                });
            }}
          />
        )}
      </div>

      <div className={style.input_group}>
        <div className={style.title}>Property Info</div>
        <div className={style.input_field}>
          <textarea
            placeholder="Add some information about the Property"
            value={propertyData.detail1}
            onChange={(e) => {
              if (setPropertyData) setPropertyData({ ...propertyData, detail1: e.target.value });
            }}
          ></textarea>
        </div>
        {isValidate && propertyData.detail1 === "" && <div className={style.error}>Please add Property Info</div>}
      </div>
      <div className={style.input_group}>
        <div className={style.title}>Property Details</div>
        <div className={style.input_field}>
          <textarea
            placeholder="Add details Property"
            value={propertyData.detail2}
            onChange={(e) => {
              if (setPropertyData) setPropertyData({ ...propertyData, detail2: e.target.value });
            }}
          ></textarea>
        </div>
        {isValidate && propertyData.detail2 === "" && <div className={style.error}>Please add Property Details</div>}
      </div>
      <div className={style.input_group}>
        <div className={style.title}>Choose Color for card</div>
        <div className={style.input_field}>
          <ColorDropDown
            title={propertyData.color}
            width={"100%"}
            selectedColor={propertyData.color}
            onColorChange={(color: any) => {
              if (setPropertyData) setPropertyData({ ...propertyData, color });
            }}
          />
        </div>
      </div>

      <PropertyFeature
        data={propertyData.features}
        featurelist={config.features}
        setData={(features: any) => {
          if (setPropertyData) setPropertyData({ ...propertyData, features });
        }}
      />

      <AmenitiesComponent
        data={propertyData.amenities}
        amentieslist={config.amenities}
        setData={(amenities: any) => {
          if (setPropertyData) setPropertyData({ ...propertyData, amenities });
        }}
      />
      <UploadPDFComponent
        name={"Upload Floor Plan"}
        data={propertyData.attachments_floor_plans}
        setData={(data: any) => {
          if (setPropertyData) setPropertyData({ ...propertyData, floor_plans: data });
        }}
      />
      <UploadPDFComponent
        name={"Upload Brochure"}
        data={propertyData.attachments_brochure}
        setData={(data: any) => {
          if (setPropertyData) setPropertyData({ ...propertyData, brochure: data });
        }}
      />
      <div className={style.input_group}>
        <div className={style.title}>Caretaker Name</div>
        <div className={style.input_field}>
          <input
            type={"text"}
            placeholder="Add Caretaker Name"
            value={propertyData.caretaker_name}
            onChange={(e) => {
              if (setPropertyData)
                setPropertyData({
                  ...propertyData,
                  caretaker_name: e.target.value,
                });
            }}
          />
        </div>
        {isValidate && propertyData.caretaker_name === "" && (
          <div className={style.error}>Please add Caretaker Name</div>
        )}
      </div>
      <div className={style.input_group}>
        <div className={style.title}>Caretaker Phone Number</div>
        <div className={style.input_field}>
          <input
            type={"text"}
            placeholder="Add Caretaker Phone Number"
            value={propertyData.caretaker_mobile}
            onChange={(e) => {
              if (setPropertyData)
                setPropertyData({
                  ...propertyData,
                  caretaker_mobile: e.target.value,
                });
            }}
          />
        </div>
        {isValidate && propertyData.caretaker_mobile === "" && (
          <div className={style.error}>Please add Caretaker Phone number</div>
        )}
      </div>
    </div>
  );
};

export default PropertInfo;
