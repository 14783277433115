/* eslint-disable eqeqeq */
import { FC, useEffect, useState } from "react";
import { checkBoxDD } from "../type";
import style from "./style.module.scss";
const OwnerListDD: FC<checkBoxDD> = ({
  data,
  getData,
  width,
  customClass,
  showClear,
  multi,
  title,
  border,
  height,
  clearAll,
  setClearAll,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [dropDownSelect, setDropDownSelect] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<Array<any>>([]);
  const [selectName, setSelectName] = useState<Array<any>>([]);
  const dropDownData = [
    {
      id: 0,
      first_name: `All ${title ? title : "owners"} selected`,
      last_name: "",
    },
    ...data,
  ];
  const [option, setOption] = useState(
    title !== "" ? `${"Select" + title}` : "Select Owner",
  );
  const menuToggle = (index: any, value: any, id: any) => {
    setOption(value);

    let ids = [...selectedValues];
    let selectedNames = [...selectName];
    if (!multi) {
      ids = [];
      setOption(value);
      setSelectName(value);
    }
    if (option === `All ${title} selected` && id == 0) {
      selectAll();
      const index = ids.indexOf(0);
      ids.splice(index, 1);
      setSelectedValues(ids);
    } else if (id == 0 && multi) {
      selectAll();
      setOption(`All ${title} selected`);
      
    } else {
      if (ids.includes(0)) {
        const index = ids.indexOf(0);
        ids.splice(index, 1);
      }
      if (ids.includes(id)) {
        const index = ids.indexOf(id);
        if (index > -1) {
          ids.splice(index, 1);
        }
      } else {
        ids.push(id);
      }
      if (selectedNames.includes(value)) {
        const index = selectedNames.indexOf(value);
        if (index > -1) {
          selectedNames.splice(index, 1);
        }
      } else {
        selectedNames.push(value);
      }
      setSelectName(selectedNames);
      setSelectedValues(ids);
      if (multi) {
        setOption(ids.length + title + " selected");
      } else {
        setOption(value);
      }
    }
    callback(ids, selectedNames);
    setDropDownSelect(true);
  };

  const callback = (ids: Array<any>, option: any) => {
    if (multi) {
      getData(ids, option);
    } else {
      getData(ids.length > 0 ? ids[0] : 0);
    }
    if (ids.length <= 0) {
      setOption(`${"Select" + title}`);
    }
  };

  const selectAll = () => {
    const ids = dropDownData.map((m: any) => m.id);
    const selectNames = dropDownData.map((m: any) => m.first_name);
    setSelectName(selectNames);
    setSelectedValues(ids);
  };

  const clear = () => {
    setSelectedValues([]);
    setSelectName([]);
    callback([], []);
    setOption("Select " + title);
    if (setClearAll) setClearAll(false);
  };

  useEffect(() => {
    if (clearAll) {
      clear();
    }
  }, [clearAll]);

  return (
    <div
      className={`${style.drop_down_container}  `}
      style={{ width: `${width}`, opacity: `${dropDownActive ? "1" : "0.5"}` }}
    >
      <div
        className={`${style.drop_down_selected}  ${customClass}`}
        onClick={() => setDropDownActive(!dropDownActive)}
        style={{
          border: border ? border : "1px solid #082017",
          opacity: dropDownActive ? "1" : "0.5",
        }}
      >
        {selectedValues.length > 0
          ? selectedValues.length !== dropDownData.length
            ? selectedValues.length + title + " selected"
            : selectedValues.length - 1 + title + " selected"
          : `${"Select" + title}`}
        <img
          src="/images/drop_down_icon.svg"
          className={!dropDownActive ? style.rotate_icon : ""}
          alt=""
        />
      </div>
      {
        <div
          className={style.drop_down_box}
          style={{ display: dropDownActive ? "block" : "none" }}
        >
          <div
            className={style.scorll_box}
            style={{ height: height ? height : "20rem" }}
          >
            {dropDownData.map((val: any, ind: any) => {
              return (
                <>
                  <div
                    key={ind}
                    id={`${ind}`}
                    className={`${style.menu} menu_active ${
                      selectedValues.includes(val.id) ? style.active : ""
                    }`}
                    onClick={(e) =>
                      menuToggle(
                        `${ind}`,
                        val.first_name +
                          " " +
                          (val.last_name ? val.last_name : ""),
                        val.id,
                      )
                    }
                  >
                    <img
                      src="/images/DropDown/radio_active.svg"
                      className={style.active_circle}
                      alt=""
                    />
                    <img
                      src="/images/DropDown/radio_inactive.svg"
                      className={style.inactive_circle}
                      alt=""
                    />
                    {val.first_name} {val.last_name ? val.last_name : ""}
                  </div>
                  {ind !== dropDownData.length - 1 && (
                    <div className={style.divider}></div>
                  )}
                  {/* <div className={style.divider}></div> */}
                </>
              );
            })}
          </div>

          {showClear && (
            <div
              className={style.clear}
              onClick={(e) => {
                clear();
                // setDropDownActive(!dropDownActive);
                // setDropDownSelect(false);
              }}
            >
              CLEAR
            </div>
          )}
        </div>
      }
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(false)}
        ></div>
      )}
    </div>
  );
};

export default OwnerListDD;
