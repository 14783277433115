import Layout from "../../layout/default";
import ComplaintContainer from "../../container/ComplainContainer";
const ComplainPage = () => {
  return (
    <Layout>
      <ComplaintContainer />
    </Layout>
  );
};

export default ComplainPage;
