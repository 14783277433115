import React, { FC, useState } from "react";
import style from "./style.module.scss";

export interface sharedd {
  data?: any;
  getData?: any;
}

const ShareDD: FC<sharedd> = ({ data, getData }) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [option, setOption] = useState(data[0]);

  const menuToggle = (id: any, value: any) => {
    const allMenu = document.querySelectorAll(".menu_active");
    for (let index = 0; index < allMenu.length; index++) {
      allMenu[index].classList.remove(style.active);
    }
    const ele = document.getElementById(id);
    ele?.classList.add(style.active);
    setOption(value);
    if (getData) getData(value);
    setDropDownActive(!dropDownActive);
  };

  return (
    <div className={style.drop_down_container}>
      <div
        className={`${style.drop_down_selected} ${
          dropDownActive ? style.dd_active : ""
        } `}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {option}
        <img
          src="/images/drop_down_icon.svg"
          className={dropDownActive ? "" : style.rotate_icon}
          alt=""
        />
      </div>

      {
        <div
          className={style.drop_down_box}
          style={{ display: dropDownActive ? "block" : "none" }}
        >
          {data.map((val: any, ind: any) => {
            return (
              <>
                <div
                  id={`${ind}_sharedd`}
                  className={`${style.menu} menu_active ${
                    ind === 0 ? style.active : ""
                  }`}
                  onClick={(e) => menuToggle(`${ind}_sharedd`, val)}
                >
                  <img
                    src="/images/DropDown/circle_active.svg"
                    className={style.active_circle}
                    alt=""
                  />
                  <img
                    src="/images/DropDown/circle_inactive.svg"
                    className={style.inactive_circle}
                    alt=""
                  />
                  {val}
                </div>
                {ind !== data.length - 1 && (
                  <div className={style.divider}></div>
                )}
              </>
            );
          })}
        </div>
      }
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(!dropDownActive)}
        ></div>
      )}
    </div>
  );
};

export default ShareDD;
