import React from "react";
import Setting from "../../component/Setting";
const SettingContainer = () => {
  return (
    <div>
      <Setting />
    </div>
  );
};

export default SettingContainer;
