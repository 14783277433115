import { FC, useState } from "react";
import style from "./style.module.scss";
import { ActivityFieldType } from "../../type";
import { uploadFile } from "../../../../utils/helper";
import LocationComponent from "../../../Location";
import LocationDropdown from "../../../Location/LocationDropdown";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ActivityAdd: FC<ActivityFieldType> = ({
  categorylist,
  activityData,
  setActivityData,
  onDelete,
}) => {
  const [addLocationStatus, setaddLocationStatus] = useState(false);
  const [addLocationBtnStatus, setaddLocationBtnStatus] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [uploadData, setUploadData] = useState<Array<any>>(
    activityData.attachments
  );

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];
    file.isUploading = true;
    const dataObj = {
      img: "",
      file: file,
      name: file.name,
      id: null,
    };

    setUploadData([...uploadData, dataObj]);
    const res = await uploadFile(file);
    const data = res.data.data;
    file.isUploading = false;
    if (res.status === 201) {
      setTimeout(() => {
        dataObj.img = data.urls.bucket.small;
        dataObj.id = data.id;
        let newData: any = { ...activityData };
        newData.images = [...newData.images, data.id];
        newData.attachments = [...newData.attachments, dataObj];
        setActivityData(newData);
        setUploadData([...uploadData, dataObj]);
      }, 1000);
    }
  };

  const removeImg = (id: any) => {
    const newData: any = { ...activityData };
    const index = newData.images.findIndex((d: any) => d === id);
    if (index > -1) {
      newData.images.splice(index, 1);
    }
    const index2 = newData.attachments.findIndex((d: any) => d.id === id);
    if (index2 > -1) {
      newData.attachments.splice(index2, 1);
    }
    setActivityData(newData);
    setUploadData(newData.attachments);
  };

  return (
    <div className={style.activity_add_contaier}>
      <div
        className={`${style.header} ${
          collapse === false ? style.noborder : ""
        }`}
      >
        {collapse ? (
          <img
            src="/images/minus_icon.svg"
            alt=""
            onClick={() => setCollapse(!collapse)}
          />
        ) : (
          <img
            src="/images/plus_icon.svg"
            alt=""
            onClick={() => setCollapse(!collapse)}
          />
        )}
        <LocationDropdown
          width={"100%"}
          title={"Activity"}
          data={categorylist}
          valueKey="title"
          selected={activityData.category_id}
          onChange={(cat: any) => {
            setActivityData({ ...activityData, category_id: cat.id });
          }}
        />
        <div className={style.remove} onClick={() => onDelete()}>
          Remove
        </div>
      </div>

      <div className={style.property_info}>
        <div className={style.input_group}>
          <div className={style.title}>Place Name</div>
          <div className={style.input_field}>
            <input
              readOnly={!collapse}
              type="text"
              placeholder="Place Name"
              value={activityData.title}
              onChange={(e) => {
                setActivityData({ ...activityData, title: e.target.value });
              }}
            />
          </div>
        </div>
        {collapse && (
          <>
            <div className={style.input_group}>
              <div className={style.title}>Place Info</div>
              <div className={style.input_field}>
                <textarea
                  value={activityData.description}
                  placeholder="Add some descriptions about the Place"
                  onChange={(e) => {
                    setActivityData({
                      ...activityData,
                      description: e.target.value,
                    });
                  }}
                ></textarea>
              </div>
            </div>
            <div className={style.divider}></div>
            <div className={style.input_group}>
              <div className={style.title}>Location </div>
              {!addLocationStatus && !addLocationBtnStatus && (
                <div className={`${style.input_field}`}>
                  <button
                    onClick={() => {
                      setaddLocationStatus(true);
                      setaddLocationBtnStatus(true);
                    }}
                  >
                    {activityData.state_id?'Update':'Add'} Location
                  </button>
                </div>
              )}
            </div>
            {addLocationStatus && (
              <LocationComponent
                state_id={activityData.state_id}
                district_id={activityData.district_id}
                city_id={activityData.city_id}
                location={activityData.location}
                pin={activityData.zipcode}
                lat={activityData.lat}
                long={activityData.long}
                onLocationChange={(data: any) => {
                  setActivityData({ ...activityData, ...data, zipcode: data.pin});
                }}
                pickLatLong={true}
              />
            )}
            {/* Location end */}

            <div className={style.divider}></div>

            <div className={style.input_group}>
              <div className={style.title}>Upload Property Image</div>
              <div className={style.input_field}>
                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  id="select_input1"
                  className={style.select_input}
                  onChange={(e: any) => handleUpload(e)}
                />
                <div className={style.select_box}>
                  <div className={style.upper}>
                    Drag And Drop image or Browse
                  </div>
                  <div className={style.upper}>
                    Resolution &#40; 328*204 &#41;
                  </div>
                </div>
              </div>
              <div className={style.file_container}>
                {uploadData &&
                  uploadData.length > 0 &&
                  uploadData.map((val: any, ind: any) => {
                    return (
                      <div key={ind} className={style.file}>
                        <img
                          src="/images/close_icon_2.svg"
                          className={style.close_btn}
                          alt=""
                          onClick={() => removeImg(val.id)}
                        />
                        {val.img && (
                          <img
                            src={val.img}
                            className={style.file_img}
                            alt=""
                          />
                        )}
                        {!val.img && <Spin indicator={antIcon} />}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className={style.input_group}>
              <div className={style.title}>Contact Number</div>
              <div className={style.input_field}>
                <input
                maxLength={10}
                  type="text"
                  placeholder="Contact Number"
                  value={activityData.contact_number}
                  onChange={(e) => {
                    setActivityData({
                      ...activityData,
                      contact_number: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className={style.input_group}>
              <div className={style.title}>Website</div>
              <div className={style.input_field}>
                <input
                  type="text"
                  placeholder="Website"
                  value={activityData.weblink}
                  onChange={(e) => {
                    setActivityData({
                      ...activityData,
                      weblink: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ActivityAdd;
