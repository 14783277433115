/* eslint-disable no-new-wrappers */
/* eslint-disable eqeqeq */
import { Modal, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIS } from "../../../../constants/apis";
import API from "../../../../services/api.service";
import style from "./style.module.scss";
export interface editModal {
  data?: any;
  callOwner?: any;
  setShowModal: Function;
  showModal: any;
}
const EditProfileModal: FC<editModal> = ({ setShowModal, showModal, callOwner, data }) => {
  const params = useParams();
  const defaultUser = {
    user_id: data.id,
    first_name: "",
    first_name_error: "",
    last_name: "",
    email: "",
    mobile: "",
    mobile_error: "",
  };
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({ ...defaultUser, ...data });
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const updateData = (val: any, key: any) => {
    setUser({ ...user, [key]: val });
  };

  const resetData = () => {
    setUser({ ...defaultUser, ...data });
  };

  const saveUser = async () => {
    try {
      let error = false;
      const userTemp = { ...user, mobile_error: "", first_name_error: "" };
      if (userTemp.first_name.trim() == "") {
        userTemp.first_name_error = "First name is required";
        error = true;
      }
      if (userTemp.mobile.trim() == "") {
        userTemp.mobile_error = "Contact number is required";
        error = true;
      } else {
        if (userTemp.mobile.trim().length != 10 || isNaN(Number(userTemp.mobile.trim()))) {
          user.mobile_error = "Contact number should be 10 digit only";
          error = true;
        }
      }
      setUser(userTemp);
      if (error) {
        return;
      }
      const res = await API.post(APIS.PROPERTY.UPDATE_USER + params.id, userTemp);
      if (res.data.status === 1) {
        setShowModal(false);
        notification.success({
          message: res.data.message,
          placement: "topRight",
        });
        if (callOwner) callOwner();
      } else {
        notification.error({
          message: res.data.message,
          placement: "topRight",
        });
      }
    } catch (error) {
      
    }
  };

  return (
    <>
      <Modal
        title="Vertically centered modal dialog"
        centered
        visible={show}
        onOk={() => {}}
        onCancel={() => {}}
        keyboard={true}
      >
        <div className={style.modal_container}>
          <div
            className={style.overlay}
            onClick={() => {
              setShow(false);

              setShowModal(false);
            }}
          ></div>
          <div className={style.modal_add_editprofile}>
            <div
              className={style.close_btn}
              onClick={() => {
                setShow(false);

                setShowModal(false);
              }}
            >
              <img src="/images/close_icon.svg" alt="" />
            </div>
            <div className={style.header}>
              <div className={style.title}>Edit Profile</div>
            </div>
            <div className={style.profile_picture}>
              {data.profile_path.length > 0 && (
                <div className={style.img_box}>
                  <img src={data.profile_path[0].file.urls.bucket.file} alt="" />
                </div>
              )}
              {data.profile_path.length == 0 && (
                <div className={style.img_box}>
                  <div className={style.profile_name_img}>
                    {new String(data.first_name).toUpperCase().substring(0, 1)}
                  </div>
                </div>
              )}
            </div>
            <div className={style.body}>
              <div className={style.input_group}>
                <div className={style.title}>First Name</div>
                <div className={`${style.input_field} ${user.first_name_error ? style.error : ""}`}>
                  <input
                    type="text"
                    placeholder="Enter First Name"
                    value={user.first_name}
                    onChange={(e) => {
                      updateData(e.target.value, "first_name");
                    }}
                  />
                  {user.first_name_error && <div className={style.input_error}>{user.first_name_error}</div>}
                </div>
              </div>
              <div className={style.input_group}>
                <div className={style.title}>Last Name</div>
                <div className={style.input_field}>
                  <input
                    type="text"
                    placeholder="Enter Last Name"
                    value={user.last_name}
                    onChange={(e) => {
                      updateData(e.target.value, "last_name");
                    }}
                  />
                </div>
              </div>
              <div className={style.input_group}>
                <div className={style.title}>Email</div>
                <div className={style.input_field}>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    value={user.email}
                    onChange={(e) => {
                      updateData(e.target.value, "email");
                    }}
                  />
                </div>
              </div>
              <div className={style.input_group}>
                <div className={style.title}>Contact number</div>
                <div className={`${style.input_field} ${user.mobile_error ? style.error : ""}`}>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    value={user.mobile}
                    onChange={(e) => {
                      updateData(e.target.value, "mobile");
                    }}
                  />
                  {user.mobile_error && <div className={style.input_error}>{user.mobile_error}</div>}
                </div>
              </div>
              <div className={style.action_box}>
                <button className={style.btn_action} onClick={resetData}>
                  Reset
                </button>
                <button className={`${style.btn_action} ${style.submit} `} onClick={saveUser}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditProfileModal;
