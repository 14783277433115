/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { uploadFile } from "../../../utils/helper";
import { UploadPDFType } from "../type";
import style from "./style.module.scss";
import { LoadingOutlined } from "@ant-design/icons";

const UploadPDFComponent: FC<UploadPDFType> = ({ name, data, setData }) => {
  const [uploadData, setUploadData] = useState<any>(data);
  const [mainData, setMainData] = useState<any>(data.map((a: any) => a.id));
  const handleUpload = async (e: any) => {
    const file = e.target.files[0];
    let dataObj = {
      img: "",
      file: file,
      name: file.name,
      id: "",
    };
    file.isUploading = true;
    const res = await uploadFile(file);
    if (res.status === 201) file.isUploading = false;
    const data = await res.data.data;

    dataObj.img = data.urls.bucket.small;
    dataObj.id = data.id;
    const newData = [...uploadData, dataObj];

    setMainData([...mainData, data.id]);
    setUploadData(newData);
  };
  const removeData = (index: any) => {
    const newData = uploadData.filter((val: any, ind: any) => {
      return ind !== index;
    });
    const newMainData = mainData.filter((val: any, ind: any) => {
      return ind !== index;
    });
    setMainData(newMainData);
    setUploadData(newData);
  };

  useEffect(() => {
    if (setData) {
      setData(mainData);
    }
  }, [mainData]);

  return (
    <div className={style.input_group}>
      <div className={style.title}>{name}</div>

      <div className={style.input_field}>
        <input
          type="file"
          name=""
          id="select_input1"
          accept="application/pdf"
          className={style.select_input}
          onChange={(e: any) => handleUpload(e)}
        />
        <div className={style.select_box}>
          <div className={style.upper}>
            Drag And Drop image or <span className={style.highlight}>Browse</span>{" "}
          </div>
          <div className={style.upper}>PDF Only</div>
        </div>
      </div>

      <div className={style.upload_file_container}>
        {uploadData &&
          uploadData.map((val: any, ind: any) => {
            return (
              <div key={ind}>
                {val.file && val.file.isUploading && <LoadingOutlined />}
                {!(val.file && val.file.isUploading) && (
                  <div className={style.file_container}>
                    <div className={style.file_name}>
                      <img src="/images/pdf_file.svg" alt="" />
                      {val.name}
                    </div>
                    <img src="/images/delete.svg" alt="" className={style.remove} onClick={() => removeData(ind)} />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default UploadPDFComponent;
