/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import CirclularMenuDropDown from "../../DropDown/RadioDropdown";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import "./style.scss";
import { bookingHistory } from "../type";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
import OwnerListDD from "../../Home/ownerListDD";
import BookingDrawerComponent from "../../Home/bookingdrawer";
import moment from "moment";
const BookingHistory: FC<bookingHistory> = ({ property_id }) => {
  let defaultParams = {
    limit: 500,
    owners: [],
  };
  const [params, setParams] = useState<any>(defaultParams);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState<any>(null);
  const [owners, setOwners] = useState([]);
  const [data, setData] = useState<Array<any>>([]);
  const [ownerClear, setOwnerClear] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [clearStatus, setClearStatus] = useState(false);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const nth = function (d: any) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const onDateChange: DatePickerProps["onChange"] = (date: any, dateString) => {
    const p = { ...params };
    if (dateString == null || date == null) delete p["month"];
    else p["month"] = dateString + "-" + "01";
    setParams(p);
    getData(p);
    setSelectedDate(dateString);
  };

  useEffect(() => {
    fetchOwners();
    getData(params);
  }, []);

  const fetchOwners = async () => {
    const res: any = await API.get(APIS.PROPERTY.GET_OWNERS + property_id);
    setOwners(res.data.data);
  };

  const getData = async (filter: any) => {
    const res = await API.post(
      APIS.PROPERTY.BOOKING_HISTORY + property_id,
      filter,
    );
    setData(res.data.data.rows);
  };

  const clearFilter = () => {
    setParams(defaultParams);
    getData(defaultParams);
    setOwnerClear(true);
    setSelectedDate(null);
    setClearStatus(true);
  };

  return (
    <div className={style.booking_history}>
      <div className={style.filter_container}>
        <div className={style.left}>
          <CirclularMenuDropDown
            width={"10rem"}
            data={["All", "Confirmed", "Cancelled"]}
            getData={(index: number) => {
              const p: any = { ...params };
              if (index == 0) {
                delete p.status;
              } else if (index == 2) {
                p.status = 0;
              } else {
                p.status = index;
              }
              getData(p);
              setParams(p);
            }}
            clearAll={clearStatus}
            setClearAll={setClearStatus}
            customClass={style.property_detail_dd}
          />
        </div>
        <div className={style.right}>
          <DatePicker
            picker="month"
            value={
              selectedDate && selectedDate != "" ? moment(selectedDate) : null
            }
            onChange={onDateChange}
            format={"YYYY-MM"}
            allowClear={false}
            className={style.property_detail_date_picker}
          />
          <OwnerListDD
            title={" owner"}
            width={"180px"}
            customClass={style.property_detail_dd}
            data={owners}
            getData={(ids: any) => {
              const p: any = { ...params };
              p["owners"] = ids;
              getData(p);
              setParams(p);
            }}
            showClear={true}
            multi={true}
            clearAll={ownerClear}
            setClearAll={setOwnerClear}
          />
          {(params["month"] ||
            params["owners"].length > 0 ||
            params["status"] == 0 ||
            params["status"] == 1) && (
            <span className={style.clear_selected} onClick={clearFilter}>
              Clear filters
            </span>
          )}
        </div>
      </div>
      <div className={style.booking_history_card_container}>
        {data &&
          data.map((val: any, ind: any) => (
            <div className={style.card} key={ind}>
              <div className={style.left}>
                <div className={style.upper}>
                  <div className={style.date}>
                    {new Date(val.start_date).getUTCDate()}
                    {nth(new Date(val.start_date).getUTCDate())}-
                    {new Date(val.end_date).getUTCDate()}
                    {nth(new Date(val.end_date).getUTCDate())}{" "}
                    {month[new Date(val.end_date).getMonth()]},{" "}
                    {new Date(val.end_date).getFullYear()}{" "}
                  </div>
                  {val.details.type === 3 && (
                    <div className={style.highpeek}>
                      <div className={style.dot}></div>
                      High Peak
                    </div>
                  )}
                  {val.details.type === 2 && (
                    <div className={style.peek}>
                      <div className={style.dot}></div>
                      Peak
                    </div>
                  )}
                </div>
                <div className={style.lower}>
                  <img src="/images/property/member_icon.svg" alt="no image" />
                  &nbsp;&nbsp;&nbsp;
                  {val.details.guest.name}{" "}
                  {val.booking_for !== "MYSELF" ? <>&#40;Guest&#41;</> : " "}
                  {val.details.guest.count - 1 > 0
                    ? val.details.guest.count - 1 == 1
                      ? "+ " + (val.details.guest.count - 1) + " Member"
                      : "+ " + (val.details.guest.count - 1) + " Members"
                    : ""}{" "}
                </div>
              </div>
              <div className={style.right}>
                <div className={style.booking_detail}>
                  <div
                    className={`${style.status} ${
                      val.status == 1 ? style.confirmed : style.cancelled
                    }`}
                  >
                    {val.status === 0 ? "Cancelled" : "Confirmed"}
                  </div>
                  <div className={style.booking_date}>
                    Booking Date :{" "}
                    <span>
                      {" "}
                      {new Date(val.created_at).getUTCDate()}
                      {nth(new Date(val.created_at).getUTCDate())}{" "}
                      {month[new Date(val.created_at).getMonth()]},{" "}
                      {new Date(val.created_at).getFullYear()}{" "}
                    </span>
                  </div>
                </div>
                <i
                  className="icon-arrow-left3"
                  onClick={() => {
                    setShowDrawer(true);
                    setDrawerData(val);
                  }}
                ></i>
              </div>
            </div>
          ))}
      </div>
      <BookingDrawerComponent
        data={drawerData}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        onUpdate={getData}
        param={params}
      />
    </div>
  );
};

export default BookingHistory;
