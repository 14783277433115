import React, { useState } from "react";
import style from "./style.module.scss";
import PropertyDetailTab from "../propertyDetailTab";
import UserManagement from "../userManagement";
import MaintenanceLog from "../maintenanceLog";
import { FC } from "react";
export interface detailtab {
  data?: any;
}
const DetailsTab: FC<detailtab> = ({ data }) => {
  const [activeTab, setactiveTab] = useState(0);
  const handleMainTabs = (index: any, tabPannelId: any) => {
    setactiveTab(index);
  };
  return (
    <div className={style.details_tab_main}>
      <div className={style.tabs_top_container}>
        <div
          className={`${style.tabs} prop_details_main_tabs ${activeTab === 0 ? style.active : ""} `}
          onClick={(e) => handleMainTabs(0, "propertyDTab")}
        >
          Property Details
        </div>
        <div
          className={`${style.tabs} prop_details_main_tabs ${activeTab === 1 ? style.active : ""} `}
          onClick={(e) => handleMainTabs(1, "maintenanceLogTab")}
        >
          Maintenance log
        </div>
        <div
          className={`${style.tabs} prop_details_main_tabs ${activeTab === 2 ? style.active : ""} `}
          onClick={(e) => handleMainTabs(2, "userMngTab")}
        >
          User Management
        </div>
      </div>
      <div className={style.tab_main_body}>
        {activeTab === 0 && (
          <div className={`${style.tab_main_pannel} tab_main_pnls ${style.active}`} id="propertyDTab">
            <PropertyDetailTab
              id={data.id}
              complaint_count={data.details.complaint}
              request_count={data.details.booking}
              booking_count={data.details.booking_history}
            />
          </div>
        )}
        {activeTab === 1 && (
          <div className={`${style.tab_main_pannel} tab_main_pnls `} id="maintenanceLogTab">
            <MaintenanceLog />
          </div>
        )}
        {activeTab === 2 && (
          <div className={`${style.tab_main_pannel} tab_main_pnls `} id="userMngTab">
            <UserManagement />
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsTab;
