/* eslint-disable eqeqeq */
import { Modal, notification } from "antd";
import React, { FC, useState } from "react";
import { APIS } from "../../../../constants/apis";
import API from "../../../../services/api.service";
import AddServiceComponent from "../../serviceDrawer";
import style from "./style.module.scss";
import { nth, month } from "../../../../utils/helper";
export interface maintenancecard {
  data?: any;
  onupdate: Function;
}
const UpcomingMaintanenceCard: FC<maintenancecard> = ({ data, onupdate }) => {
  let serviceIcons: any = {
    1: "/images/servicesimg/pestcontrol.svg",
    2: "/images/servicesimg/gardening.svg",
    3: "/images/servicesimg/deepcleaning.svg",
    4: "/images/servicesimg/plumbing.svg",
    5: "/images/servicesimg/Painting.svg",
  };
  let newData = {};
  newData = {
    service_date: data.service_date,
    info: data.info,
    service_id: data.service_id,
    repeat_type: data.repeat_type,
    provider_name: data.provider_name,
    provider_mobile: data.provider_mobile,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleCom, setIsModalVisibleCom] = useState(false);
  const [isModalVisibleSkip, setIsModalVisibleSkip] = useState(false);
  const [isModalVisibleSkipRes, setIsModalVisibleSkipRes] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  let currDate = new Date();

  const [showDrawer, setshowDrawer] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleComplete = async () => {
    const res = await API.put(APIS.USER_MANAGEMENT.UPDATE_MAINTE_STATUS + data.id, {
      status: 1,
    });
    if (res.data.status == 1) {
      setIsModalVisibleCom(true);
      setIsModalVisible(false);
      onupdate();
    } else {
      notification.info({
        message: `${res.data.message}`,

        placement: "top",
      });
    }
  };
  const handleCancelCom = () => {
    setIsModalVisibleCom(false);
  };

  const showModalSkip = () => {
    setIsModalVisibleSkip(true);
  };
  const handleCancelSkip = () => {
    setIsModalVisibleSkip(false);
  };
  const handleSkip = async () => {
    const res = await API.delete(APIS.USER_MANAGEMENT.DELETE_MAINTANENCE + data.id);
    if (res.data.status == 1) {
      setIsModalVisibleSkipRes(true);
      setIsModalVisibleSkip(false);
      onupdate();
    } else {
      notification.info({
        message: `${res.data.message}`,

        placement: "top",
      });
    }
  };
  const handleCancelSkipRes = () => {
    setIsModalVisibleSkipRes(false);
  };

  const deleteMaintenence = async () => {
    const res = await API.delete(APIS.USER_MANAGEMENT.DELETE_MAINTANENCE + data.id);
    if (res.data.status == 1) {
      onupdate();
      setDeleteModal(false);
    } else {
      notification.info({
        message: `${res.data.message}`,

        placement: "top",
      });
    }
  };

  return (
    <>
      <div className={style.visit_cards}>
        <div className={style.vist_head}>
          <div className={style.head_left}>
            <div className={style.profile_icon}>
              <img src={serviceIcons[data.service_id]} alt="" className={style.img_profile} />
            </div>
            <div className={style.head_name}>
              {data && data.service ? data.service.title : ""}
              <div className={style.head_name_part}>{data.info}</div>
            </div>
          </div>
          <div className={style.head_right}>
            <div className={style.menu_btn}>
              <img
                src="/images/property/more_icon.svg"
                alt=""
                onClick={() => {
                  setShowMenu(!showMenu);
                }}
              />
              {showMenu && (
                <div className={style.menu_list_show}>
                  <div
                    className={style.menu_list}
                    onClick={() => {
                      setshowDrawer(true);
                      setShowMenu(false);
                    }}
                  >
                    <span>
                      <img src="/images/edit.svg" className={style.img_icon} alt={""} />
                      Edit
                    </span>
                  </div>
                  <div className={style.divider}></div>

                  <div
                    className={style.menu_list}
                    onClick={() => {
                      setDeleteModal(true);
                      setShowMenu(false);
                    }}
                  >
                    <span>
                      <img src="/images/delete.svg" className={style.img_icon} alt={""} />
                      Delete
                    </span>
                  </div>

                  {/* <div className={style.divider}></div>
                  <div
                    className={style.menu_list}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    <span>
                      <img
                        src="/images/share_icon.svg"
                        className={style.img_icon}
                        alt={""}
                      />
                      Share
                    </span>
                  </div> */}
                </div>
              )}
              {showMenu && <div className={style.overlay} onClick={() => setShowMenu(false)}></div>}
            </div>
          </div>
        </div>
        <div className={style.visit_container}>
          <div className={style.upcoming_}>
            <div className={style.name}>Upcoming Maintenance </div>
            <div className={style.date}>
              <img src="/images/home/calendar.svg" alt="" /> {new Date(data.service_date).getUTCDate()}
              {nth(new Date(data.service_date).getUTCDate())} {month[new Date(data.service_date).getMonth()]}{" "}
              {new Date(data.service_date).getFullYear()}{" "}
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.contact}>
            <div className={style.name}>Contact</div>
            <div className={style.number}>
              <img src="/images/home/telephone.svg" alt="" />
              {data.provider_mobile ?? "Number Not Avaiable"}
            </div>
          </div>
        </div>
        <div className={style.visit_footer}>
          <div className={style.footer_left} onClick={showModalSkip}>
            Skip
          </div>
          <div
            className={style.footer_right}
            style={{
              opacity: `${currDate < new Date(data.service_date) ? 0.5 : 1}`,
              cursor: `${currDate < new Date(data.service_date) ? "not-allowed" : "pointer"}`,
            }}
            onClick={() => {
              if (currDate >= new Date(data.service_date)) showModal();
            }}
          >
            Complete
          </div>
        </div>
      </div>
      <Modal visible={isModalVisible} footer={null} width={650} onCancel={handleCancel} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className={style.modal_head}>Complete {data && data.service ? data.service.title : ""}</div>
          <div className={style.modal_content} style={{}}>
            On clicking complete, It will be considered as the services has been completed. Are you sure you want to
            continue?{" "}
          </div>
          <div className={style.group_btn}>
            <div className={style.cancel_btn} onClick={handleCancel}>
              Cancel
            </div>
            <div className={style.com_btn} onClick={handleComplete}>
              Complete
            </div>
          </div>
        </div>
      </Modal>
      <Modal visible={isModalVisibleCom} footer={null} width={650} onCancel={handleCancelCom} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/property/modal_success_icon.png" alt="" />
          <div className={style.modal_head}>Service complete</div>
        </div>
      </Modal>
      <Modal visible={isModalVisibleSkip} footer={null} width={650} onCancel={handleCancelSkip} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div className={style.modal_head}>Are you sure you want to skip?</div>
          <div className={style.modal_content} style={{}}>
            You will be skipping services if you click skip. Are you certain you want to proceed?
          </div>
          <div className={style.group_btn_skip}>
            <div className={style.cancel_btn} onClick={handleCancelSkip}>
              Cancel
            </div>
            <div className={style.com_btn} onClick={handleSkip}>
              Skip
            </div>
          </div>
        </div>
      </Modal>
      <Modal visible={isModalVisibleSkipRes} footer={null} width={650} onCancel={handleCancelSkipRes} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/property/skip_img.svg" alt="" />
          <div className={style.modal_head}>The maintenance service has been skipped</div>
          <div className={style.modal_content}>please make sure to schedule another one as soon as possible.</div>
        </div>
      </Modal>
      <Modal visible={deleteModal} footer={null} onCancel={() => setDeleteModal(false)} keyboard={true}>
        <div className={style.modal_delete}>
          <div className={style.modal_header}>Delete service</div>
          <div className={style.modal_content} style={{}}>
            On clicking delete, You'll be deleting a service. Are you sure you want to continue?
          </div>
          <div className={style.modal_btns}>
            <div className={style.cancel} onClick={() => setIsModalVisible(false)}>
              Cancel
            </div>
            <div className={style.delete} onClick={deleteMaintenence}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
      {showDrawer && (
        <AddServiceComponent
          showDrawer={showDrawer}
          setShowDrawer={setshowDrawer}
          updateData={newData}
          onupdate={onupdate}
          id={data.id}
        />
      )}
    </>
  );
};

export default UpcomingMaintanenceCard;
