import React from "react";
import Layout from "../../layout/default";
import SettingProfileContainer from "../../container/SettingProfile";
const SettingProfilePage = () => {
  return (
    <Layout>
      <SettingProfileContainer />
    </Layout>
  );
};

export default SettingProfilePage;
