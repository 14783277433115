import { createContext, useContext } from "react";

export type AuthType = {
  isAuthenticated: any;
  user: any;
  sendOtpEmail: Function;
  sendOtpPhone: Function;
  login: Function;
  logout: Function;
  loading: any;
  token: any;
  access: any;
  getProfileData: Function;
};
export const AuthContext = createContext<AuthType>({
  isAuthenticated: null,
  user: null,
  login: () => {},
  logout: () => {},
  sendOtpEmail: () => {},
  sendOtpPhone: () => {},
  loading: null,
  token: null,
  access: null,
  getProfileData: () => {},
});

export const useAuth = () => useContext(AuthContext);
