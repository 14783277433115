import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { routeMap } from "../utils/constants";
import SettingPage from "../pages/Setting";
import HomePage from "../pages/home";
import LoginPage from "../pages/LoginPage";
import PropetiesPage from "../pages/properties";
import ComplainPage from "../pages/Complaint";
import RequestPage from "../pages/request";
import SettingProfilePage from "../pages/SettingProfile";
import AuthProvider from "../store/context/auth";
import PropertyPage from "../pages/property";
export type RouterProps = {};

const Router: FC<RouterProps> = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path={routeMap.home} element={<HomePage />} />
        <Route path={routeMap.login} element={<LoginPage />} />
        <Route path={routeMap.properties} element={<PropetiesPage />} />
        <Route path={routeMap.property} element={<PropertyPage />} />
        <Route path={routeMap.profile} element={<SettingProfilePage />} />
        <Route path={routeMap.complaint} element={<ComplainPage />} />
        <Route path={routeMap.request} element={<RequestPage />} />
        <Route path={routeMap.setting} element={<SettingPage />} />
        <Route path={"*"} element={<LoginPage />} />
      </Routes>
    </AuthProvider>
  );
};

export default Router;
