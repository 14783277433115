/* eslint-disable eqeqeq */
import { FC, useState } from "react";
import { circleDD } from "../type";
import style from "./style.module.scss";
const ServiceDropDown: FC<circleDD> = ({ data, customClass, getData, width, title }) => {
  const [dropDownActive, setDropDownActive] = useState(false);

  const menuToggle = (id: any, value: any) => {
    if (getData) getData(id + 1);
    setDropDownActive(!dropDownActive);
  };

  return (
    <div
      className={style.drop_down_container}
      style={{ width: `${width}`, opacity: `${dropDownActive ? "1" : "0.5"}` }}
    >
      <div
        className={`${style.drop_down_selected} ${dropDownActive ? style.dd_active : ""} ${customClass}`}
        style={{ opacity: `${dropDownActive ? "1" : "0.9"}` }}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {title !== null ? data[title - 1] : "Select service"}
        <img src="/images/drop_down_icon.svg" className={dropDownActive ? "" : style.rotate_icon} alt="" />
      </div>

      {
        <div className={style.drop_down_box} style={{ display: dropDownActive ? "block" : "none", width: width }}>
          {data.map((val: any, ind: any) => {
            return (
              <>
                <div
                  id={`${ind}`}
                  key={ind}
                  className={`${style.menu} menu_active `}
                  onClick={(e) => menuToggle(ind, val)}
                >
                  {data[title - 1] == val ? (
                    <img src="/images/DropDown/circle_active.svg" alt="" />
                  ) : (
                    <img src="/images/DropDown/circle_inactive.svg" alt="" />
                  )}
                  {val}
                </div>
                {ind !== data.length - 1 && <div className={style.divider}></div>}
              </>
            );
          })}
        </div>
      }
      {dropDownActive && <div className={style.overlay} onClick={() => setDropDownActive(!dropDownActive)}></div>}
    </div>
  );
};

export default ServiceDropDown;
