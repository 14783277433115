/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
export interface Tasklist {
  listData: any;
  getData: Function;
  width?: any;
  customClass?: any;
  showClear?: any;
  remove?: any;
  multi?: any;
  data?: any;
  setMainData?: Function;
}
const TaskList: FC<Tasklist> = ({ listData, getData, width, data, customClass, showClear, remove, multi }) => {
  const [dropDownActive, setDropDownActive] = useState(false);

  const [selectedValues, setSelectedValues] = useState<Array<any>>(data);
  const dropDownData = [...listData];
  const [option, setOption] = useState("Select the tasks you have completed");
  const menuToggle = (index: any, value: any, id: any) => {
    setOption(value);
    let vals = [...selectedValues];
    if (!multi) {
      vals = [];
      setOption(value);
    }

    if (vals.includes(value)) {
      const index = vals.indexOf(value);
      if (index > -1) {
        vals.splice(index, 1);
      }
    } else {
      vals.push(value);
    }
    setSelectedValues(vals);
    if (multi) {
      if (vals.length === 0) setOption("Select the tasks you have completed");
      else setOption(` ${vals.length} of ${dropDownData.length} selected`);
    } else {
      setOption(value);
    }

    callback(vals);
  };

  const callback = (ids: Array<any>) => {
    if (multi) {
      getData(ids);
    } else {
      getData(ids.length > 0 ? ids[0] : 0);
    }
  };

  // const selectAll = () => {
  //   const ids = dropDownData.map((m: any) => m.id);
  //   setSelectedValues(ids);
  // };

  const clear = () => {
    setSelectedValues([]);
    callback([]);
  };

  // const removeTask = () => {

  //   let vals = [...selectedValues];
  //   if (vals.includes(remove)) {
  //     const index = vals.indexOf(remove);
  //     if (index > -1) {
  //       vals.splice(index, 1);
  //     }
  //   }
  //   setSelectedValues(vals);
  //   callback(vals);
  //   setOption(` ${vals.length} of ${dropDownData.length} selected`);
  // };

  useEffect(() => {
    setSelectedValues(data);
    if (data.length === 0) setOption("Select the tasks you have completed");
    else setOption(` ${data.length} of ${dropDownData.length} selected`);
  }, [data]);

  return (
    <div className={`${style.drop_down_container}  `} style={{ width: `${width}` }}>
      <div
        className={`${style.drop_down_selected} ${dropDownActive ? style.dd_active : ""} ${customClass}`}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {option}
        <img src="/images/drop_down_icon.svg" className={dropDownActive ? "" : style.rotate_icon} alt="" />
      </div>

      {
        <div className={style.drop_down_box} style={{ display: dropDownActive ? "block" : "none" }}>
          {dropDownData.map((val: any, ind: any) => {
            return (
              <>
                <div
                  key={ind}
                  id={`${ind}`}
                  className={`${style.menu} menu_active ${selectedValues.includes(val) ? style.active : ""}`}
                  onClick={(e) => menuToggle(`${ind}`, val, ind)}
                >
                  <img src="/images/DropDown/radio_active.svg" className={style.active_circle} alt="" />
                  <img src="/images/DropDown/radio_inactive.svg" className={style.inactive_circle} alt="" />
                  {val}
                </div>
                {ind !== dropDownData.length - 1 && <div className={style.divider}></div>}
              </>
            );
          })}
          {showClear && (
            <div className={style.clear} onClick={(e) => clear()}>
              CLEAR
            </div>
          )}
        </div>
      }
      {dropDownActive && <div className={style.overlay} onClick={() => setDropDownActive(!dropDownActive)}></div>}
    </div>
  );
};

export default TaskList;
