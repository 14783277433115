/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
import UpcomingMainteComponent from "../upcomingMainte";
import UpComingVisit from "../upcomingVisits";
import VisitHistoryComponent from "../visitHistory";
import MainteneceHistory from "../maintenecehistory";
import style from "./style.module.scss";

const MaintenanceLog = () => {
  const [showTabs, setShowTabs] = useState(0);
  const [upcomingData, setUpcomingData] = useState<any>([]);
  const [historyVisit, setHistoryVisit] = useState<any>([]);
  const [upcomingMaintenence, setUpcomingMaintenence] = useState<any>([]);
  const [mainteneceHistoryData, setMainteneceHistoryData] = useState<any>([]);

  const param = useParams();

  const getConfigData = async (date?: any) => {
    let sendDate = "";
    if (date) sendDate = date;
    const res1 = await API.get(APIS.USER_MANAGEMENT.UPCOMING_VISIT + param.id + `?date=${sendDate}`);

    if (res1.data.status === 1) {
      setUpcomingData(res1.data.data);
    } else {
      setUpcomingData([]);
    }
    const res2 = await API.get(APIS.USER_MANAGEMENT.HISTORY_VISIT + param.id);

    if (res2.data.status === 1) {
      setHistoryVisit(res2.data.data);
    } else {
      setHistoryVisit([]);
    }

    const res3 = await API.get(APIS.USER_MANAGEMENT.UPCOMING_MAINTANENCE + param.id + `?date=${sendDate}&service_id=`);
    if (res3.data.status === 1) {
      setUpcomingMaintenence(res3.data.data.data);
    } else {
      setUpcomingMaintenence([]);
    }
    const res4 = await API.get(APIS.USER_MANAGEMENT.HISTORY_MAINTANENCE + param.id + `?date=${sendDate}&service_id=`);
    if (res4.data.status === 1) {
      setMainteneceHistoryData(res4.data.data.data);
    } else {
      setMainteneceHistoryData([]);
    }
  };

  useEffect(() => {
    getConfigData();
  }, []);

  return (
    <div className={style.peroperty_detail_tab}>
      <div className={style.side_tabs}>
        {
          <div
            className={`${style.tab} prop_details_side_tab ${showTabs === 0 ? style.active : ""}`}
            onClick={(e) => setShowTabs(0)}
          >
            Upcoming Visits &#40;
            {upcomingData && <>{upcomingData.length}</>}
            &#41;
          </div>
        }
        {
          <div
            className={`${style.tab} prop_details_side_tab ${showTabs === 1 ? style.active : ""}`}
            onClick={(e) => setShowTabs(1)}
          >
            Visit History &#40;
            {historyVisit && <>{historyVisit.length}</>}
            &#41;
          </div>
        }
        <div
          className={`${style.tab} prop_details_side_tab ${showTabs === 2 ? style.active : ""} `}
          onClick={(e) => setShowTabs(2)}
        >
          Upcoming Maintenance &#40;
          {upcomingMaintenence && <>{upcomingMaintenence.length}</>}
          &#41;
        </div>
        <div
          className={`${style.tab} prop_details_side_tab ${showTabs === 3 ? style.active : ""} `}
          onClick={(e) => setShowTabs(3)}
        >
          Maintenance History &#40;
          {mainteneceHistoryData && <>{mainteneceHistoryData.length}</>}
          &#41;
        </div>
      </div>
      <div className={style.side_tab_body}>
        <div style={{ marginLeft: "25px" }}>
          {showTabs === 0 && (
            <UpComingVisit
              onUpdate={getConfigData}
              data={upcomingData}
              selectDate={(date: any) => {
                getConfigData(date);
              }}
            />
          )}
          {showTabs === 1 && <VisitHistoryComponent callVisitHistory={getConfigData} data={historyVisit} />}
          {showTabs === 2 && (
            <UpcomingMainteComponent onupdate={getConfigData} maintainencedata={upcomingMaintenence} />
          )}
          {showTabs === 3 && (
            <MainteneceHistory
              onupdate={getConfigData}
              maintainencedata={mainteneceHistoryData}
              selectDate={(date: any) => {
                getConfigData(date);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceLog;
