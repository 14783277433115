import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { APIS } from "../../../constants/apis";
import Card from "../../Complaint/card";
import API from "../../../services/api.service";
import { NoDataComponent } from "../../NoDataComponent";

export interface complaintType {}
const ComplaintHome = () => {
  const [data, setData] = useState({
    count: 0,
    rows: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await API.post(APIS.PROPERTY.COMPLAINT + "0", {
      limit: 50,
      status: 0,
    });
    setData(res.data.data);
    const ele: any = window.document.querySelector(
      ".compl_pagi .swiper-pagination-total",
    );
    ele.innerHTML = res.data.data.count;
  };

  const setTotalPagination = () => {

    const nextCount: any = window.document.querySelector(
      ".compl_pagi .swiper-pagination-total",
    );
    nextCount.innerHTML = data.count;
    const prevCount: any = window.document.querySelector(
      ".compl_pagi .swiper-pagination-current",
    );
    if (prevCount.innerHTML == data.count - 1) {
      prevCount.innerHTML = data.count;
    }
  };

  return (
    <>
      <div className="card_header">
        <div className="card_main_head">Pending complaints ({data.count})</div>
        {data && data.count > 0 && (
          <div className="card_slider_icons">
            <i
              className="icon-previous compl_prev"
              onClick={setTotalPagination}
            ></i>
            <div className="swiper-custom_pagi compl_pagi">{data.count}</div>
            <i className="icon-next2 compl_next" onClick={setTotalPagination}>
              {" "}
            </i>
          </div>
        )}
      </div>
      <div className="cards_complaints" id="slider">
        {data.rows.length > 0 ? (
          <Swiper
            pagination={{
              el: ".compl_pagi",
              clickable: true,
              type: "fraction",
            }}
            spaceBetween={50}
            slidesPerView={2}
            navigation={{
              enabled: true,
              prevEl: ".compl_prev",
              nextEl: ".compl_next",
            }}
            onInit={(swiper) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.prevEl = ".icon-previous";
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              swiper.params.navigation.nextEl = ".icon-next2";
              swiper.navigation.update();
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            onSwiper={(swiper) => {}}
          >
            {data.rows.length > 0 &&
              data.rows.map((val: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <Card
                      customClass={"complaint_card_home"}
                      data={val}
                      property_id={val.property_id}
                      onUpdate={(resData: any) => {
                        getData();
                      }}
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        ) : (
          <NoDataComponent complaint="complaint" />
        )}
      </div>
    </>
  );
};

export default ComplaintHome;
