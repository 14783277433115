import API from "../services/api.service";

export const uploadFile = async (data: any) => {
  const formData = new FormData();
  formData.append("file", data);
  const token = window.localStorage.getItem("token") || "";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const res = await API.post("files/upload", formData, { headers });

  return res;
};
export const uploadBlobFile = async (data: any, filename: string) => {
  const formData = new FormData();
  formData.append("file", data, filename);
  const token = window.localStorage.getItem("token") || "";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const res = await API.post("files/upload", formData, { headers });

  return res;
};
export const uploadPropertyData = async (data: any) => {
  const formData = new FormData();
  formData.append("file", data);
  const token = window.localStorage.getItem("token") || "";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const res = await API.post("files/upload", formData, { headers });
  return res;
};

export const getInfo = async (url: any, tokens?: any) => {
  const res = await API.post(url, { role: "MANAGER", limit: 1000 });
  return res;
};

export function formatAMPM(date: any) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export function formatDateMonthYear(date: any) {
  let year = date.getFullYear().toString();
  let date_day = date.getDate();
  date_day = date_day < 10 ? "0" + date_day : date_day;
  let month = date.toLocaleString("default", { month: "short" });
  return date_day + " " + month + " " + year;
}

export function formatDotDate(date: any) {
  let year = date.getFullYear().toString().substring(2);
  let date_day = date.getDate();
  date_day = date_day < 10 ? "0" + date_day : date_day;
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;

  return date_day + "." + month + "." + year;
}

export const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const daysInWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const nth = function (d: any) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const validationIf = function(data:any,fieldStatus:any){
  let updatedStatus = { ...fieldStatus };

    if (data.visit_date === "") {
      updatedStatus = { ...updatedStatus, date: false };
      return updatedStatus;
    }
    if (data.completed_tasks.length === 0) {
      updatedStatus = { ...updatedStatus, tasklist: false };
      return updatedStatus;
    }
    if (data.staff_issue.length === 0) {
      updatedStatus = { ...updatedStatus, stafflist: false };
      return updatedStatus;
    }
    if (data.property_issue.length === 0) {
      updatedStatus = { ...updatedStatus, propertylist: false };
      return updatedStatus;
    }
    if (data.staff_rating === 0) {
      updatedStatus = { ...updatedStatus, staffrating: false };
      return updatedStatus;
    }
    if (data.property_rating === 0) {
      updatedStatus = { ...updatedStatus, propertyrating: false };
    }
    if (data.property_rating > 3) {
      updatedStatus = { ...updatedStatus, propertylist: true };
      return updatedStatus;
    }
    if (data.staff_rating > 3) {
      updatedStatus = { ...updatedStatus, stafflist: true };
      return updatedStatus;
    }
}