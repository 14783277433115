/* eslint-disable eqeqeq */
import React, { FC, useState } from "react";
import style from "./style.module.scss";
import DrawerComplaint from "../drawer";
import { ComplainCardType } from "../type";
import { formatDotDate } from "../../../utils/helper";
const ComplainCard: FC<ComplainCardType> = ({ customClass, data, property_id, onUpdate }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  let icon = "/images/property/pending_icon.svg";
  let status = "Pending";

  if (data.status == 1) {
    icon = "/images/property/accepted_icon.svg";
    status = "Resolved";
  }

  if (data.status == 2) {
    icon = "/images/property/denied_icon.png";
    status = "Denied";
  }
  return (
    <>
      <div className={`${style.cards_complaints} ${style[customClass]}`}>
        <div className={`${style.slider_card} ${customClass}`} onClick={() => setShowDrawer(!showDrawer)}>
          <div className={style.slide_heading}>
            <div className={style.slide_header}>{data.type}</div>
            {customClass != "complaint_card_home" && (
              <div className={style.slide_ticket}>
                {status}
                <img src={icon} alt="" />
              </div>
            )}
          </div>
          <div className={style.slide_content}>{data.notes}</div>
          <div className={style.slide_footer}>
            <div className={style.slide_footer_left}>
              <div className={style.slide_footer_property_name}>
                {" "}
                {data.property.title.toString()}, {data.property.location}
              </div>
              <div className={style.slide_footer_name}>
                {data.booking.user_details.first_name} {data.booking.user_details.last_name}{" "}
              </div>
            </div>
            <div className={style.slide_footer_right}>
              <div className={style.slide_footer_time}>Ticket #{data.id}</div>
              <div className={style.slide_footer_time}>{formatDotDate(new Date(data.complaint_date))}</div>
            </div>
          </div>
        </div>
        <DrawerComplaint
          data={data}
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          property_id={property_id}
          onUpdate={onUpdate}
        />
      </div>
    </>
  );
};

export default ComplainCard;
