import React, { FC, useState } from "react";
import MaintenanceDrawer from "../../maintanaceDrawer";
import style from "./style.module.scss";
export interface upcomingvisitcard {
  data?: any;
  onUpdate: Function;
}
const UpcomingVisitCard: FC<upcomingvisitcard> = ({ data, onUpdate }) => {
  const newData = {
    id: data.id,
    visit_date: data.visit_date,
    completed_tasks:
      data.completed_tasks !== "" ? data.completed_tasks.split(",") : [],
    property_issue: data.property_issue.split(","),
    property_rating: data.property_rating,
    staff_issue: data.staff_issue.split(","),
    staff_rating: data.staff_rating,
    comment: data.comment,
  };

  const [visible, setVisible] = useState(false);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const nth = function (d: any) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      <div className={style.upcoming_visit}>
        <p className={style.name}>Upcoming Visit</p>
        {data && (
          <span className={style.date}>
            {new Date(data.visit_date).getUTCDate()}
            {nth(new Date(data.visit_date).getUTCDate())}{" "}
            {month[new Date(data.visit_date).getMonth()]}{" "}
            {new Date(data.visit_date).getFullYear()}{" "}
          </span>
        )}
        <div className={style.add_btn} onClick={() => setVisible(true)}>
          <img src="/images/property/plus_sm_icon.svg" alt="" />
          Add Details
        </div>
      </div>
      <MaintenanceDrawer
        readonly={false}
        onChange={onUpdate}
        title={"Update monthly visit"}
        visitData={newData}
        showDrawer={visible}
        setShowDrawer={setVisible}
      />
    </>
  );
};

export default UpcomingVisitCard;
