/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import UserCard from "../userCard";
import "./style.scss";
import AddOwnerModal from "../Modals/addowner";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
import { useParams } from "react-router-dom";
const UserManagement: FC = () => {
  const params = useParams();
  const [ownerModalActive, setOwnerModalActive] = useState(false);
  const [owners, setOwners] = useState([]);
  const fetchOwners = async () => {
    const res: any = await API.get(APIS.PROPERTY.GET_OWNERS + params.id);
    setOwners(res.data.data);
  };
  useEffect(() => {
    fetchOwners();
  }, []);

  return (
    <div className={style.user_management}>
      <div className={style.header}>
        <div className={style.left}>
          <div className={style.property_owner}>Property owners &#40;{owners.length}&#41;</div>
        </div>
        <div className={style.right}>
          <div className={style.add_owner} onClick={() => setOwnerModalActive(true)}>
            <img src="/images/plus_icon.svg" alt="" />
            Add Owner
          </div>
        </div>
      </div>
      <div className={style.body}>
        {owners.length > 0 &&
          owners.map((val: any, ind: any) => {
            return <UserCard key={ind} data={val} callOwners={fetchOwners} />;
          })}
      </div>

      <AddOwnerModal showModal={ownerModalActive} setShowModal={setOwnerModalActive} callOwners={fetchOwners} />
    </div>
  );
};

export default UserManagement;
