import React, { FC, useState } from "react";
import style from "./style.module.scss";
import MaintenanceDrawer from "../maintanaceDrawer";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import "./style.scss";
// import { useParams } from "react-router-dom";
import UpcomingVisitCard from "./card";
export interface upcomingvisit {
  data: any;
  onUpdate: Function;
  selectDate: Function;
}
const UpComingVisit: FC<upcomingvisit> = ({ data, selectDate, onUpdate }) => {
  const [visible, setVisible] = useState(false);

  // const param = useParams();

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    selectDate(dateString);
  };

  return (
    <div className={style.up_coming_visit}>
      <div className={style.dropdown_groups}>
        <DatePicker onChange={onChange} className={style.date_picker} />
        <div className={style.add_monthly_btn} onClick={() => setVisible(true)}>
          <img src="/images/property/plus_icon.svg" alt="" />
          Add monthly visit
        </div>
      </div>
      <div className={style.upcoming_cards}>
        {data &&
          data.map((val: any, index: any) => {
            return <UpcomingVisitCard onUpdate={onUpdate} data={val} />;
          })}
      </div>
      <MaintenanceDrawer onChange={onUpdate} showDrawer={visible} setShowDrawer={setVisible} />
    </div>
  );
};

export default UpComingVisit;
