import React, { useEffect, useState } from "react";
import { ISelectOwnerData } from "../../../models";
import style from "./style.module.scss";

const ApplyFilter: React.FC<ISelectOwnerData> = ({ filterData, removeFilterData }: ISelectOwnerData) => {
  const [nameList, setNameList] = useState<Array<any>>(filterData);
  const removeName = (index: number) => {
    let namesL = [...nameList];
    namesL.splice(index, 1);
    setNameList(namesL);
  };

  useEffect(() => {
    setNameList(filterData);
  }, [filterData]);

  return (
    <>
      {nameList.length > 0 && (
        <div className={style.select_owner}>
          {nameList ? (
            <>
              {nameList.length > 0 &&
                nameList.map((val: any, index: number) => {
                  return (
                    <>
                      <div className={style.owner_box}>
                        <span className={style.textName}>{val}</span>{" "}
                        <span className={style.closeIcon} onClick={(e) => removeFilterData(index)}>
                          <i className="icon-close" style={{ fontSize: "10px" }}></i>
                        </span>
                      </div>
                    </>
                  );
                })}
            </>
          ) : (
            ""
          )}
          <span className={style.clear_selected} onClick={() => removeFilterData(-2)}>
            clear Selected
          </span>
        </div>
      )}
    </>
  );
};

export default ApplyFilter;
