/* eslint-disable eqeqeq */
import { FC, useEffect, useState } from "react";
import { Drawer, notification } from "antd";

import style from "./style.module.scss";
import ActivityComponent from "./activity";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";
import { AddPropertyComponentType } from "./type";
import DirectionComponent from "./direction";
import PropertyImage from "./propertyImage";
import PropertInfo from "./propertyInfo";
import {
  getPropertyDefault,
  getUpdateFormat,
  propertyValidator,
} from "./helper";

const AddPropertyComponent: FC<AddPropertyComponentType> = ({
  showDrawer,
  setShowDrawer,
  config,
  data,
  onUpdate,
}) => {
  let defaultData: any = getPropertyDefault();
  let updateData = {};
  if (data) {
    

    updateData = getUpdateFormat(data);
  }
  const [validation, setValidation] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [visible, setVisible] = useState(showDrawer);
  const [propertyData, setPropertyData] = useState({
    ...defaultData,
    ...updateData,
  });

  const addUpdateActivity = async () => {
    const actList = propertyData.activitiesList;
    const activities = [];
    for (const index in actList) {
      const act: any = actList[index];
      if (!act["category_id"]) {
        notification.error({
          message: "Please select category for all activities",
          placement: "topRight",
        });
        return false;
      }
      if (act.id) {
        act.property_id = data.id;
        await API.post(APIS.PROPERTY.UPDATE_ACTIVITY, act);
      } else {
        const res = await API.post(APIS.PROPERTY.ADD_ACTIVITY, act);
        if (res.data.status === 1) {
          act.id = res.data.data.id;
        } else {
          notification.error({
            message: res.data.message,
            placement: "topRight",
          });
          return false;
        }
      }
      activities.push({
        category_id: act.category_id,
        activity_id: act.id,
      });
    }
    const propertyDataUpdated = {
      ...propertyData,
      ...{ activities: activities },
    };
    setPropertyData(propertyDataUpdated);
    return JSON.parse(JSON.stringify(propertyDataUpdated));
  };

  const uploadData = async () => {
    let newData = await addUpdateActivity();
    

    delete newData.activitiesList;
    let res = null;
    if (!data) {
      res = await API.post(APIS.PROPERTY.ADD_PROPERTY, newData);
    } else {
      res = await API.post(APIS.PROPERTY.UPDATE_PROPERTY + data.id, newData);
    }
    if (res.data.status === 1) {
      onUpdate();
      onClose();
    } else {
      notification.error({
        message: res.data.message,
        placement: "topRight",
      });
    }
  };

  const onClose = () => {
    setShowDrawer(false);
    setVisible(false);
  };

  useEffect(() => {
    setVisible(showDrawer);
  }, [showDrawer]);

  const handleTabChange = (id: any, ind: any) => {
    setActiveTab(ind);
  };

  const handleNext = () => {
    if (propertyValidator(propertyData) && activeTab == 0) {
      setValidation(true);
      return;
    }
    if (propertyData.locality_details === "" && activeTab == 2) {
      setValidation(true);
      return;
    }
    if (activeTab < 3) setActiveTab(activeTab + 1);
  };

  return (
    <div className="drawer_component_property">
      <Drawer
        placement="right"
        width={461}
        onClose={onClose}
        visible={visible}
        closable={false}
        bodyStyle={{
          padding: 0,
          position: "relative",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <div className={style.drawer_body_outer}>
          <div className={style.drawer_body}>
            <div className={style.drawer_header}>
              <div className={style.title}>
                {data ? "Update Property" : "Add New Property"}
              </div>
              <div className={style.close_btn} onClick={onClose}>
                <i className="icon-close"></i>
              </div>
            </div>
            <div className={style.tabs_container}>
              {/* active_tab */}
              <div
                className={`${style.tabs} prop_tabs  ${
                  activeTab === 0 ? style.active_tab : ""
                }`}
                onClick={(e) => handleTabChange("prop_info", 0)}
              >
                Property Info
              </div>
              <div
                className={`${style.tabs} prop_tabs  ${
                  activeTab === 1 ? style.active_tab : ""
                }`}
                onClick={(e) => handleTabChange("prop_img", 1)}
              >
                Property images
              </div>
              <div
                className={`${style.tabs} prop_tabs  ${
                  activeTab === 2 ? style.active_tab : ""
                }`}
                onClick={(e) => handleTabChange("prop_direct", 2)}
              >
                Direction
              </div>
              <div
                className={`${style.tabs} prop_tabs  ${
                  activeTab === 3 ? style.active_tab : ""
                }`}
                onClick={(e) => handleTabChange("prop_activity", 3)}
              >
                Activities
              </div>
            </div>
            <div
              className={`${style.property_tabpanel} tab_pannel ${
                activeTab === 0 ? style.active_tab_pannel : ""
              }`}
              id="prop_info"
            >
              {activeTab === 0 && (
                <PropertInfo
                  config={config}
                  propertyData={propertyData}
                  setPropertyData={setPropertyData}
                  isValidate={validation}
                />
              )}
            </div>
            <div
              className={`${style.property_tabpanel} tab_pannel ${
                activeTab === 1 ? style.active_tab_pannel : ""
              }`}
              id="prop_img"
            >
              {activeTab === 1 && (
                <PropertyImage
                  propertyData={propertyData}
                  setPropertyData={setPropertyData}
                />
              )}
            </div>
            <div
              className={`${style.property_tabpanel} tab_pannel ${
                activeTab === 2 ? style.active_tab_pannel : ""
              }`}
              id="prop_direct"
            >
              {activeTab === 2 && (
                <DirectionComponent
                  config={config}
                  propertyData={propertyData}
                  setPropertyData={setPropertyData}
                  setValidation={validation}
                />
              )}
            </div>
            <div
              className={`${style.property_tabpanel} tab_pannel ${
                activeTab === 3 ? style.active_tab_pannel : ""
              }`}
              id="prop_activity"
            >
              {activeTab === 3 && (
                <ActivityComponent
                  config={config}
                  propertyData={propertyData}
                  setPropertyData={setPropertyData}
                />
              )}
            </div>
            <div className={style.drawer_footer}>
              <button className={style.cancel_btn} onClick={onClose}>
                Cancel
              </button>
              {activeTab !== 3 && (
                <button className={style.next_btn} onClick={() => handleNext()}>
                  Next
                </button>
              )}
              {activeTab === 3 && (
                <button className={style.next_btn} onClick={() => uploadData()}>
                  {data ? "Update" : "Add"}
                </button>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default AddPropertyComponent;
