import React from "react";
import Layout from "../../layout/default";
import SettingContainer from "../../container/SettingContainer";
const SettingPage = () => {
  return (
    <Layout>
      <SettingContainer />
    </Layout>
  );
};

export default SettingPage;
