import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import PlainDDComponent from "../../DropDown/normalDD";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import { notification } from "antd";
const SettingContact = () => {
  const phoneDetails = [
    {
      name: "Roshni ",
      phone: "+91 9880775551",
    },
  ];
  // const dropData = ["All", "Data", "is"];
  const [dropData, setDropData] = useState<any>([]);
  const [resetAll, setresetAll] = useState(false);
  let defaultData = {
    subject: "Property",
    content: "",
  };
  const [data, setData] = useState({ ...defaultData });
  const getContact = async () => {
    const res = await API.get(APIS.PROPERTY_PARAMS.CONTACT);
    let data = Object.values(res.data.data);
    setDropData(data);
  };

  const sendFeedback = async () => {
    if (data.subject != "") {
      const res = await API.post(APIS.SETTING.SEND_FEEDBACK, data);
      if (res.data.status === 1) {
        onCancel();

        notification.success({
          message: res.data.message || "FeedBack Sended",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: res.data.message || "Sorry Failed To Send FeedBack",
          placement: "topRight",
        });
      }
    }
  };

  const onCancel = () => {
    setData(defaultData);
    setresetAll(true);
  };

  useEffect(() => {
    getContact();
  }, []);

  // const [optionData, setoptionData] = useState("");

  return (
    <div className={style.setting_contact_section}>
      <div className={style.left}>
        <div className={style.data_section}>
          <div className={style.header}>
            <img src="/images/phone.svg" alt="" />
            Phone
          </div>
          <div className={style.data_body}>
            {phoneDetails.map((val: any, ind: any) => {
              return (
                <div className={style.data_details}>
                  <div className={style.data}>{val.name}</div>
                  <div className={style.data}>{val.phone}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={style.data_section}>
          <div className={style.header}>
            <img src="/images/envelopeopen.svg" alt="" />
            Mail
          </div>
          <div className={style.data_body}>
            <div className={style.mail}>
              <div className={style.data}>reachus@yours.house</div>
            </div>
          </div>
        </div>
        <div className={style.social_connection}>
          <a href="https://www.facebook.com/yours.second.homes/" target="_blank">
            <img src="/images/settings/Facebook.svg" alt="" />{" "}
          </a>
          <a href="https://www.linkedin.com/company/yours-second-homes/about/" target="_blank">
            <img src="/images/settings/Linkedin.svg" alt="bell-icon" />
          </a>
          <a href="https://www.instagram.com/yours_second_homes/" target="_blank">
            <img src="/images/settings/Instagram.svg" alt="" />
          </a>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.msg_box_container}>
          <div className={style.header}>You can also reach us by leaving a message here.</div>
          <div className={style.category_select_dd}>
            <PlainDDComponent
              width={"100%"}
              reset={resetAll}
              setReset={setresetAll}
              data={dropData}
              getData={(res: any) => {
                let newData = { ...data };
                newData.subject = res;
                setData(newData);
              }}
            />
          </div>
          <textarea
            className={style.msg_text_area}
            value={data.content}
            placeholder="Add your Message"
            onChange={(e) => {
              let newData = { ...data };
              newData.content = e.target.value;
              setData(newData);
            }}
          ></textarea>
          <div className={style.action_btn_container}>
            <div className={style.action_btn} onClick={onCancel}>
              Cancel
            </div>
            <div className={style.action_btnblk} onClick={sendFeedback}>
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingContact;
