/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import FAQComponent from "../faq/Index";
import ContactTeam from "../contact";
import { GetHelp } from "../type";
const GetHelpModal: FC<GetHelp> = ({ onClose }) => {
  const [TabSwitcher, setTabSwitcher] = useState(false);

  useEffect(() => {
    function handleKeyDown(e: any) {
      if (e.keyCode === 27) {
        onClose(false);
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      className={style.gethelpmodal_overlay}
    >
      <div
        className={style.get_help_modal}
      >
        <div className={style.header}>
          <div className={style.text}>Get help</div>
          <img src="/images/close_icon.svg" className={style.close_icon} alt="" onClick={(e) => onClose(false)} />
        </div>
        <div className={style.action_container}>
          <button className={`${style.btn} ${TabSwitcher ? style.active : ""}`} onClick={(e) => setTabSwitcher(true)}>
            FAQ
          </button>
          <button className={`${style.btn} ${!TabSwitcher ? style.active : ""}`} onClick={(e) => setTabSwitcher(false)}>
            Contact Team
          </button>
        </div>
        {TabSwitcher && <FAQComponent />}
        {!TabSwitcher && <ContactTeam />}
      </div>
    </div>
  );
};

export default GetHelpModal;
