/* eslint-disable eqeqeq */
/* eslint-disable no-new-wrappers */
import React, { FC, useEffect, useState } from "react";
import { formatDateMonthYear } from "../../../utils/helper";
import style from "./style.module.scss";
import AllBookingModal from "../Modals/allbooking";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import { useParams } from "react-router-dom";
import SuspendAccountModal from "../Modals/suspendaccount";
import EditProfileModal from "../Modals/ediprofile";
export interface usercard {
  data?: any;
  callOwners?: Function;
}

const UserCard: FC<usercard> = ({ data, callOwners }) => {
  const [bookingDetails, setBookingDetails] = useState({
    count: "",
    rows: [],
  });
  const params = useParams();
  const [moreOptionModal, setmoreOptionModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  const [counter, setCounter] = useState(0);
  let start = null,
    end = null;
  let sdate = new Date();
  let edate = new Date();
  if (data.details.upcoming != null) {
    sdate = new Date(data.details.upcoming.start_date);
    edate = new Date(data.details.upcoming.end_date);
    start = formatDateMonthYear(sdate);
    end = formatDateMonthYear(edate);
  }
  const getDayNight = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays + 1;
  };
  // const getDayNight2 = (date_1: any, date_2: any) => {
  //   let difference = date_1.getTime() - date_2.getTime();
  //   let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  //   return TotalDays;
  // };
  const [showModal, setshowModal] = useState(false);
  // const dayLeft = getDayNight2(start, dateNow)
  const getUpcomingData = async () => {
    const body = {
      page: 1,
      limit: 50,
      owners: [`${data.id}`],
      upcoming: true,
      status: 1,
      sort: [["start_date", "ASC"]],
    };
    const res = await API.post(APIS.PROPERTY.BOOKING_HISTORY + params.id, body);
    if (res.data.status === 1) {
      setBookingDetails(res.data.data);
    }
  };

  useEffect(() => {
    getUpcomingData();
  }, []);

  const getDaysLeft = (date_1: any) => {
    const dateNow = new Date();
    const start = new Date(date_1);

    let difference = start.getTime() - dateNow.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
  };

  return (
    <>
      <div className={style.user_card_parent}>
        <div className={style.user_card}>
          <div className={style.body}>
            <div className={style.header}>
              <img src="/images/more_icon.svg" alt="" onClick={() => setmoreOptionModal(true)} />
            </div>
            <div className={style.upper}>
              {data.profile_path.length > 0 && (
                <div className={style.img_box}>
                  <img src={data.profile_path[0].file.urls.bucket.file} alt="" />
                </div>
              )}
              {data.profile_path.length == 0 && (
                <div className={style.img_box}>
                  <div className={style.profile_name_img}>
                    {new String(data.first_name).toUpperCase().substring(0, 1)}
                  </div>
                </div>
              )}
              <div className={style.user_detail}>
                <div className={style.name}>
                  {data.first_name} {data.last_name}
                </div>
                <div className={style.phone}>
                  <img src="/images/phone.svg" alt="" />
                  <a href="tel:+">+91 {data.mobile}</a>
                </div>
                <div className={style.mail}>
                  <img src="/images/mail.svg" alt="" />
                  <a href="mailto:">{data.email}</a>
                </div>
              </div>
            </div>
            <div className={style.divider}></div>
            {data.details.upcoming && (
              <div className={style.lower}>
                <div className={style.upcomming_booking}>
                  Upcoming Bookings &#40; {bookingDetails.rows.length} &#41;
                </div>
                {start && end && (
                  <div className={style.booking_days}>
                    <div className={style.from}>
                      <div className={style.date_box}>
                        <div className={style.date}>{start}</div>
                        {/* <div className={style.year}>2022</div> */}
                      </div>
                      <div className={style.circle_box}>
                        <img src="/images/Home/owner_card_circle.svg" alt="" />
                      </div>
                    </div>
                    <div className={style.days_number}>
                      {getDayNight(edate, sdate)} Days | {getDayNight(edate, sdate) - 1} Night
                    </div>
                    <div className={style.to}>
                      <div className={style.circle_box}>
                        <img src="/images/Home/owner_card_circle.svg" alt="" />
                      </div>
                      <div className={style.date_box}>
                        <div className={style.date}>{end}</div>
                        {/* <div className={style.year}>2022</div> */}
                      </div>
                    </div>
                  </div>
                )}
                {data.details.upcoming && (
                  <div className={style.days_left}>
                    <img src="/images/UserManag/clock.svg" alt="" />
                    {getDaysLeft(data.details.upcoming.start_date)} Days Left
                  </div>
                )}
              </div>
            )}
            {data.details.upcoming == null && (
              <div className={style.lower}>
                <div className={style.days_left}>No Booking Available</div>
              </div>
            )}
          </div>
          <div className={style.footer} onClick={() => setshowModal(true)}>
            Show All Bookings
          </div>
          {moreOptionModal && (
            <div className={style.more_option}>
              <div
                className={style.option}
                onClick={() => {
                  setshowEditModal(true);
                  setmoreOptionModal(false);
                }}
              >
                Edit
              </div>
              <div className={style.divider}></div>
              <div
                className={style.option}
                onClick={() => {
                  setShowSuspendModal(true);
                  setmoreOptionModal(false);
                }}
              >
                Suspend account
              </div>
            </div>
          )}
          {moreOptionModal && <div className={style.overlay} onClick={() => setmoreOptionModal(false)}></div>}
        </div>
      </div>
      {showModal && <AllBookingModal showModal={showModal} setShowModal={setshowModal} data={bookingDetails.rows} />}
      <SuspendAccountModal
        setShowModal={setShowSuspendModal}
        showModal={showSuspendModal}
        data={data}
        onUpdate={setCounter}
        callOwner={callOwners}
      />
      <EditProfileModal setShowModal={setshowEditModal} showModal={showEditModal} data={data} callOwner={callOwners} />
    </>
  );
};

export default UserCard;
