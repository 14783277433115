import React, { FC, useEffect, useState } from "react";
import { RemovePicModal } from "../../type";
import style from "./style.module.scss";
const RemoveProfileImgModal: FC<RemovePicModal> = ({
  showModal,
  setShowModal,
  data,
  profiePicData,
  onDelete,
}) => {
  const [show, setShow] = useState(showModal);
  const closeModal = () => {
    setShowModal(false);
    setShow(false);
  };
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  return (
    <>
      {show && (
        <div className={style.profileimg_modal_container}>
          <div className={style.profileimg_modal}>
            <img
              src="/images/close_icon.svg"
              className={style.close_icon}
              onClick={() => closeModal()}
              alt=""
            />

            {profiePicData.path == null && profiePicData.path === "" && (
              <div className={style.img_box}>
                <img src="/images/Profile/Avatar.svg" alt="" />
              </div>
            )}
            {!!profiePicData.path && profiePicData.path !== "" && (
              <div className={style.img_box}>
                <img src={profiePicData.path} alt="" />
              </div>
            )}
            <div className={style.header}>Remove profile picture?</div>
            <div className={style.drag_drop_text}>
              Your picture will be saved in your Album Archive. This image will
              be used in your profile.
            </div>
            <div className={style.action_btns}>
              <div
                className={style.btn}
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </div>
              <div
                className={style.btnblk}
                onClick={() => {
                  onDelete();
                  closeModal();
                }}
              >
                Remove
              </div>
            </div>
          </div>
          <div className={style.overlay} onClick={() => closeModal()}></div>
        </div>
      )}
    </>
  );
};

export default RemoveProfileImgModal;
