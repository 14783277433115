/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Button, Col, Input, Row } from "antd";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeMap } from "../../utils/constants";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";
import "./header.scss";
import { useAuth } from "../../store/context/login";
import { useEffect } from "react";
import NotificationComponent from "../Notification";
type HeaderProps = {
  companyName: string;
};
const Header: FC<HeaderProps> = ({ companyName }: HeaderProps) => {
  const [showProfileDD, setshowProfileDD] = useState(false);
  const [showNotification, setshowNotification] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [active, setActive] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [cardData, setCardData] = useState<Array<any>>([]);
  const { logout, user } = useAuth();

  const params = { role: "MANAGER" };
  useEffect(() => {
    getData(params);
  }, []);

  const navigate = useNavigate();
  const handleLogOut = () => {
    logout();
    navigate(routeMap.login);
    navigate(0);
  };
  const handleToggle = () => {
    setShowSearch(!showSearch);
  };
  const onChange = (e: any) => {
    if (e.target.value.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
    searchFilter(e.target.value, cardData);
  };
  const searchFilter = (keyword: any, property: any) => {
    if (keyword.length > 0) {
      let result = property
        .map(({ id, title }: any) => ({ id, title }))
        .filter((row: any) => {
          if (
            row.title.toLowerCase().search(keyword.toLowerCase()) > -1 ||
            row.title.toLowerCase().search(keyword.toLowerCase()) > -1 ||
            row.title.toLowerCase().search(keyword.toLowerCase()) > -1 ||
            row.title.toLowerCase().search(keyword.toLowerCase()) > -1
          ) {
            return true;
          }
          return false;
        });
      setSearchResult(result);
    } else {
      setSearchResult([]);
    }
  };
  const navigateProperty = (e: any, key: any) => {
    setActive(false);
    navigate("/property/" + key, { replace: false });
    navigate(0);
  };
  const getData = async (params: any) => {
    const res = await API.post(APIS.HOME.PROPERTY_LIST, params);
    setCardData(res.data.data.properties);
  };
  return (
    <Row className="appHeader">
      <Col span={12} className="headerLeft">
        <img src="/images/Home/logo.png" alt={companyName} className="logo" />
      </Col>
      <Col span={12} className="headerRight">
        {showSearch && (
          <div className="search">
            <Input type={"text"} className="searchBox" onChange={onChange} autoFocus />
            {active && (
              <div className="searchResult">
                {searchResult.map((val: any, index: number) => {
                  return (
                    <div key={index} className="propertyName" onClick={(e) => navigateProperty(e, val.id)}>
                      <div>
                        <img src="/images/Header/build.png" alt="build" />
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            color: "#80A07D",
                          }}
                        >
                          {val.title}
                        </div>
                        <div
                          style={{
                            color: "#082017",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          My Properties
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        {showSearch && <div className="search_overlay" onClick={() => setShowSearch(false)}></div>}
        <Button
          type="default"
          shape="circle"
          onClick={() => handleToggle()}
          icon={
            <Badge dot={false} offset={[3, -3]}>
              <span>
                <img src="/images/Home/search_icon.svg" alt="bell-icon" />
              </span>
            </Badge>
          }
          size="large"
          className="notification-btn"
        />

        <Button
          type="default"
          shape="circle"
          icon={
            <Badge dot={showIcon} offset={[-6, 3]} size="small" style={{ backgroundColor: "#80A07D" }}>
              <span>
                <img src="/images/Home/bell_icon.svg" alt="bell-icon" />
              </span>
            </Badge>
          }
          size="large"
          className="notification-btn"
          onClick={() => {
            setshowProfileDD(false);
            setshowNotification(!showNotification);
            setShowIcon(false);
          }}
        />

        <div className="header_profile_drop_down_contaner">
          <img
            onClick={() => {
              setshowProfileDD(!showProfileDD);
              setshowNotification(false);
            }}
            src={`${
              user && user.profile_path !== null ? user.profile_path.urls.bucket.medium : "/images/Profile/Avatar.svg"
            }`}
            className="header_profile_img"
            alt="bell"
          />
          {showProfileDD && (
            <div className="header_profile_drop_down">
              <div className="upper_body">
                <img
                  src={`${
                    user && user.profile_path !== null
                      ? user.profile_path.urls.bucket.medium
                      : "/images/Profile/Avatar.svg"
                  }`}
                  className="header_profile_img"
                  alt="bell"
                />
                <div className="name">
                  {user.first_name} {user.last_name}
                </div>
                <div className="email">{user.email}</div>
                <Link to={routeMap.profile}>
                  <div className="mng_btn">Manage your Profile</div>
                </Link>
              </div>
              <div className="lower_body">
                <Link to={routeMap.setting}>
                  <div className="user_options">
                    <img src="/images/Profile/settings.svg" alt="" />
                    Settings
                  </div>
                </Link>

                <div className="user_options" onClick={() => handleLogOut()}>
                  <img src="/images/Profile/logout.svg" alt="" />
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
        <NotificationComponent
          showNotification={showNotification}
          setShowNotification={setshowNotification}
          getNotificationIcon={(val: any) => {
            if (val === 1) {
              setShowIcon(true);
            }
          }}
        />
        <img
          onClick={() => setshowProfileDD(!showProfileDD)}
          src="/images/drop_down_icon.svg"
          className="drop_down_img"
          alt="bell"
        />
        {(showNotification || showProfileDD) && (
          <div
            className="overlay_heaer"
            onClick={() => {
              setshowProfileDD(false);
              setshowNotification(false);
            }}
          ></div>
        )}
      </Col>
    </Row>
  );
};

export default Header;
