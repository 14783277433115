import React, { FC, useState } from "react";
import style from "./style.module.scss";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";

import HistoryVisitCard from "./card";
import MaintenanceDrawer from "../maintanaceDrawer";
export interface historyvisit {
  data: any;
  callVisitHistory: Function;
}
const VisitHistoryComponent: FC<historyvisit> = ({ data, callVisitHistory }) => {
  const [visible, setVisible] = useState(false);
  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {};
  return (
    <div className={style.visit_history}>
      <div className={style.dropdown_groups}>
        <DatePicker onChange={onDateChange} className={style.date_picker} />
      </div>
      <div className={style.vist_card_group}>
        {data &&
          data.map((val: any, index: any) => {
            return <HistoryVisitCard data={val} key={index} callVisitHistory={callVisitHistory} />;
          })}
      </div>
      <MaintenanceDrawer
        onChange={callVisitHistory}
        title={"Add personal visit"}
        showDrawer={visible}
        setShowDrawer={setVisible}
      />
    </div>
  );
};

export default VisitHistoryComponent;
