import React, { useState } from "react";
import style from "./style.module.scss";
import "antd/dist/antd.css";
import { Carousel } from "antd";
import EmailInput from "./email";
import OTPInput from "./otp";
import GetHelpModal from "./gethelp";
const LoginComponent = () => {
  const [showOtpContainer, setshowOtpContainer] = useState(false);
  const [showGetHelpModal, setshowGetHelpModal] = useState(false);
  const [userData, setUserData] = useState<any>({
    id: "",
    info: "",
  });
  const onSubmitEmail = (id: any, info: any, showotp: boolean) => {
    setUserData({ id, info });
    setshowOtpContainer(showotp);
  };

  return (
    <>
      <div className={style.login_page}>
        <div className={style.container_left}>
          {showOtpContainer && (
            <OTPInput showEmail={setshowOtpContainer} userData={userData} />
          )}
          {!showOtpContainer && (
            <EmailInput onSubmit={onSubmitEmail} userData={userData} />
          )}
          <div className={style.help_container}>
            Having trouble signing in ?{" "}
            <span
              className={style.highlight}
              onClick={(e) => setshowGetHelpModal(true)}
            >
              {" "}
              Get help
            </span>
          </div>
        </div>
        <div className={style.container_right}>
          <Carousel autoplay>
            <div className={style.carousel_context}>
              <img src="/images/loginpage/carousel_1.png" alt="" />
              <div className={style.context}>
                <img src="/images/loginpage/yours_logo.svg" alt="" />
                <div className={style.para}>
                  Where fractional ownership becomes a new way of owning
                  property
                </div>
              </div>
            </div>
            <div className={style.carousel_context}>
              <img src="/images/loginpage/carousel_2.png" alt="" />
              <div className={style.context}>
                <img src="/images/loginpage/yours_logo.svg" alt="" />
                <div className={style.para}>
                  Resolve all the complaints and requests in one place.
                </div>
              </div>
            </div>
            <div className={style.carousel_context}>
              <img src="/images/loginpage/carousel_3.png" alt="" />
              <div className={style.context}>
                <img src="/images/loginpage/yours_logo.svg" alt="" />
                <div className={style.para}>
                  Manage the properties, logs and all the visits
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      {showGetHelpModal && <GetHelpModal onClose={setshowGetHelpModal} />}
    </>
  );
};

export default LoginComponent;
