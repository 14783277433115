/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import ComplainCard from "./card";
import CirclularMenuDropDown from "../DropDown/RadioDropdown";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";
import { DatePicker, DatePickerProps } from "antd";
import OwnerListDD from "../Home/ownerListDD";
import PropertyListDD from "../Home/propertyListDD";
import { NoDataComponent } from "../NoDataComponent";
import ApplyFilter from "../DropDown/ApplyFilter";
import moment from "moment";

const ComplaintComponent: FC = () => {
  const statusArray = ["All", "Pending", "Resolved"];
  const statusArrayNew = ["New to old", "A to Z"];
  const [titleId, setTitleId] = useState(0);
  const [propertyId, setPropertyId] = useState(0);
  let defaultParam = { limit: 500, owners: [] };
  // const [params, setParams] = useState<any>({
  //   limit: 500,
  //   owners: [],
  // });
  const [params, setParams] = useState<any>(defaultParam);
  const [applyFilter, setApplyFilter] = useState<any>({
    name: [],
  });
  const [title, setTitle] = useState<any>(null);
  const [titleNew, setTitleNew] = useState<any>(null);
  const [owners, setOwners] = useState([]);
  const [propertyList, setPropertyList] = useState<any>([]);
  const [data, setData] = useState<Array<any>>([]);
  const [ownerClear, setOwnerClear] = useState(false);
  const [propertyClear, setPropertyClear] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [clearAll, setClearAll] = useState(false);

  useEffect(() => {
    fetchOwners();
    fetchProperties();
    getData(params);
  }, []);

  useEffect(() => {
    getData(params);
  }, [propertyId]);

  const clearFilter = () => {
    setParams(defaultParam);
    getData(defaultParam);
    setTitle(null);
    setTitleNew(null);
    setOwnerClear(true);
    setPropertyClear(true);
    setSelectedDate(null);
  };

  const fetchOwners = async () => {
    const res: any = await API.get(APIS.PROPERTY.GET_ALL_OWNERS);
    setOwners(res.data.data);
  };

  const fetchProperties = async () => {
    const res: any = await API.post(APIS.HOME.PROPERTY_NAME_LIST, {
      role: "MANAGER",
    });
    setPropertyList(res.data.data);
  };

  const getData = async (filter: any) => {
    const res = await API.post(APIS.PROPERTY.COMPLAINT + propertyId, filter);
    setData(res.data.data.rows);
  };

  const onDateChange: DatePickerProps["onChange"] = (date: any, dateString: any) => {
    const p = { ...params };
    // eslint-disable-next-line no-useless-concat
    p["month"] = dateString + "-" + "01";
    setParams(p);
    getData(p);
    setSelectedDate(dateString);
  };

  const removeFilterData = (index: any) => {
    let filterData = { ...applyFilter };
    let paramData = { ...params };
    if (index == -2) {
      setOwnerClear(true);
      setPropertyClear(true);
    } else {
      filterData.name.splice(index, 1);
      paramData.owners.splice(index, 1);
      paramData.propertiesList.splice(index, 1);
    }

    getData(paramData);
    setParams(paramData);
    setApplyFilter(filterData);
  };

  return (
    <div className={style.complaint_page}>
      <div className={style.header}>
        <div className={style.left}>Complaints &#40;{data.length}&#41;</div>
        <div className={style.right}>
          <DatePicker
            picker="month"
            value={selectedDate && selectedDate != "" ? moment(selectedDate) : null}
            onChange={onDateChange}
            format={"YYYY-MM"}
            className={style.property_detail_date_picker}
          />
          <PropertyListDD
            width={"210px"}
            data={propertyList}
            getData={(id: number) => {
              const p: any = { ...params };
              const a: any = { ...ApplyFilter };
              setPropertyId(id);
              setApplyFilter(a);
              getData(p);
              setParams(p);
            }}
            clearAll={propertyClear}
            setClearAll={setPropertyClear}
            showClear={true}
            customClass={style.property_detail_dd}
            multi={true}
          />
          <OwnerListDD
            title={" owner"}
            width={"195px"}
            customClass={style.property_detail_dd}
            data={owners}
            getData={(ids: any, option: string) => {
              const p: any = { ...params };
              const a: any = { ...ApplyFilter };
              p["owners"] = ids;
              a["name"] = option;
              setApplyFilter(a);
              getData(p);
              setParams(p);
            }}
            clearAll={ownerClear}
            setClearAll={setOwnerClear}
            showClear={true}
            multi={true}
          />
          {(params["month"] || params["owners"].length > 0 || propertyId != 0) && (
            <span className={style.clear_selected} onClick={clearFilter}>
              Clear filters
            </span>
          )}
        </div>
      </div>
      {applyFilter.name && (
        <>
          {" "}
          {applyFilter.name.length > 0 && (
            <div className={style.selectedOwner}>
              <ApplyFilter filterData={applyFilter.name} removeFilterData={removeFilterData} />
            </div>
          )}
        </>
      )}

      <div className={style.sorting_filters}>
        <CirclularMenuDropDown
          title={title}
          width={"10rem"}
          data={statusArray}
          getData={(index: number) => {
            setTitleId(index);
            setTitle(statusArray[index]);
            const p: any = { ...params };
            if (index == 0) {
              delete p.status;
            } else if (index == 1) {
              p.status = 0;
            } else if (index == 2) {
              p.status = 1;
            } else {
              p.status = 2;
            }
            getData(p);
            setParams(p);
          }}
          customClass={style.property_detail_dd}
        />
        <CirclularMenuDropDown
          title={titleNew}
          width={"10rem"}
          data={statusArrayNew}
          getData={(index: any) => {
            setTitleNew(statusArrayNew[index]);
            const p: any = { ...params };
            if (index == 0) {
              p.sort = [["id", "DESC"]];
            } else if (index == 1) {
              p.sort = [["notes", "ASC"]];
            }
            getData(p);
            setParams(p);
          }}
          customClass={style.property_sorting_dd}
        />
      </div>
      {data.length > 0 ? (
        <div className={style.complaint_card_continer}>
          {data.map((val: any, index: any) => {
            return (
              <ComplainCard
                key={index}
                data={val}
                customClass={"complaint_card_main"}
                property_id={0}
                onUpdate={(resData: any) => {
                  const d = [...data];
                  d[index] = resData;
                  setData(d);
                }}
              />
            );
          })}
        </div>
      ) : (
        <NoDataComponent complaint="complaint" title={statusArray[titleId]} />
      )}
    </div>
  );
};

export default ComplaintComponent;
