import React, { FC, ReactNode } from "react";
import Header from "../component/Header";
import { Row } from "antd";
import Sidebar from "../component/Sidebar";
import "./default.scss";
import { useAuth } from "../store/context/login";
import { Navigate } from "react-router-dom";
import { routeMap } from "../utils/constants";

export type LayoutProps = {
  children: ReactNode;
  propertyList?: any;
};

const Layout: FC<LayoutProps> = ({ children, propertyList }: LayoutProps) => {
  const { access } = useAuth();
  if (!access || access === null) {
    return <Navigate to={routeMap.login} />;
  }

  return (
    <div>
      <Header companyName="Company Name" />
      <Row>
        <div className="sidebarWrapper">
          <Sidebar />
        </div>
        <div className="pageviewWrapper">{children}</div>
      </Row>
    </div>
  );
};

export default Layout;
