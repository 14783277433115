import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "antd/dist/antd.less";
import "./style/main.scss";
import "./style/icons/css/fontello.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./routes";

ReactDOM.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.ENV !== "production") {
  reportWebVitals(console.log);
}
