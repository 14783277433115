import React, { FC, useEffect, useState } from "react";
import { APIS } from "../../../../constants/apis";
import API from "../../../../services/api.service";
import style from "./style.module.scss";
export interface propertyrating {
  data?: any;
  setData: Function;
  status?: any;
  setStatus: Function;
}
const PropertyRating: FC<propertyrating> = ({
  data,
  setData,
  setStatus,
  status,
}) => {
  const [validationStatus, setValidationStatus] = useState(status);
  const [propertyList, setPropertyList] = useState([]);
  const [propertyIssue, setPropertyIssue] = useState<any>([]);
  const star_patern = [
    { name: "Very bad", img: "/images/property/star_icon.svg", id: 1 },
    { name: "Bad", img: "/images/property/star_icon.svg", id: 2 },
    { name: "Average", img: "/images/property/star_icon.svg", id: 3 },
    { name: "Good", img: "/images/property/star_icon.svg", id: 4 },
    { name: "Very good", img: "/images/property/star_icon.svg", id: 5 },
  ];

  // const [starProperty, setStarProperty] = useState(-1);
  // const [starOverall, setStarOverall] = useState(-1);

  const handleStarColorproperty = (index: any) => {
    let valid = { ...validationStatus };
    let newData = { ...data };
    valid.propertyrating = true;

    if (index >= 3) {
      newData.property_issue = [];
      valid.propertylist = true;
    } else {
      if (propertyIssue.length !== 0) {
        valid.propertylist = true;
      }
      newData.property_issue = propertyIssue;
    }
    newData.property_rating = index + 1;

    setData(newData);
    setStatus(valid);
    setValidationStatus(valid);
  };
  // const handleStarColorOverAll = (index: number) => {
  //   setStarOverall(index);
  // };

  const getConfig = async () => {
    const res = await API.get(APIS.USER_MANAGEMENT.CONFIG);
    setPropertyList(res.data.data.PROPERTY);
  };

  const addIssue = (value: any) => {
    let issue: any = [...propertyIssue];
    if (issue.includes(value)) {
      const index = issue.indexOf(value);
      if (index > -1) {
        issue.splice(index, 1);
      }
    } else {
      issue.push(value);
    }
    setData({ ...data, property_issue: issue });
    if (issue.length !== 0) {
      setStatus({ ...status, propertylist: true });
    }
  };

  useEffect(() => {
    setPropertyIssue(data.property_issue);
  }, [data]);
  useEffect(() => {
    getConfig();
  }, []);
  return (
    <>
      <div className={style.property}>
        <img src="/images/property/property_icon.svg" alt="user_logo" />
        How was the overall property?
      </div>
      {!status.propertyrating && (
        <div className={style.error}>Please rate property</div>
      )}

      <div className={style.pattern}>
        {star_patern.map((val: any, index: number) => {
          return (
            <div
              className={style.star_pattern}
              key={index}
              onClick={() => handleStarColorproperty(index)}
            >
              <span
                className={` ${style.star_icon} ${
                  index <= data.property_rating - 1 ? style.on : style.off
                }`}
              >
                <img
                  src="/images/star.svg"
                  className={style.start_inactive}
                  alt=""
                />
                <img
                  src="/images/starActive.svg"
                  className={style.start_active}
                  alt=""
                />
              </span>
              <div className={style.star_criteria}>{val.name}</div>
            </div>
          );
        })}
      </div>

      {data.property_rating - 1 > -1 && data.property_rating - 1 < 3 && (
        <div className={style.why_avg_rating_container}>
          <div className={style.why_avg_rating}>
            Why are you rating {star_patern[data.property_rating - 1].name}?{" "}
          </div>
          {!status.propertylist && (
            <div className={style.error}>Please select issue</div>
          )}
          <div className={style.body}>
            {propertyList.map((value: any, ind: any) => {
              return (
                <div
                  key={ind}
                  className={`${style.card_container} ${
                    propertyIssue.includes(value) ? style.active : ""
                  }`}
                  onClick={() => addIssue(value)}
                >
                  <div className={style.card}>{value}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default PropertyRating;
