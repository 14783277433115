/* eslint-disable eqeqeq */
import React, { FC, useEffect, useState } from "react";
import { Drawer, Modal } from "antd";
import style from "./style.module.scss";
import { DrawerType } from "../type";
import { formatAMPM, formatDotDate } from "../../../utils/helper";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
const DrawerComplaint: FC<DrawerType> = ({ showDrawer, setShowDrawer, data, onUpdate, property_id }) => {
  const dateNow = new Date(data.complaint_date);
  const [visible, setVisible] = useState(showDrawer);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [edit, setEdit] = useState(data.status === 0 ? true : false);
  const onClose = () => {
    setShowDrawer(false);
    setVisible(false);
  };
  useEffect(() => {
    setVisible(showDrawer);
  }, [showDrawer]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [response, setResponse] = useState(data.response);
  const resolveComplaint = async () => {
    const res = await API.post(APIS.PROPERTY.UPDATE_COMPLAINT + data.property_id, {
      id: data.id,
      response: response,
      status: 1,
    });

    if (res.data.status === 1) {
      onUpdate(res.data.data);
    }
    // onUpdate(res.data.data);
    setEdit(false);
    setIsModalVisible(true);
  };

  let icon = "/images/Property/pending_icon.svg";
  let status = "Pending";

  if (data.status == 1) {
    icon = "/images/Property/accepted_icon.svg";
    status = "Resolved";
  }

  if (data.status == 2) {
    icon = "/images/Property/denied_icon.png";
    status = "Denied";
  }

  function getOrdinalNum(n: any) {
    return n + (n > 0 ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : "");
  }
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const handleResponseChange = (data: any) => {
    setResponse(data.target.value);
  };

  return (
    <>
      <div className="drawer_component_property">
        <Drawer
          placement="right"
          width={461}
          onClose={onClose}
          visible={visible}
          closable={false}
          bodyStyle={{
            padding: 0,
            position: "relative",
            overflow: "scroll",
            height: "100%",
          }}
        >
          <div className={style.drawer_body}>
            <div className={style.drawer_header}>
              <div className={style.title}>
                <img src={icon} alt="" />
                {"Complaint " + status}
              </div>
              <div className={style.close_btn} onClick={onClose}>
                <img src="/images/cross.svg" alt="" />
              </div>
            </div>
            <div className={style.body}>
              <div className={style.ticket_details}>
                <div className={style.ticket_box}>
                  <div className={style.ticket}>Ticket #{data.id}</div>
                  <div className={style.date}>{formatDotDate(dateNow)}</div>
                </div>
                <div className={style.place_name}>{data.property.title}</div>
                <div className={style.location}>
                  {data.property.location}, {data.property.city}
                </div>
                <div className={style.person_name}>
                  {data.booking.user_details.first_name} {data.booking.user_details.last_name}
                </div>
                <div className={style.contact}>
                  <img src="/images/phone.svg" alt="" />
                  {data.booking.user_details.isd} {data.booking.user_details.mobile}
                </div>
              </div>
              <div className={style.complaint_details}>
                <div className={style.type}>
                  <div className={style.type_name}> {data.type}</div>

                  {data.status === 1 && (
                    <div className={style.status}>
                      <img src="/images/correct.svg" alt="" />
                      Resolved
                    </div>
                  )}
                  {data.status === 2 && (
                    <div className={style.status}>
                      <img src="/images/denied.svg" alt="" />
                      Denied
                    </div>
                  )}
                </div>

                <div className={style.name}>{data.notes}</div>
                <div className={style.time}>
                  {getOrdinalNum(dateNow.getDate())} {months[dateNow.getMonth()]} {dateNow.getFullYear()} |{" "}
                  {formatAMPM(dateNow)}
                </div>

                {edit && (
                  <>
                    <div className={style.response_box}>
                      <div className={style.title}>Add Response</div>
                      <textarea
                        value={response || data.response}
                        placeholder="Add a comment to let the the property owner know more regarding the complaint "
                        onChange={(e) => handleResponseChange(e)}
                        className={style.textfield}
                      ></textarea>
                    </div>
                    {edit && (
                      <div className={style.buttongroup}>
                        <button className={style.requestbuttons} style={{ marginRight: "25px" }} onClick={onClose}>
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            resolveComplaint();
                            onClose();
                          }}
                          className={style.requestbuttons}
                          style={{ background: "#082017", color: "white" }}
                        >
                          {data.status === 0 ? "Resolve" : "Update"}
                        </button>
                      </div>
                    )}
                  </>
                )}
                {data.status !== 0 && !edit && (
                  <div className={style.comment_box}>
                    <div className={style.title}>Comments</div>
                    <div className={style.cmt_box_body}>
                      <div className={style.comment}>{data.response}</div>
                      <div className={style.edit_btn} onClick={() => setEdit(true)}>
                        Edit
                      </div>
                    </div>
                    <div className={style.time}>
                      {getOrdinalNum(dateNow.getDate())} {months[dateNow.getMonth()]} {dateNow.getFullYear()} |{" "}
                      {formatAMPM(dateNow)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
        <Modal visible={isModalVisible} footer={null} onCancel={handleCancel} keyboard={true}>
          <div
            style={{
              height: "377px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src="/images/Property/modal_success_icon.png" alt="" />
            <div className={style.modal_content} style={{}}>
              Your response has been sent successfully!
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DrawerComplaint;
