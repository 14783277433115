import { Modal, notification } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIS } from "../../constants/apis";
import API from "../../services/api.service";
import AddPropertyComponent from "../AddProperty";
import "./style.scss";
import style from "./style.module.scss";
export interface PropertyCardType {
  customClass?: any;
  propertyData?: any;
  setPropertyData?: Function;
  counter?: any;
  getCounter?: Function;
  data?: any;
  config?: any;
  onupdate?: any;
}

const PropertyCard: FC<PropertyCardType> = ({ customClass, data, config, onupdate }) => {
  let navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [propertyData, setPropertyData] = useState<any>({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [con, setCon] = useState({});
  const handleMenu = (e: any) => {
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();
    setShowMenu(!showMenu);
  };
  const [showDrawer, setShowDrawer] = useState(false);
  const fetchPropertyData = async () => {
    const res = await API.get(APIS.PROPERTY.GET_PROPERTY + data.id);
    setPropertyData(res.data.data);
    setShowMenu(false);
    setShowDrawer(true);
  };
  const fetchConfigData = async () => {
    const res: any = await API.get(APIS.PROPERTY_PARAMS.CONFIG);
    setCon(res.data.data);
  };
  const handleEdit = (e: any) => {
    e.stopPropagation();
    fetchConfigData();
    fetchPropertyData();
  };
  const deleteProperty = async () => {
    const res = await API.delete(APIS.PROPERTY.DELETE_PROPERTY + data.id);
    if (res.data.status !== 1) {
      notification.info({
        message: `Problem in Deleting Property`,

        placement: "top",
      });
    } else {
      if (onupdate) onupdate();
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <div
        className={` ${customClass} property-card-main`}
        onClick={(e) => {
          e.stopPropagation();
          navigate("/property/" + data.id, { replace: false });
        }}
      >
        {data.details.files.length > 0 && (
          <img className="banner_img_property" src={data.details.files[0].file.urls.bucket.large} alt="" />
        )}
        <div className="menu_container_property">
          <img src="/images/vertical_menu_two.svg" alt="" className="vertical_menu_property" onClick={handleMenu} />
          {showMenu && (
            <div className="menu_container">
              <div className="menu" onClick={handleEdit}>
                Edit
              </div>
              <div className="divider"></div>
              <div
                className="menu"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalVisible(true);
                }}
              >
                Remove Property
              </div>
            </div>
          )}
        </div>

        <div className="slider_bookings">
          <div className="slider_data">
            <div className="slider_booking_name">
              <img src="/images/Home/calendar.svg" alt="calenderLogo" className="booking_img" />
              Bookings
            </div>
            <div className="slider_number slider_font_weight">{data.details.booking_request}</div>
          </div>

          <div className="slider_data_request">
            <div className="slider_booking_name">
              <img src="/images/Home/book-open.svg" alt="calenderLogo" className="booking_img" />
              Requests
            </div>
            <div className="slider_number slider_font_weight">{data.details.special_request}</div>
          </div>
          <div className="slider_data">
            <div className="slider_booking_name">
              <img src="/images/Home/clipboard.svg" alt="calenderLogo" className="booking_img" />
              Complaints
            </div>
            <div className="slider_number slider_font_weight">{data.details.complaint_request}</div>
          </div>
        </div>
        <div className="slider_heading">
          <div className="slider_head">{data.title}</div>
          <div className="slider_sub_head">
            <i className="icon-geo-location"></i> {data.location}, {data.state}
          </div>
        </div>
        <div className="slider_footer">
          <div className="footer_care_taker">Care taker</div>
          <div className="footer_detail">
            <div className="footer_care_taker_name">
              {" "}
              {data.caretaker_name ? data.caretaker_name : data.details.manager_details.first_name}{" "}
            </div>
            <div className="footer_care_taker_num">
              <img className="footer_img" src="/images/Home/telephone.svg" alt="telphone" />
              +91&nbsp;&nbsp;
              {data.caretaker_mobile ? data.caretaker_mobile : data.details.manager_details.mobile}
            </div>
          </div>
        </div>
      </div>
      {showDrawer && (
        <AddPropertyComponent
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          config={con}
          data={propertyData}
          onUpdate={onupdate}
        />
      )}
      <Modal visible={isModalVisible} footer={null} onCancel={() => setIsModalVisible(false)} keyboard={true}>
        <div className={style.modal_delete}>
          <div className={style.modal_header}>Delete Property</div>
          <div className={style.modal_content} style={{}}>
            On clicking delete, You'll be deleting a Property. Are you sure you want to continue?
          </div>
          <div className={style.modal_btns}>
            <div className={style.cancel} onClick={() => setIsModalVisible(false)}>
              Cancel
            </div>
            <div className={style.delete} onClick={deleteProperty}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PropertyCard;
