import React, { FC, useState } from "react";
import style from "./style.module.scss";
import { colorDD } from "../type";
const ColorDropDown: FC<colorDD> = ({
  selectedColor,
  width,
  onColorChange,
  title,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [option, setOption] = useState(
    title && title !== "" ? title : "Select Color"
  );
  const [color, setColor] = useState({
    border: "#082017",
    background: selectedColor ? selectedColor : "#ffff",
  });
  const colorList = ["#F6F3EF", "#EDF2EC", "#FFF7EF", "#FDFAEC", "#EFF5FF"];
  const menuToggle = (id: any, value: any) => {
    const allMenu = document.querySelectorAll(".menu_active");
    for (let index = 0; index < allMenu.length; index++) {
      allMenu[index].classList.remove(style.active);
    }
    setColor({ ...color, background: value });
    const ele = document.getElementById(id);
    ele?.classList.add(style.active);
    setOption(value);
    onColorChange(value);
    setDropDownActive(false);
  };
  
  return (
    <div className={style.drop_down_container} style={{ width: `${width}` }}>
      <div
        className={style.drop_down_selected}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        <div className={style.option_part}>
          <div
            className={style.circle_color}
            style={{
              borderColor: color.border,
              backgroundColor: color.background,
            }}
          ></div>
          {option}
        </div>

        <img
          src="/images/drop_down_icon.svg"
          className={dropDownActive ? "" : style.rotate_icon}
          alt=""
        />
      </div>

      {dropDownActive && (
        <div className={style.drop_down_box}>
          {colorList.map((val: any, ind: any) => {
            return (
              <div
                key={ind}
                id={`${ind}`}
                className={`${style.menu} menu_active ${
                  ind === 0 ? style.active : ""
                }`}
                style={{ backgroundColor: `${val}` }}
                onClick={(e) => menuToggle(`${ind}`, val)}
              ></div>
            );
          })}
        </div>
      )}
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(!dropDownActive)}
        ></div>
      )}
    </div>
  );
};

export default ColorDropDown;
