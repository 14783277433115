import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import BookingDaysCard from "../../bookingDaysCard";
import { AllBooking } from "../../type";

const AllBookingModal: FC<AllBooking> = ({ showModal, setShowModal, data }) => {
  const [show, setShow] = useState(showModal);
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const closeModal = () => {
    setShow(false);
    setShowModal(false);
  };

  return (
    <div className={style.modal_container}>
      <div className={style.overlay} onClick={() => closeModal()}></div>
      <div className={style.modal_add_all_booking}>
        <div className={style.header}>
          {data.length > 0 && (
            <div className={style.title}>
              All Bookings &#40;{data.length}&#41;
            </div>
          )}
          {data.length <= 0 && (
            <div className={style.title}>NO UPCOMING BOOKING</div>
          )}
          <div className={style.close_btn}>
            <img
              src="/images/close_icon.svg"
              alt=""
              onClick={() => closeModal()}
            />
          </div>
        </div>
        <div className={style.body}>
          {data &&
            data.length > 0 &&
            data.map((val: any, ind: any) => {
              return <BookingDaysCard key={ind} data={val} />;
            })}
        </div>
      </div>
    </div>
  );
};
export default AllBookingModal;
