/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FC } from "react";
import style from "./style.module.scss";
import { DireactionType } from "../type";
import MapComponent from "../../Map";
const DirectionComponent: FC<DireactionType> = ({
  config,
  propertyData,
  setPropertyData,
  setValidation
}) => {
  const [optionData, setOptionData] = useState("Add Nearby");
  const loc = [];

  if (propertyData.location) {
    loc.push(propertyData.location);
  }
  if (propertyData.city) {
    loc.push(propertyData.city);
  }
  if (propertyData.district) {
    loc.push(propertyData.district);
  }
  if (propertyData.state) {
    loc.push(propertyData.state);
  }
  const [chooseFromMap, setChooseFromMap] = useState(
    loc.join(", ") !== "" ? false : true,
  );
  const [address, setAddress] = useState(loc.join(","));
  const [dropDownActive, setDropDownActive] = useState(false);

  const nearby: any = {
    AIRPORT: "/images/nearby/Airport.svg",
    HOSPITAL_PHARMACY: "/images/nearby/Hospital.svg",
    RAILWAY_STATION: "/images/nearby/Railway_station.svg",
    SUPERMARKET: "/images/nearby/Nearest Supermarket.svg ",
  };

  const addNearby = (value: any) => {
    let newData: any = propertyData.nearby;
    if (newData[value]) {
      delete newData[value];
    } else {
      newData = {
        ...newData,
        [value]: {
          title: config.nearby[value],
          distance: "",
        },
      };
    }

    if (Object.entries(newData).length > 0) {
      setOptionData(Object.entries(newData).length + " nearby location added ");
    } else {
      setOptionData("Add nearby");
    }
    setPropertyData({ ...propertyData, nearby: newData });
  };

  const removeActive = (value: any) => {
    let newData = propertyData.nearby;
    if (newData[value]) {
      delete newData[value];
    }
    if (Object.entries(newData).length > 0) {
      setOptionData(Object.entries(newData).length + " nearby location added ");
    } else {
      setOptionData("Add nearby");
    }
    setPropertyData({ ...propertyData, nearby: newData });
  };

  const handleDistance = (value: any, key: any) => {
    let newData = propertyData.nearby;
    newData[key].distance = value;
    setPropertyData({ ...propertyData, nearby: newData });
  };

  useEffect(() => {
    if (Object.entries(propertyData.nearby).length > 0) {
      setOptionData(
        Object.entries(propertyData.nearby).length + " nearby location added ",
      );
    } else {
      setOptionData("Add nearby");
    }
    if ((!propertyData.lat || !propertyData.long) && address.length > 6) {
      searchOnMap();
    }
  }, []);

  const searchOnMap = () => {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, function (results: any, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        setPropertyData({
          ...propertyData,
          lat: results[0].geometry.location.lat(),
          long: results[0].geometry.location.lng(),
        });
      }
    });
  };

  return (
    <div className={style.property_direction_container}>
      <div className={style.input_group}>
        <div className={style.title}>Location</div>
        <div className={`${style.input_field} ${style.flex_box}`}>
          {chooseFromMap && (
            <input
              type="text"
              placeholder="Add Location"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          )}
          {!chooseFromMap && address !== "" && (
            <div className={style.address_field}>{address}</div>
          )}
          {!chooseFromMap && (
            <button onClick={() => setChooseFromMap(true)}>Change</button>
          )}
          {chooseFromMap && (
            <button onClick={searchOnMap}>Choose From Map</button>
          )}
        </div>
      </div>

      {chooseFromMap && (
        <MapComponent
          lat={propertyData.lat}
          long={propertyData.long}
          onChange={(lat: any, long: any) => {
            setPropertyData({ ...propertyData, lat, long });
          }}
        />
      )}

      <div className={style.input_group}>
        <div className={style.title}>About Location</div>
        <div className={style.input_field}>
          <textarea
            placeholder="Add some descriptions about the Property"
            value={propertyData.locality_details}
            onChange={(e) => {
              setPropertyData({
                ...propertyData,
                locality_details: e.target.value,
              });
            }}
          ></textarea>
        </div>
        {setValidation && propertyData.locality_details=== "" && (
                <div className={style.error}>Please add Location</div>
              )}
      </div>
      <div className={style.input_group}>
        <div className={style.title}>Nearby</div>
        <div className={style.input_field}>
          <div className={`${style.drop_down_container_direction}  `}>
            <div
              className={`${style.drop_down_selected} ${
                dropDownActive ? style.dd_active : ""
              }`}
              onClick={() => setDropDownActive(!dropDownActive)}
            >
              {optionData}
              <img
                src="/images/drop_down_icon.svg"
                className={dropDownActive ? "" : style.rotate_icon}
                alt=""
              />
            </div>

            {
              <div
                className={style.drop_down_box}
                style={{ display: `${dropDownActive ? "block" : "none"}` }}
              >
                {Object.keys(config.nearby).map((val: any, ind: any) => {
                  return (
                    <div key={ind} className={style.drop_down_items}>
                      <div
                        id={`${ind}dir`}
                        className={`${style.menu} menu_active ${
                          propertyData.nearby[val] ? style.active : ""
                        } `}
                        onClick={(e) => addNearby(val)}
                      >
                        {propertyData.nearby[val] && (
                          <img
                            src={"/images/DropDown/radio_active.svg"}
                            alt=""
                          />
                        )}
                        {!propertyData.nearby[val] && (
                          <img
                            src="/images/DropDown/radio_inactive.svg"
                            className={style.inactive_circle}
                            alt=""
                          />
                        )}
                        <img src={nearby[val]} alt="" />
                        {config.nearby[val]}
                      </div>
                      <div className={style.divider}></div>
                    </div>
                  );
                })}
                {/* {<div className={style.clear}>Add New Blank</div>} */}
              </div>
            }
            {dropDownActive && (
              <div
                className={style.overlay}
                onClick={() => setDropDownActive(!dropDownActive)}
              ></div>
            )}
          </div>
        </div>
        {Object.entries(propertyData.nearby).length > 0 &&
          Object.keys(propertyData.nearby).map((key: any, ind: any) => {
            return (
              <div key={ind} className={style.location_detail_container}>
                <div className={style.location_detail}>
                  <div className={style.img_box_uplaod}>
                    <img src={nearby[key]} alt="" />
                  </div>
                  <input
                    type="text"
                    className={style.detail_name}
                    value={propertyData.nearby[key].title}
                  />
                  <div
                    className={style.remove}
                    onClick={() => {
                      removeActive(key);
                    }}
                  >
                    Remove
                  </div>
                </div>
                <input
                  type="text"
                  className={`${style.distance} ${style.distance}`}
                  value={propertyData.nearby[key].distance}
                  placeholder={"Enter Distance"}
                  onChange={(e) => {
                    handleDistance(e.target.value, key);
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DirectionComponent;
