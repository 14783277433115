import React from "react";
import SettingFaq from "./faq";
import NotificationSettings from "./notification";
import SettingContact from "./contact";
import style from "./style.module.scss";
const Setting = () => {
  const handleTabActive = (e: any, id: any) => {
    const allMenu = document.querySelectorAll(".menuSetting");
    for (let index = 0; index < allMenu.length; index++) {
      allMenu[index].classList.remove(style.active);
    }

    const allTabPannel = document.querySelectorAll(".tabPannelSetting");
    for (let index = 0; index < allTabPannel.length; index++) {
      allTabPannel[index].classList.remove(style.active);
    }

    e.target.classList.add(style.active);
    document.getElementById(id)?.classList.add(style.active);
  };

  return (
    <div className={style.setting_page}>
      <div className={style.header}>Settings</div>
      <div className={style.tabs_container}>
        <div
          className={`${style.menu} menuSetting ${style.active}`}
          onClick={(e) => handleTabActive(e, "notification_stng")}
        >
          Notification Settings
        </div>
        <div
          className={`menuSetting ${style.menu}`}
          onClick={(e) => handleTabActive(e, "contact_stng")}
        >
          Contact us
        </div>
        <div
          className={`menuSetting ${style.menu}`}
          onClick={(e) => handleTabActive(e, "faq_stng")}
        >
          FAQ
        </div>
      </div>
      <div className={style.tab_pannel_container}>
        <div
          id="notification_stng"
          className={`${style.tab_pannel} tabPannelSetting ${style.active}`}
        >
          <NotificationSettings />
        </div>
        <div id="faq_stng" className={` tabPannelSetting ${style.tab_pannel}`}>
          <SettingFaq />
        </div>
        <div
          id="contact_stng"
          className={` tabPannelSetting ${style.tab_pannel}`}
        >
          <SettingContact />
        </div>
      </div>
    </div>
  );
};

export default Setting;
