import React from "react";
import style from "./style.module.scss";
import "./style.scss";
import { Switch } from "antd";
const NotificationSettings = () => {
  const onChange = (checked: boolean) => {};
  return (
    <div className={style.notification_section}>
      <div className={style.setting_option}>
        <div className={style.left}>
          <div className={style.title}>Email notifications</div>
          <div className={style.para}>
            Receive push emails on property, stays, requests and complaint updates on email.
          </div>
        </div>
        <div className={style.right}>
          <Switch defaultChecked onChange={onChange} />
        </div>
      </div>
      <div className={style.setting_option}>
        <div className={style.left}>
          <div className={style.title}>Text Messages</div>
          <div className={style.para}>
            Receive push notifications on property, stays, requests and complaint updates on phone.
          </div>
        </div>
        <div className={style.right}>
          <Switch defaultChecked onChange={onChange} className={"custom_switch"} />
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;
