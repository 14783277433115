import { FC, useState } from "react";
import { AmenitiesType } from "../type";
import style from "./style.module.scss";
const AmenitiesComponent: FC<AmenitiesType> = ({
  amentieslist,
  setData,
  data,
}) => {
  const amenities: any = {
    POOL: "/images/amenties/swimming_pool.svg",
    LOUNGE: "/images/amenties/loungue.svg",
    WIFI: "/images/amenties/wifi.svg",
    GYM: "/images/amenties/gym.svg",
    TV_ROOM: "/images/amenties/television.svg",
    PARKING: "/images/amenties/parking.svg",
    PROFESSIONALLY_DECORATED: "/images/amenties/professionally_decorated.svg",
    WELL_EQUIPPED_KITCHEN: "/images/amenties/well_equipped_kitchen.svg",
    PRIVATE_SWIMMING_POOL: "/images/amenties/swimming_pool.svg",
    OWNERS_STORAGE_FACILITY: "/images/amenties/owner_storage_facility.svg",
    HOUSE_HELP_AND_SECURITY: "/images/amenties/house_help_and_security.svg",
    ITALIAN_COFFEE_MACHINE: "/images/amenties/coffe_mechine.svg",
    FULLY_FURNISHED: "/images/amenties/fully_furnished.svg",
    KID_FRIENDLY: "/images/amenties/kids freindly.svg",
    CHEF_ON_CALL: "/images/amenties/chef_on_call.svg",
    OPEN_DECK: "/images/amenties/open_deck.svg",
    BALCONY_WITH_BAR: "/images/amenties/balcony_with_bar.svg",
    LARGE_GARDEN: "/images/amenties/loungue.svg",
    MOUNTAIN_VIEWS: "/images/amenties/mountain_view.svg",
    MOUNTAIN_VIEW: "/images/amenties/mountain_view.svg",
    WELL_EQUIPPED_WET_AND_DRY_KITCHEN:
      "/images/amenties/well_equipped_kitchen.svg",
    HOUSE_HELP: "/images/amenties/house_help_and_security.svg",
    OUTDOOR_HOT_TUB: "/images/amenties/hot_bathtub.svg",
    HIKING_TRAIL_ACCESS: "/images/amenties/hiking_trail_access.svg",
    EXTRA: "/images/amenties/Default_picture.svg",
  };
  const [extraAmentitiesCount, setExtraAmentitiesCount] = useState(0);
  const [dropDownActive, setDropDownActive] = useState(false);
  const [addedAmenties, setAddedAmenties] = useState<any>(data);

  const addAmenities = (id: any, value: any) => {
    let newData = { ...addedAmenties };
    let amenitiesObj = {
      [value]: amentieslist[value],
    };
    const found = newData.hasOwnProperty(value);

    if (found) {
      delete newData[value];
    } else {
      Object.assign(newData, amenitiesObj);
    }

    setAddedAmenties(newData);

    setData(newData);
  };
  const removeAmenities = (value: any) => {
    let newData = { ...addedAmenties };
    const found = addedAmenties.hasOwnProperty(value);
    if (found) {
      delete newData[value];
    }
    setAddedAmenties(newData);
    setData(newData);
  };

  const addNewBlank = () => {
    let newData = { ...addedAmenties };
    let amenitiesObj = {
      ["EXTRA" + extraAmentitiesCount]: "",
    };
    Object.assign(newData, amenitiesObj);
    setAddedAmenties(newData);
    setData(newData);
    setExtraAmentitiesCount(extraAmentitiesCount + 1);
  };

  const handleInputChange = (field: any, value: any) => {
    let newData = { ...addedAmenties };
    newData[field] = value;
    setData(newData)
    setAddedAmenties(newData);
  };

  return (
    <div className={style.amenities_container}>
      <div className={style.input_group}>
        <div className={style.title}>Add Amenities</div>
        <div className={style.input_field}>
          <div className={`${style.drop_down_container}  `}>
            <div
              className={`${style.drop_down_selected} ${
                dropDownActive ? style.dd_active : ""
              }`}
              onClick={() => setDropDownActive(!dropDownActive)}
            >
              {Object.entries(addedAmenties).length > 0
                ? Object.entries(addedAmenties).length + " amenities added"
                : "Select Amenities"}
              <img
                src="/images/drop_down_icon.svg"
                className={dropDownActive ? "" : style.rotate_icon}
                alt=""
              />
            </div>
            {
              <div
                className={style.drop_down_box}
                style={{ display: `${dropDownActive ? "block" : "none"}` }}
              >
                {Object.keys(amentieslist).map((val: any, ind: any) => {
                  return (
                    <div key={ind}>
                      <div
                        id={`${ind}_amenities`}
                        className={`${style.menu} menu_active 
                    
                        `}
                        onClick={(e) => addAmenities(`${ind}`, val)}
                      >
                        <img
                          src={`/images/DropDown/${
                            addedAmenties.hasOwnProperty(val)
                              ? "radio_active.svg"
                              : "radio_inactive.svg"
                          }`}
                          className={style.active_circle}
                          alt=""
                        />
                        {amentieslist[val]}
                      </div>
                      {ind !== data.length - 1 && (
                        <div className={style.divider}></div>
                      )}
                    </div>
                  );
                })}
                {
                  <div className={style.clear} onClick={() => addNewBlank()}>
                    Add New Blank
                  </div>
                }
              </div>
            }
            {dropDownActive && (
              <div
                className={style.overlay}
                onClick={() => setDropDownActive(!dropDownActive)}
              ></div>
            )}
          </div>
          {Object.entries(addedAmenties).length > 0 &&
            Object.entries(addedAmenties).map((val: any, ind: any) => {
              const [field, value] = val;
              return (
                <div className={style.property_detail} key={ind}>
                  <div className={style.img_box_uplaod}>
                    <img
                      src={
                        val[0].includes("EXTRA")
                          ? amenities["EXTRA"]
                          : amenities[field]
                      }
                      alt=""
                    />
                  </div>
                  <input
                    className={style.detail_name}
                    value={value}
                    placeholder="Add new amenities"
                    onChange={(e) => handleInputChange(field, e.target.value)}
                  />

                  <div
                    className={style.remove}
                    onClick={() => {
                      removeAmenities(field);
                    }}
                  >
                    Remove
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AmenitiesComponent;
