import React from "react";
import style from "./style.module.scss";
import ProfileComponent from "./profile";
const SettingProfile = () => {
  return (
    <div className={style.setting_profile_page}>
      <ProfileComponent />
    </div>
  );
};

export default SettingProfile;
