import React, { FC, useState, useEffect } from "react";
import style from "./style.module.scss";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import CheckBoxDD from "../../DropDown/checkBoxDD";
import AddServiceComponent from "../serviceDrawer";
import UpcomingMaintanenceCard from "./card";
import ServiceDropDown from "../../DropDown/serviceDD";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
export interface maintenancecard {
  onupdate: Function;
  maintainencedata: any;
  selectDate: Function;
}
const MainteneceHistory: FC<maintenancecard> = ({ onupdate, maintainencedata, selectDate }) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const defaultState = {
    service_id: null,
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    selectDate(dateString);
  };
  // const data = ["All", "Data", "Is", "Temp"];
  const [data, setData] = useState({ ...defaultState });
  const [serviceList, setServiceList] = useState({});
  const [maintainenceCardData, setMaintainenceCardData] = useState(maintainencedata);
  // const [option, setOption] = useState("");

  // const getUpcomingMaintanence = async () => {
  //   const res = await API.get(APIS.USER_MANAGEMENT.UPCOMING_MAINTANENCE)
  // }
  const getConfig = async () => {
    const serRes = await API.get(APIS.USER_MANAGEMENT.LIST_SERVICE);
    let list = {};
    if (serRes.data.status == 1) {
      serRes.data.data.map((val: any) => {
        return Object.assign(list, { [val.title]: val.id });
      });
    }
    setServiceList(list);
  };
  const filterCardData = (id: any) => {
    let mainteData = [...maintainencedata];
    mainteData = mainteData.filter((val: any) => {
      return val.service_id === id;
    });
    setMaintainenceCardData(mainteData);
  };
  useEffect(() => {
    getConfig();
  }, []);

  return (
    <div className={style.upcoming_mainte}>
      <div className={style.dropdown_groups}>
        <div className={style.left}>
          <DatePicker onChange={onChange} className={style.date_picker} />
          {/* <CheckBoxDD getData={() => {}} data={data} width={"211px"} customClass={style.check_box_dd} /> */}
          <ServiceDropDown
            title={data.service_id}
            data={Object.keys(serviceList)}
            getData={(res: any) => {
              let newData = { ...data };
              newData.service_id = res;
              filterCardData(res);
              setData(newData);
              // // setOption(Object.keys(serviceList)[res]);
            }}
            customClass={style.service_dd}
          />
        </div>
        <div className={style.right}>
          <div className={style.add_monthly_btn} onClick={() => setShowDrawer(true)}>
            <img src="/images/property/plus_icon.svg" alt="plus_icon" />
            Add new service{" "}
          </div>
        </div>
      </div>
      <div className={style.vist_card_group}>
        {maintainencedata.length > 0 &&
          maintainencedata.map((val: any, index: number) => {
            return <UpcomingMaintanenceCard data={val} onupdate={onupdate} />;
          })}
      </div>
      <AddServiceComponent showDrawer={showDrawer} setShowDrawer={setShowDrawer} onupdate={onupdate} />
    </div>
  );
};

export default MainteneceHistory;
