export const locationValidator = (data: any) => {
  if (
    data.state === "" ||
    data.city === "" ||
    data.location === "" ||
    data.zipcode === ""
  )
    return true;
  return false;
};
