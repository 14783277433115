import { APIS } from "./apis";
import API from "../services/api.service";

export const propertyList = async (tokens?: any) => {
  const res = await API.post(APIS.HOME.PROPERTY_LIST, { role: "MANAGER" });
  return res;
};
export const getOwnerList = async (tokens?: any) => {
  const res = await API.post(APIS.HOME.OWNER_LIST, { role: "MANAGER" });
  return res;
};
export const getPropertyNameList = async (tokens?: any) => {
  const res = await API.post(APIS.HOME.PROPERTY_NAME_LIST, { role: "MANAGER" });
  return res;
};
export const upcommingBookings = async (
  type: any,
  type_id: any,
  tokens?: any,
) => {
  const res = await API.post(
    APIS.PROPERTY.BOOKING_HISTORY +
      (type !== "owners" ? type_id.toString() : 0),
    {
      page: 1,
      limit: 500,
      owners:
        type === "owners" && type_id.toString().length > 0 ? type_id : [],
      upcoming: true,
      fields: [{ field: "status", values: { "1": "Confirmed" } }],
      sort: [["start_date", "ASC"]],
    },
  );
  return await res;
};

//
export const getProperty = async (tokens?: any, id?: any) => {
  const res = await API.get(APIS.PROPERTY.GET_PROPERTY + id);
  return res;
};
export const getAllRequest = async (tokens?: any, id?: any, filter?: any) => {
  var body;
  if (filter === 3) {
    body = {
      page: 1,
      limit: 50,
      owners: [],
      month: "2022-08-01",
    };
  } else {
    body = {
      page: 1,
      limit: 50,
      owners: [],
      month: "2022-08-01",
      status: filter,
    };
  }
  const res = await API.post(APIS.PROPERTY.REQUEST + id, body);
  return res;
};
export const getAllComplaint = async (tokens?: any, id?: any, filter?: any) => {
  const body = {
    page: 1,
    limit: 50,
    owners: [],
    month: "2022-08-01",
    status: filter,
  };
  if (filter === 3) {
    delete body.status;
  }

  const res = await API.post(APIS.PROPERTY.COMPLAINT + id, body);
  return res;
};

export const updateComplaint = async (
  tokens?: any,
  id?: any,
  id2?: any,
  resp?: any,
  stat?: any,
) => {
  const body = {
    id: id2,
    response: resp,
    status: stat,
  };

  const res = await API.post(APIS.PROPERTY.UPDATE_COMPLAINT, body);
  return res;
};
