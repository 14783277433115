/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from "react";
import { LocationDropdown } from "./type";
import style from "./style.module.scss";
import { propertyFieldValidator } from "../../AddProperty/helper";
const LocationDropdownComponent: FC<LocationDropdown> = ({
  title,
  data,
  width,
  valueKey,
  selected,
  onChange,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  useEffect(() => {
    const index = data.findIndex((state: any) => state.id === selected);
    if (index > -1) {
      setSelectedText(data[index][valueKey]);
    } else {
      setSelectedText("");
    }
  }, [selected, data]);

  return (
    <div
      className={`${style.drop_down_container}  `}
      style={{ width: `${width}` }}
    >
      <div
        className={`${style.drop_down_selected} ${
          dropDownActive ? style.dd_active : ""
        } ${selectedText !== "" ? style.value_present : ""}`}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {selectedText ? selectedText : "Select " + title}
        <img
          src="/images/drop_down_icon.svg"
          className={dropDownActive ? "" : style.rotate_icon}
          alt=""
        />
      </div>
      {
        <div
          className={`${style.drop_down_box}`}
          style={{ display: dropDownActive ? "block" : "none" }}
        >
          <div className={style.scroll}>
            {data.map((val: any, ind: any) => {
              return (
                <div key={ind}>
                  <div
                    className={`${style.menu} ${
                      val.id === selected ? style.active : ""
                    } `}
                    onClick={() => {
                      if (onChange) {
                        onChange(val);
                      }
                      setDropDownActive(false);
                    }}
                  >
                    {val[valueKey]}
                  </div>
                  {ind !== data.length - 1 && (
                    <div className={style.divider}></div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      }
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(!dropDownActive)}
        ></div>
      )}
    </div>
  );
};

export default LocationDropdownComponent;
