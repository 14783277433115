import { FC, useState } from "react";
import style from "./style.module.scss";
import { uploadFile } from "../../../utils/helper";
import { PropertyImageType } from "../type";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const PropertyImage: FC<PropertyImageType> = ({
  propertyData,
  setPropertyData,
}) => {
  const [mainData, setMainData] = useState<any>(
    propertyData.images.PROPERTY_BANNER,
  );
  const [uploadData, setUploadData] = useState<any>(propertyData.attachments);

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];
    file.isUploading = true;
    const dataObj = {
      img: "",
      file: file,
      name: file.name,
      id: "",
    };
    setUploadData([...uploadData, dataObj]);
    const res = await uploadFile(file);
    const data = await res.data.data;
    file.isUploading = false;
    if (res.status !== 201 || res.data.status !== 1) {
      setUploadData([...uploadData]);
    } else {
      dataObj.img = data.urls.bucket.small;
      dataObj.id = data.id;
      setUploadData([...uploadData, dataObj]);
    }
    let propertyImages = [
      ...propertyData.images.PROPERTY_BANNER,
      {
        title: file.name,
        id: data.id,
      },
    ];
    setMainData(propertyImages);
    setPropertyData({
      ...propertyData,
      images: { PROPERTY_BANNER: propertyImages },
      attachments: [
        ...propertyData.attachments,
        { img: data.urls.bucket.small, file: null, name: "", id: data.id },
      ],
    });
  };

  const handleInputChange = (value: any, index: any) => {
    let newData = [...uploadData];
    let newMainData = [...mainData];
    newData[index].name = value;

    newMainData[index].title = value;
    setMainData(newMainData);
    setUploadData(newData);
    if (setPropertyData)
      setPropertyData({
        ...propertyData,
        images: { ...propertyData.images, PROPERTY_BANNER: newMainData },
      });
  };

  const removeData = (index: any) => {
    let newData = [...uploadData];
    let newMainData = [...mainData];
    newData.splice(index, 1);
    newMainData.splice(index, 1);
    setMainData(newMainData);
    setUploadData(newData);
    if (setPropertyData)
      setPropertyData({
        ...propertyData,
        images: { ...propertyData.images, PROPERTY_BANNER: newMainData },
      });
  };

  return (
    <>
      {/* <div className={style.input_group} >
          <div className={`${style.input_field}`} style={{marginBottom:'32px'}}>
            <button style={{width: '413px',height: '40px', fontWeight:'600',cursor:'pointer',background:'none',padding:'0px'}}>
             + Add Category
            </button>
          </div>
      </div> */}

      <div className={style.property_image_container}>
        {uploadData &&
          uploadData.map((val: any, ind: any) => {
            return (
              <div key={ind}>
                {val.file && val.file.isUploading ? (
                  <>
                    <Spin indicator={antIcon} />
                    Uploading
                  </>
                ) : (
                  <div className={style.image_detail_container}>
                    <img src={`${val.img}`} alt="" />
                    <input
                      type="text"
                      value={val.name}
                      onChange={(e) => {
                        handleInputChange(e.target.value, ind);
                      }}
                    />
                    <div
                      className={style.remove}
                      onClick={() => {
                        removeData(ind);
                      }}
                    >
                      Remove
                    </div>
                  </div>
                )}
              </div>
            );
          })}

        <div className={style.input_group}>
          <div className={style.title}>Upload Property Image</div>
          <div className={style.input_field}>
            <input
              type="file"
              name="file"
              className={style.select_input}
              accept={"image/*"}
              onChange={(e: any) => handleUpload(e)}
            />
            <div className={style.select_box}>
              <div className={style.upper}>Drag And Drop image or Browse</div>
              <div className={style.upper}>Resolution &#40; 328*204 &#41;</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyImage;
