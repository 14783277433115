import React, { FC, useEffect, useState } from "react";
import { PlainDropD } from "../type";
import style from "./style.module.scss";
const PlainDDComponent: FC<PlainDropD> = ({
  reset,
  setReset,
  data,
  getData,
  width,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [option, setOption] = useState(data[0]);
  const menuToggle = (id: any, value: any) => {
    const allMenu = document.querySelectorAll(".menu_active");
    for (let index = 0; index < allMenu.length; index++) {
      allMenu[index].classList.remove(style.active);
    }
    const ele = document.getElementById(id);
    ele?.classList.add(style.active);
    setOption(value);
    getData(value);
    setDropDownActive(!dropDownActive);
  };
  useEffect(() => {
    setOption(data[0]);
  }, [data]);

  useEffect(() => {
    if (reset) {
      setOption(data[0]);
      if (setReset) setReset(false);
    }
  }, [reset]);

  return (
    <div className={style.drop_down_container} style={{ width: `${width}` }}>
      <div
        className={style.drop_down_selected}
        onClick={() => setDropDownActive(!dropDownActive)}
      >
        {option}
        <img
          src="/images/drop_down_icon.svg"
          className={dropDownActive ? "" : style.rotate_icon}
          alt=""
        />
      </div>

      {dropDownActive && (
        <div className={style.drop_down_box}>
          {data.map((val: any, ind: any) => {
            return (
              <>
                <div
                  id={`${ind}`}
                  className={`${style.menu} menu_active ${
                    ind === 0 ? style.active : ""
                  }`}
                  onClick={(e) => menuToggle(`${ind}`, val)}
                >
                  {val}
                </div>
                {ind !== data.length - 1 && (
                  <div className={style.divider}></div>
                )}
              </>
            );
          })}
        </div>
      )}
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(!dropDownActive)}
        ></div>
      )}
    </div>
  );
};

export default PlainDDComponent;
