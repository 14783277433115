import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../store/context/login";
import { OTPModule } from "../type";
import style from "./style.module.scss";
const OTPInput: FC<OTPModule> = ({ showEmail, userData }) => {
  const [time, settime] = useState(5);
  const [otpId, setOtpId] = useState(userData.id);
  const { login, sendOtpEmail } = useAuth();
  const [digit1, setDigit1] = useState<any>("");
  const [digit2, setDigit2] = useState<any>("");
  const [digit3, setDigit3] = useState<any>("");
  const [digit4, setDigit4] = useState<any>("");
  const [isValid, setIsValid] = useState(true);
  const navigate = useNavigate();

  const resendOtp = async () => {
    setDigit1('');
    setDigit2('');
    setDigit3('');
    setDigit4('');
    setIsValid(true);
    settime(30);
    const res = await sendOtpEmail(userData.info);
    setOtpId(res.data.data.id);
  };

  useEffect(() => {
    var otpLoader = setInterval(() => {
      settime(time - 1);
    }, 1000);
    return () => {
      clearInterval(otpLoader);
    };
  }, [time]);

  const vertifyOtp = async () => {
    let ansOtp = digit1 + digit2 + digit3 + digit4;
    try {
      const res = await login(otpId, Number(ansOtp));

      if (res.data.status === 1) {
        navigate("/");
      } else {
        setIsValid(false);
      }
    } catch (error) {}
  };

  function getCodeBoxElement(index: any) {
    return document.getElementById("optinput" + index) as HTMLInputElement;
  }

  const handleFocus = (event: any, ind: any) => {
    const eventCode = event.which || event.keyCode;
    if (eventCode === 9) return;
    if (getCodeBoxElement(ind)?.value.length === 1) {
      if (ind !== 4) {
        getCodeBoxElement(ind + 1)?.focus();
      } else {
        getCodeBoxElement(ind)?.blur();
      }
    }
    if (eventCode === 8 && ind !== 1) {
      getCodeBoxElement(ind - 1)?.focus();
    }
  };
  function onFocusEvent(index: any) {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement?.value) {
        currentElement?.focus();
        break;
      }
    }
  }

  window.addEventListener("keyup", (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      vertifyOtp();
    }
  });

  return (
    <>
      <div className={style.main_title}>Verify OTP </div>
      <div className={style.sub_title}>
        Enter the four digit OTP code sent to your mobile{" "}
      </div>
      <div className={style.edit_email}>
        {userData.info !== "" && !isNaN(userData.info) ? "+91" : ""} {""}{" "}
        {userData.info}{" "}
        <span className={style.highlight} onClick={(e) => showEmail(false)}>
          Edit
        </span>{" "}
      </div>
      <div className={style.input_container}>
        <div
          className={`${style.otp_input_box} ${isValid ? "" : style.error_otp}`}
        >
          <input
            type="text"
            id="optinput1"
            value={digit1}
            autoFocus
            maxLength={1}
            onFocus={() => onFocusEvent(1)}
            onKeyUp={(e: any) => handleFocus(e, 1)}
            onChange={(e: any) => {
              setDigit1(e.target.value);
            }}
            className={style.digit_box}
          />
          <input
            type="text"
            id="optinput2"
            value={digit2}
            maxLength={1}
            onFocus={() => onFocusEvent(2)}
            onKeyUp={(e: any) => handleFocus(e, 2)}
            onChange={(e: any) => {
              setDigit2(e.target.value);
            }}
            className={style.digit_box}
          />
          <input
            type="text"
            id="optinput3"
            value={digit3}
            maxLength={1}
            onFocus={() => onFocusEvent(3)}
            onKeyUp={(e: any) => handleFocus(e, 3)}
            onChange={(e: any) => {
              setDigit3(e.target.value);
            }}
            className={style.digit_box}
          />
          <input
            type="text"
            id="optinput4"
            value={digit4}
            maxLength={1}
            onFocus={() => onFocusEvent(4)}
            onKeyUp={(e: any) => {
              handleFocus(e, 4);
              if (e.keyCode === 13) {
                vertifyOtp();
              }
            }}
            onChange={(e: any) => {
              setDigit4(e.target.value);
            }}
            className={style.digit_box}
          />
        </div>
        {isValid && (
          <div className={style.status_msg}>
            {time > 0 ? (
              <> Resend OTP in {time} seconds</>
            ) : (
              <div className={style.resend_btn} onClick={() => resendOtp()}>
                Resend
              </div>
            )}
          </div>
        )}
        {!isValid && <div className={style.error}>Invalid OTP</div>}
        {isValid ? (
          <button className={style.submit_btn} onClick={() => vertifyOtp()}>
            Verify
          </button>
        ) : (
          <button className={style.submit_btn} onClick={() => resendOtp()}>
            Resend OTP
          </button>
        )}
      </div>
    </>
  );
};

export default OTPInput;
