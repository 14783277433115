/* eslint-disable react-hooks/exhaustive-deps */
import { Drawer, Modal, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import { DatePicker } from "antd";
import type { DatePickerProps } from "antd";
import StaffComponent from "./staffrating";
import "./style.scss";
import { DrawerType } from "../type";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import TaskList from "../dropDown/tasklist";
import PropertyRating from "./propertyrating";
import { useParams } from "react-router-dom";
import moment from "moment";
import { validationIf } from "../../../utils/helper";

const MaintenanceDrawer: FC<DrawerType> = ({ showDrawer, setShowDrawer, visitData, readonly, title, onChange }) => {
  const param = useParams();
  const currDate = new Date().toLocaleDateString("en-CA");
  const [isReadOnly, setIsReadOnly] = useState(readonly || false);
  const [showFeedBackFields, setShowFeedBackFields] = useState(
    visitData && currDate <= visitData.visit_date ? false : true
  );
  const [visible, setVisible] = useState(false);
  const [taskList, setTaskList] = useState([]);
  // const [selectedTask, setSelectedTask] = useState([]);
  // const [staffList, setStaffList] = useState([]);
  // const star_patern = [
  //   { name: "Very bad", img: "/images/property/star_icon.svg", id: 1 },
  //   { name: "Bad", img: "/images/property/star_icon.svg", id: 2 },
  //   { name: "Average", img: "/images/property/star_icon.svg", id: 3 },
  //   { name: "Good", img: "/images/property/star_icon.svg", id: 4 },
  //   { name: "Very good", img: "/images/property/star_icon.svg", id: 5 },
  // ];
  const defaultStatus = {
    date: true,
    staffrating: true,
    propertyrating: true,
    tasklist: true,
    stafflist: true,
    propertylist: true,
  };
  const [fieldStatus, setFieldStatus] = useState({ ...defaultStatus });
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const defaultState = Object.assign(
  //   {
  //     visit_date: "",
  //     completed_tasks: [],
  //     staff_rating: 0,
  //     staff_issue: [],
  //     property_rating: 0,
  //     property_issue: [],
  //     comment: "",
  //   },
  //   visitData,
  // )

  let defaultState = {
    visit_date: "",
    completed_tasks: [],
    staff_rating: 0,
    staff_issue: [],
    property_rating: 0,
    property_issue: [],
    comment: "",
  };
  // defaultState = Object.assign(defaultState, visitData);

  const [data, setData] = useState({ ...defaultState, ...visitData });
  const onClose = () => {
    setShowDrawer(false);
    setVisible(false);
    if (visitData) {
      return;
    }
    onReset();
  };
  const showModal = () => {
    setIsModalVisible(true);
    setVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setData({ ...data, ...visitData });
  }, [visitData]);

  useEffect(() => {
    setVisible(showDrawer);
    getConfig();
  }, [showDrawer]);

  const getConfig = async () => {
    const res = await API.get(APIS.USER_MANAGEMENT.CONFIG);

    setTaskList(res.data.data.TASKS);
    // setStaffList(res.data.data.STAFF);
  };

  const removeTask = (value: any) => {
    let vals: any = [...data.completed_tasks];
    if (vals.includes(value)) {
      const index = vals.indexOf(value);
      if (index > -1) {
        vals.splice(index, 1);
      }
    }
    setData({ ...data, completed_tasks: vals });
  };

  const onResolve = async () => {
    if (!showFeedBackFields) {
      let res = null;
      if (visitData && visitData.id) {
        let updateData: any = { ...data };
        delete updateData.id;
        res = await API.put(APIS.USER_MANAGEMENT.UPDATE_VISIT + visitData.id, updateData);
      } else {
        res = await API.post(APIS.USER_MANAGEMENT.ADD_VISIT + param.id, data);
      }

      if (res.data.status === 1) {
        setShowDrawer(false);
        setData(defaultState);
        showModal();
        onChange();
      } else {
        notification.error({
          message: `ERROR IN SENDING DATA`,
          placement: "top",
        });
      }
      setFieldStatus(defaultStatus);
      onReset();
      return;
    }

    let updatedStatus = { ...fieldStatus };

    if (data.visit_date === "") {
      updatedStatus = { ...updatedStatus, date: false };
    }
    if (data.completed_tasks.length === 0) {
      updatedStatus = { ...updatedStatus, tasklist: false };
    }
    if (data.staff_issue.length === 0) {
      updatedStatus = { ...updatedStatus, stafflist: false };
    }
    if (data.property_issue.length === 0) {
      updatedStatus = { ...updatedStatus, propertylist: false };
    }
    if (data.staff_rating === 0) {
      updatedStatus = { ...updatedStatus, staffrating: false };
    }
    if (data.property_rating === 0) {
      updatedStatus = { ...updatedStatus, propertyrating: false };
    }
    if (data.property_rating > 3) {
      updatedStatus = { ...updatedStatus, propertylist: true };
    }
    if (data.staff_rating > 3) {
      updatedStatus = { ...updatedStatus, stafflist: true };
    }
    if (
      updatedStatus.stafflist &&
      updatedStatus.date &&
      updatedStatus.propertylist &&
      updatedStatus.propertyrating &&
      updatedStatus.tasklist &&
      updatedStatus.staffrating
    ) {
      let res = null;
      if (visitData && visitData.id) {
        let updateData: any = { ...data };
        if (data.property_rating > 3) updateData.property_issue = [];
        if (data.staff_rating > 3) updateData.staff_issue = [];
        delete updateData.id;
        res = await API.put(APIS.USER_MANAGEMENT.UPDATE_VISIT + visitData.id, updateData);
      } else {
        res = await API.post(APIS.USER_MANAGEMENT.ADD_VISIT + param.id, data);
      }

      if (res.data.status === 1) {
        setShowDrawer(false);
        setData(defaultState);
        showModal();
        onChange();
      } else {
        notification.error({
          message: `ERROR IN SENDING DATA`,
          placement: "top",
        });
      }
      setFieldStatus(defaultStatus);
      return;
    }
    setFieldStatus(updatedStatus);
  };

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setData({ ...data, visit_date: dateString });

    setShowFeedBackFields(currDate >= dateString);
    setFieldStatus({ ...fieldStatus, date: true });
  };

  const onReset = () => {
    // setData({ ...defaultState, visit_date: visitData.visit_date });
    // if (!readonly) setShowFeedBackFields(true);
    // setIsReadOnly(false);
    // setselectedDate("")
    if (readonly) setData({ ...defaultState, visit_date: visitData.visit_date });
    else {
      setData({ ...defaultState })
      setShowFeedBackFields(true);
    }
    setFieldStatus({...defaultStatus})
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        maskClosable={true}
        closeIcon={<img className="icon-previous" src="/images/property/pending_icon.svg" alt="pending_logo"></img>}
        closable={false}
        visible={visible}
        width={464}
        bodyStyle={{
          padding: 0,
          position: "relative",
          overflow: "scroll",
          height: "100%",
        }}
      >
        <div className={style.monthly_drawer}>
          <div className={style.monthly_header}>
            <div className={style.monthly_head_name}>{title || "Add monthly visit"}</div>
            <div className={style.closeBtn} onClick={onClose}>
              <img src="/images/close_icon.svg" alt="" />
            </div>
          </div>

          <div className={style.date}>Date</div>
          <div className={style.date_input}>
            <DatePicker
              value={data.visit_date !== "" ? moment(data.visit_date) : null}
              className={style.date_picker}
              onChange={onDateChange}
              disabled={isReadOnly}
            />
          </div>
          {!fieldStatus.date && <div className={style.error}>Please select date</div>}
          {showFeedBackFields && (
            <>
              <div className={style.completed}>
                <img src="/images/property/setting_icon.svg" alt="setting_logo" />
                Completed Tasks
              </div>
              <div className={style.dropdown_task}>
                <TaskList
                  width={"100%"}
                  customClass={style.property_detail_dd}
                  data={data.completed_tasks}
                  listData={taskList}
                  getData={(tasks: any) => {
                    // setSelectedTask(tasks);
                    if (tasks.length !== 0) {
                      setFieldStatus({ ...fieldStatus, tasklist: true });
                    }
                    setData({ ...data, completed_tasks: tasks });
                  }}
                  showClear={true}
                  multi={true}
                />
              </div>
              {!fieldStatus.tasklist && <div className={style.error}>Please select task</div>}
              <div className={style.tasks_container}>
                {data.completed_tasks.length > 0 &&
                  data.completed_tasks[0] !== "" &&
                  data.completed_tasks.map((value: any, ind: any) => {
                    return (
                      <div className={style.tasklist_card_container} key={ind}>
                        <div className={style.task_card}>
                          {value}
                          <img src="/images/close_icon.svg" alt="" onClick={() => removeTask(value)} />
                        </div>
                      </div>
                    );
                  })}
              </div>

              <StaffComponent data={data} setData={setData} status={fieldStatus} setStatus={setFieldStatus} />

              <PropertyRating data={data} setData={setData} status={fieldStatus} setStatus={setFieldStatus} />

              <div className={style.add_response}>Add a comment</div>
              <textarea
                value={data.comment}
                placeholder="Type here"
                className={style.textarea}
                onChange={(e) => {
                  setData({ ...data, comment: e.target.value });
                }}
              ></textarea>
            </>
          )}
          <div className={style.buttongroup}>
            <button onClick={onReset} className={style.requestbuttons} style={{ marginRight: "25px" }}>
              Reset
            </button>
            <button
              onClick={onResolve}
              className={style.requestbuttons}
              style={{ background: "#082017", color: "white" }}
            >
              {visitData && visitData.id ? "Update" : "Add"}
            </button>
          </div>

          <div className={style.drawer_container}></div>
        </div>
      </Drawer>
      <Modal visible={isModalVisible} footer={null} onCancel={handleCancel} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/property/modal_success_icon.png" alt="" />
          <div className={style.modal_content} style={{}}>
            Your response has been sent successfully!
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MaintenanceDrawer;
