import React, { FC, useState } from "react";
import style from "./style.module.scss";
import { PropertyFeatureType } from "../type";
const PropertyFeature: FC<PropertyFeatureType> = ({ data, featurelist, setData }) => {
  const features: any = {
    BEDS_COUNT: "/images/features/Bed.svg",
    BATHS_COUNT: "/images/features/Washroom.svg",
    sqft: "/images/features/Property_size.svg",
    AREA_SQFT: "/images/features/Property_size.svg",
    EXTRA: "/images/features/Default_icon.svg",
  };
  const [extraValueCount, setExtraValueCount] = useState(0);
  const [dropDownActive, setDropDownActive] = useState(false);
  const [addedData, setAddedData] = useState<any>(data);
  const addFeature = (id: any, value: any) => {
    let featureObj = {
      [value]: {
        title: value == "AREA_SQFT" ? "SQFT" : featurelist[value],
        value: "",
      },
    };

    let newData = { ...addedData };
    const found = newData.hasOwnProperty(value);

    if (found) {
      delete newData[value];
    } else {
      Object.assign(newData, featureObj);
    }
    setAddedData(newData);

    callBack(newData);
  };

  const removeActive = (value: any) => {
    let newData = { ...addedData };
    const found = newData.hasOwnProperty(value);

    if (found) {
      delete newData[value];
    }
    setAddedData(newData);

    callBack(newData);
  };

  const addNewBlank = () => {
    let featureObj = {
      ["EXTRA" + extraValueCount]: {
        title: featurelist["EXTRA"],
        value: "",
      },
    };

    let newData = { ...addedData };
    const found = newData.hasOwnProperty("EXTRA");

    if (found) {
      delete newData["EXTRA"];
    } else {
      Object.assign(newData, featureObj);
    }
    setAddedData(newData);

    callBack(newData);
    setExtraValueCount(extraValueCount + 1);
  };

  const handleInputChange = (value: any, key: any, type: any) => {
    let newData = { ...addedData };
    newData[key][type] = value;
    setAddedData(newData);
  };

  const callBack = (value: any) => {
    setData(value);
  };

  return (
    <div className={style.property_feature_container}>
      <div className={style.input_group}>
        <div className={style.title}>Property Details</div>
        <div className={style.input_field}>
          <div className={`${style.drop_down_container}  `}>
            <div
              className={`${style.drop_down_selected} ${dropDownActive ? style.dd_active : ""}`}
              onClick={() => setDropDownActive(!dropDownActive)}
            >
              {Object.entries(addedData).length > 0
                ? Object.entries(addedData).length + " features added"
                : "Select Feature"}
              <img src="/images/drop_down_icon.svg" className={dropDownActive ? "" : style.rotate_icon} alt="" />
            </div>

            {
              <div className={`${style.drop_down_box}`} style={{ display: dropDownActive ? "block" : "none" }}>
                {Object.keys(featurelist).map((val: any, ind: any) => {
                  return (
                    <div key={ind}>
                      {/* add here the some waal logics */}
                      <div
                        id={`${ind}`}
                        key={ind}
                        className={`${style.menu} menu_active
               
                        `}
                        onClick={(e) => addFeature(`${ind}`, val)}
                      >
                        <img
                          src={`/images/DropDown/${
                            addedData.hasOwnProperty(val === "AREA_SQFT" ? "sqft" : val)
                              ? "radio_active.svg"
                              : "radio_inactive.svg"
                          }`}
                          className={style.active_circle}
                          alt=""
                        />

                        {featurelist[val]}

                        {/* {JSON.stringify(val)} */}
                      </div>
                      {ind !== addedData.length - 1 && <div className={style.divider}></div>}
                    </div>
                  );
                })}

                {/* <div className={style.clear} onClick={addNewBlank}>
                  Add New Blank
                </div> */}
              </div>
            }
            {dropDownActive && <div className={style.overlay} onClick={() => setDropDownActive(!dropDownActive)}></div>}
          </div>
          {addedData &&
            Object.keys(addedData).map((key: any, ind: any) => {
              return (
                <div className={style.property_detail} key={ind}>
                  <div className={style.img_box_uplaod}>
                    <img src={features[key]} alt="" />
                  </div>
                  <input
                    readOnly={true}
                    className={style.detail_name}
                    name="name"
                    value={addedData[key].title}
                    onChange={(e) => handleInputChange(e.target.value, key, "title")}
                  />
                  <i className={`icon-icon_close ${style.cross}`}></i>
                  <input
                    type="text"
                    className={style.quantity}
                    name="value"
                    value={addedData[key].value}
                    onChange={(e) => handleInputChange(e.target.value, key, "value")}
                    style={{ width: "72px" }}
                  />
                  <div
                    className={style.remove}
                    onClick={() => {
                      if (removeActive) removeActive(key);
                    }}
                  >
                    Remove
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PropertyFeature;
