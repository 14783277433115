import { FC } from "react";
import { Navigate } from "react-router-dom";
import LoginContainer from "../../container/LoginContainer";
import { useAuth } from "../../store/context/login";
import { routeMap } from "../../utils/constants";

const LoginPage: FC = () => {
  const { access } = useAuth();

  if (access) {
    return <Navigate to={routeMap.home} />;
  }

  return (
    <div>
      <LoginContainer />
    </div>
  );
};

export default LoginPage;
