import Layout from "../../layout/default";
import RequestContainer from "../../container/Requestcontainer";
const RequestPage = () => {
  return (
    <Layout>
      <RequestContainer />
    </Layout>
  );
};

export default RequestPage;
