import React from "react";
import HomeComponent from "../../component/Home";
const HomeContainer = () => {
  return (
    <div>
      <HomeComponent />
    </div>
  );
};

export default HomeContainer;
