/* eslint-disable no-new-wrappers */
/* eslint-disable eqeqeq */
import notification from "antd/lib/notification";
import React, { FC, useContext } from "react";
import { useParams } from "react-router-dom";
import { APIS } from "../../../../constants/apis";
import { UpdatePropertyData } from "../../../../container/PropertyContainer";
import API from "../../../../services/api.service";
import style from "./style.module.scss";
export interface SuspendAccount {
  data?: any;
  showModal?: any;
  setShowModal?: Function;
  onUpdate?: Function;
  callOwner?: Function;
}
const SuspendAccountModal: FC<SuspendAccount> = ({
  data,
  showModal,
  setShowModal,
  onUpdate,
  callOwner,
}) => {
  const para = useParams();
  const updateProperty = useContext(UpdatePropertyData);
  const suspendUser = async () => {
    const res = await API.post(APIS.PROPERTY.SUSPEND_ACOUNT + para.id, {
      user_id: data.id,
    });
    if (res.data.status === 1) {
      notification.success({
        message: res.data.message,
        placement: "topRight",
      });
      if (callOwner) callOwner();
      if (setShowModal) setShowModal(false);
      if (updateProperty) updateProperty?.update();
    } else {
      notification.error({
        message: res.data.message,
        placement: "topRight",
      });
    }
  };

  return (
    <>
      {showModal && (
        <div className={style.modal_container}>
          <div className={style.overlay}></div>
          <div className={style.modal_add_suspend_profile}>
            <div
              className={style.close_btn}
              onClick={() => {
                if (setShowModal) setShowModal(false);
              }}
            >
              <img src="/images/close_icon.svg" alt="" />
            </div>
            <div className={style.header}>
              <div className={style.title}>Suspend Account</div>
            </div>
            <div className={style.profile_picture_cont}>
              {data.profile_path.length > 0 && (
                <div className={style.img_box}>
                  <img
                    src={data.profile_path[0].file.urls.bucket.file}
                    alt=""
                  />
                </div>
              )}
              {data.profile_path.length == 0 && (
                <div className={style.img_box}>
                  <div className={style.profile_name_img}>
                    {new String(data.first_name).toUpperCase().substring(0, 1)}
                  </div>
                </div>
              )}
            </div>
            <div className={style.body}>
              <div className={style.msg}>
                On clicking suspend Mr. {data.first_name} {data.last_name}’s
                account will be suspended. Are you sure you want to continue?
              </div>
              <div className={style.action_box}>
                <button
                  className={style.btn_action}
                  onClick={() => {
                    if (setShowModal) setShowModal(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`${style.btn_action} ${style.suspend} `}
                  onClick={() => {
                    suspendUser();
                  }}
                >
                  Suspend
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuspendAccountModal;
