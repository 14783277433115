import React, { FC, useEffect } from "react";
import { APIS } from "../../constants/apis";
const ScriptsComponent: FC<any> = ({ onLoad }) => {
  useEffect(() => {
    const scriptElem = document.createElement("script");
    scriptElem.src = APIS.MAP;
    scriptElem.addEventListener("load", () => {});
    scriptElem.addEventListener("error", () => {});
    document.body.appendChild(scriptElem);
  }, []);

  return <div></div>;
};

export default ScriptsComponent;
