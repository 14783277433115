/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, DatePickerProps, Drawer, Modal, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import API from "../../../services/api.service";
import { APIS } from "../../../constants/apis";
import { useParams } from "react-router-dom";
import moment from "moment";
import RepeatationDropDown from "../../DropDown/RepeatationDropDown";
import ServiceDropDown from "../../DropDown/serviceDD";
export interface servicedrawer {
  showDrawer: any;
  setShowDrawer: Function;
  updateData?: any;
  onupdate: Function;
  id?: any;
  readonly?: any;
}

const AddServiceComponent: FC<servicedrawer> = ({ showDrawer, setShowDrawer, updateData, onupdate, id, readonly }) => {
  const param = useParams();

  const defaultState = {
    service_date: "",
    info: "",
    service_id: null,
    repeat_type: null,
    provider_name: "",
    provider_mobile: "",
  };

  const [serviceList, setServiceList] = useState({});
  const [validation, setValidation] = useState(false);
  const [data, setData] = useState({ ...defaultState, ...updateData });
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [repeatDropDown, setRepeatDropDown] = useState<any>({});
  const [count, setCount] = useState(0);
  const onClose = () => {
    setShowDrawer(false);
    setVisible(false);
  };
  // const showModal = () => {
  //   setIsModalVisible(true);
  //   setVisible(false);
  // };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    setVisible(showDrawer);
    getConfig();
  }, [showDrawer]);

  const getConfig = async () => {
    let newData = { ...data };
    const res = await API.get(APIS.USER_MANAGEMENT.CONFIG);
    if (res.data.status == 1) {
      setRepeatDropDown(res.data.data.REPEAT_TYPES);
    }
    if (updateData) {
      newData.repeat_type = updateData.repeat_type;
      setData(newData);
    }
    const serRes = await API.get(APIS.USER_MANAGEMENT.LIST_SERVICE);

    let list = {};

    if (serRes.data.status == 1) {
      serRes.data.data.map((val: any) => {
        return Object.assign(list, { [val.title]: val.id });
      });
    }
    setServiceList(list);
  };

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setData({ ...data, service_date: dateString });
  };

  const sendServiceData = async () => {
    if (data.info === "" || data.repeat_type === "" || data.service_date === "" || count > 3) {
      setValidation(true);
      return;
    }
    let res;
    if (id) {
      res = await API.put(APIS.USER_MANAGEMENT.UPDATE_MAINTANENCE + id, data);
    } else {
      res = await API.post(APIS.USER_MANAGEMENT.ADD_MAINTANENCE + param.id, data);
    }

    if (res.data.status === 1) {
      setIsModalVisible(true);
      onClose();
      onupdate();
      onReset();
    } else {
      notification.info({
        message: ` ${res.data.message}`,
        placement: "top",
      });
    }
  };

  const onReset = () => {
    if (readonly) {
      let newData = { ...defaultState };
      newData.service_date = updateData.service_date;
      setData(newData);
    } else {
      setData(defaultState);
    }

    setValidation(false);
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        maskClosable={true}
        closeIcon={<img className="icon-previous" src="/images/property/pending_icon.svg" alt="pending_logo"></img>}
        closable={false}
        visible={visible}
        width={464}
        bodyStyle={{
          padding: 0,
          position: "relative",
          overflow: "scroll",
          height: "100%",
        }}
      >
        <div className={style.service_drawer}>
          <div className={style.service_header}>
            <div className={style.service_head_name}>{updateData ? "Update service" : "Add new service"}</div>
            <div
              className={style.closeBtn}
              onClick={() => {
                setShowDrawer(false);
                setVisible(false);
              }}
            >
              <img src="/images/close_icon.svg" alt="" />
            </div>
          </div>
          <div className={style.service_body}>
            <div className={style.input_group}>
              <div className={style.title}>Category</div>
              <div className={style.input_field}>
                <ServiceDropDown
                  title={data.service_id}
                  data={Object.keys(serviceList)}
                  getData={(res: any) => {
                    let newData = { ...data };
                    newData.service_id = res;
                    setData(newData);
                    // // setOption(Object.keys(serviceList)[res]);
                  }}
                  customClass={style.service_dd}
                />
              </div>
            </div>
            <div className={style.input_group}>
              <div className={style.title}>Add Service information</div>
              <div className={style.input_field}>
                <input
                  type="text"
                  placeholder="Specific service  (max 3 words)"
                  value={data.info}
                  onChange={(e) => {
                    const arr = data.info.split(" ");
                    let count = arr.length;
                    setCount(count);
                    setData({ ...data, info: e.target.value });
                  }}
                />
              </div>
              {validation && data.info === "" && <div className={style.error}>Please add information</div>}
              {count > 3 && <div className={style.error}>Word limit exceeded</div>}
            </div>
            <div className={style.date}>Date</div>
            <div className={style.date_input}>
              <DatePicker
                disabled={readonly ?? false}
                value={data.service_date !== "" ? moment(data.service_date) : null}
                className={style.date_picker}
                onChange={onDateChange}
                // disabled={readonly || false}
              />
              {validation && data.service_date === "" && <div className={style.error}>Please select date</div>}
            </div>

            <div className={style.input_group}>
              <div className={style.title}>Repeat every</div>
              <div className={style.input_field}>
                <RepeatationDropDown
                  title={data.repeat_type}
                  data={repeatDropDown}
                  getData={(res: any) => {
                    let rep: any = Object.keys(repeatDropDown)[res];
                    let newData = { ...data };
                    newData.repeat_type = rep;
                    setData(newData);
                  }}
                  customClass={style.service_dd}
                />
                {validation && data.repeat_type === "" && <div className={style.error}>Please select repetition</div>}
              </div>
            </div>

            <div className={style.input_group}>
              <div className={style.title}>Service Provider Name</div>
              <div className={style.input_field}>
                <input
                  type="text"
                  placeholder="Add service provider name"
                  value={data.provider_name}
                  onChange={(e) => {
                    setData({ ...data, provider_name: e.target.value });
                  }}
                />
              </div>
              {validation && data.info === "" && <div className={style.error}>Please add information</div>}
            </div>
            <div className={style.input_group}>
              <div className={style.title}>Service Provider Contact</div>
              <div className={style.input_field}>
                <input
                  type="text"
                  placeholder="Add service provider contact"
                  value={data.provider_mobile}
                  maxLength={10}
                  onChange={(e) => {
                    setData({ ...data, provider_mobile: e.target.value });
                  }}
                />
              </div>
              {validation && data.info === "" && <div className={style.error}>Please add information</div>}
            </div>
          </div>
          <div className={style.buttongroup}>
            <button onClick={onReset} className={style.requestbuttons} style={{ marginRight: "25px" }}>
              Reset
            </button>
            <button
              onClick={() => sendServiceData()}
              className={style.requestbuttons}
              style={{ background: "#082017", color: "white" }}
            >
              {id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </Drawer>
      <Modal visible={isModalVisible} footer={null} onCancel={handleCancel} keyboard={true}>
        <div
          style={{
            height: "377px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img src="/images/property/modal_success_icon.png" alt="" />
          <div className={style.modal_content} style={{}}>
            {id ? "Service update" : "New service"} has been added successfully
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddServiceComponent;
