/* eslint-disable eqeqeq */
import React, { FC, useState } from "react";
import { EmailModule } from "../type";
import style from "./style.module.scss";
import { useAuth } from "../../../store/context/login";
const EmailInput: FC<EmailModule> = ({ onSubmit, userData }) => {
  // const [isEmailPhoneValid, setisEmailPhoneValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const { sendOtpEmail, sendOtpPhone } = useAuth();
  const [loginInput, setloginInput] = useState(userData.info);

  const handleChange = async () => {
    if (loginInput == "") {
      setErrorMessage("Please enter a registered Phone number or email id");
      return;
    }
    if (isNaN(loginInput)) {
      const res = await sendOtpEmail(loginInput);
      if (res.data.status !== 1) setErrorMessage("Please enter a registered email adrress");
      else {
        onSubmit(res.data.data.id, loginInput, true);
        setErrorMessage("");
      }
    } else {
      const res = await sendOtpPhone(loginInput);
      if (res.data.status !== 1) setErrorMessage("Please enter a registered Phone number");
      else {
        onSubmit(res.data.data.id, loginInput, true);
        setErrorMessage("");
      }
    }
  };
  const handleTextChange = (e: any) => {
    setloginInput(e.target.value);
  };

  // window.addEventListener("keyup", (e) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     handleChange();
  //   }
  // });

  return (
    <>
      <div className={style.main_title}>Welcome Back</div>
      <div className={style.sub_title}>Please enter your details</div>
      <div className={style.input_container}>
        {loginInput !== "" && !isNaN(loginInput) && <div className={style.isd}>+91</div>}
        <input
          maxLength={isNaN(loginInput) ? 50 : 10}
          autoFocus={true}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              handleChange();
            }
          }}
          type="text"
          className={`${errorMessage !== "" ? style.error : ""} ${!isNaN(loginInput) ? style.isd_active : ""}  `}
          style={{
            padding: `${loginInput !== "" && !isNaN(loginInput) ? `12px 40px` : `12px`} `,
          }}
          value={loginInput}
          onChange={(e) => handleTextChange(e)}
          placeholder="Enter your phone number / email"
        />
        <div className={style.status_msg}>{errorMessage}</div>
        <button
          className={`${style.submit_btn} ${userData !== "" ? style.active : ""}`}
          onClick={(e) => handleChange()}
        >
          Get OTP
        </button>
      </div>
    </>
  );
};

export default EmailInput;
