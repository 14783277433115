import Layout from "../../layout/default";
import PropertyContainer from "../../container/PropertyContainer";
import { useParams } from "react-router-dom";
const PropertyPage = () => {
  const params = useParams();
  return (
    <Layout>
      <PropertyContainer id={params.id} />
    </Layout>
  );
};

export default PropertyPage;
