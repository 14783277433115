/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIS } from "../../../../constants/apis";
import API from "../../../../services/api.service";
import { AddOwner } from "../../type";
import style from "./style.module.scss";
import ShareDD from "../../dropDown/shareDD";
import { Modal, notification } from "antd";
import { UpdatePropertyData } from "../../../../container/PropertyContainer";
import { useAuth } from "../../../../store/context/login";
const AddOwnerModal: FC<AddOwner> = ({
  showModal,
  setShowModal,
  callOwners,
}) => {
  const [show, setShow] = useState(showModal);
  const { user } = useAuth();
  const [existingUser, setExistingUser] = useState({
    user_id: 0,
    share: 1,
  });
  const defaultUser = {
    first_name: "",
    first_name_error: "",
    last_name: "",
    email: "",
    mobile: "",
    mobile_error: "",
    share: 1,
  };
  const [newUser, setNewUser] = useState({ ...defaultUser });
  const updateProperty = useContext(UpdatePropertyData);
  const para = useParams();
  const [showDD, setshowDD] = useState(false);
  const [existingOwner, setExistingOwner] = useState([]);
  const [listOfOwners, setlistOfOwners] = useState([]);
  const [selectedUser, setSelectedUser] = useState("Select from existing List");
  const [option, setOption] = useState(true);
  useEffect(() => {
    setShow(showModal);
    getExistingOwner();
  }, [showModal]);

  const closeModal = () => {
    setShow(false);
    setShowModal(false);
  };

  const getExistingOwner = async () => {
    const res = await API.get(APIS.PROPERTY.ADD_OWNER_USER_LIST + para.id);

    setExistingOwner(res.data.data);
    setlistOfOwners(res.data.data);
  };
  const selectedFromDD = (data: any) => {
    setExistingUser({ ...existingUser, user_id: data.id });
    setSelectedUser(data.first_name + " " + data.last_name);
    setOption(false);
    setshowDD(false);
  };

  const setShareData = (value: any) => {
    setExistingUser({ ...existingUser, share: value });
    setNewUser({ ...newUser, share: value });
  };

  const addNewUser = async () => {
    if (option === false) {
      try {
        const res = await API.post(
          APIS.PROPERTY.ASSIGN_USER + para.id,
          existingUser,
        );
        if (res.data.status === 1) {
          resetData();
          setShowModal(false);
          notification.success({
            message: res.data.message,
            placement: "topRight",
          });
          if (callOwners) callOwners();
          updateProperty?.update();
        } else {
          notification.error({
            message: res.data.message,
            placement: "topRight",
          });
        }
      } catch (error) {
        
      }
      return;
    }
    try {
      let error = false;
      const user = { ...newUser, mobile_error: "", first_name_error: "" };
      if (newUser.first_name.trim() == "") {
        user.first_name_error = "First name is required";
        error = true;
      }
      if (newUser.mobile.trim() == "") {
        user.mobile_error = "Contact number is required";
        error = true;
      } else {
        if (
          newUser.mobile.trim().length != 10 ||
          isNaN(Number(newUser.mobile.trim()))
        ) {
          user.mobile_error = "Contact number should be 10 digit only";
          error = true;
        }
      }
      setNewUser(user);
      if (error) {
        return;
      }
      const res = await API.post(APIS.PROPERTY.ADD_NEW_OWNER + para.id, user);
      if (res.data.status === 1) {
        resetData();
        setShowModal(false);
        notification.success({
          message: res.data.message,
          placement: "topRight",
        });
        if (callOwners) callOwners();
      } else {
        notification.error({
          message: res.data.message,
          placement: "topRight",
        });
      }
    } catch (error) {
      
    }
  };

  const resetData = () => {
    setOption(true);
    setNewUser({ ...defaultUser });
    setSelectedUser("Select from existing List");
  };

  const searchUser = (inp: any) => {
    const newlist = existingOwner
      .filter((name: any) => name.first_name.toLowerCase().includes(inp))
      .map((filteredName) => {
        return filteredName;
      });
    setlistOfOwners(newlist);
  };

  return (
    <Modal
      title="Vertically centered modal dialog"
      centered
      visible={showModal}
      onOk={() => {}}
      onCancel={() => {}}
      keyboard={true}
    >
      <div className={style.modal_container}>
        <div className={style.overlay} onClick={() => closeModal()}></div>
        <div className={style.modal_add_owner}>
          <div className={style.close_btn} onClick={() => closeModal()}>
            <img src="/images/close_icon.svg" alt="" />
          </div>
          <div className={style.header}>
            <div className={style.title}>Add owner</div>
          </div>
          <div className={style.drop_down_container}>
            <div className={style.select_input} onClick={() => setshowDD(true)}>
              <div className={style.text}>{selectedUser}</div>
              <img src="/images/drop_down_icon.svg" alt="" />
            </div>
            {showDD && (
              <div className={style.drop_down}>
                <div className={style.search_box}>
                  <input
                    type="text"
                    placeholder="Typing"
                    onChange={(e) => searchUser(e.target.value)}
                  />
                  <img src="/images/DropDown/Search.svg" alt="" />
                </div>
                {listOfOwners.length > 0 &&
                  listOfOwners.map((val: any, ind: any) => {
                    const { first_name, last_name, email } = val;
                    return (
                      email !== user.email && (
                        <div
                          className={style.options}
                          key={ind}
                          onClick={() => selectedFromDD(val)}
                        >
                          <img
                            src="/images/DropDown/circle_inactive.svg"
                            alt=""
                            className={style.circle_inactive}
                          />
                          <img
                            src="/images/DropDown/circle_active.svg"
                            alt=""
                            className={style.circle_active}
                          />
                          {first_name} {last_name}
                        </div>
                      )
                    );
                  })}
              </div>
            )}
            {showDD && (
              <div
                className={style.overlayDD}
                onClick={() => setshowDD(false)}
              ></div>
            )}
          </div>
          {option && (
            <div className={style.divider}>
              <div className={style.line}></div>
              <div className={style.text}>or</div>
              <div className={style.line}></div>
            </div>
          )}
          <div className={style.body}>
            {option && (
              <>
                <div className={style.input_group}>
                  <div className={style.title}>First Name</div>
                  <div
                    className={`${style.input_field} ${
                      newUser.first_name_error ? style.error : ""
                    }`}
                  >
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      value={newUser.first_name}
                      onChange={(e) => {
                        setNewUser({ ...newUser, first_name: e.target.value });
                      }}
                    />
                    {newUser.first_name_error && (
                      <div className={style.input_error}>
                        {newUser.first_name_error}
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.input_group}>
                  <div className={style.title}>Last Name</div>
                  <div className={style.input_field}>
                    <input
                      type="text"
                      placeholder="Enter Last Name"
                      value={newUser.last_name}
                      onChange={(e) => {
                        setNewUser({ ...newUser, last_name: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={style.input_group}>
                  <div className={style.title}>Email</div>
                  <div className={style.input_field}>
                    <input
                      type="email"
                      placeholder="Enter Email"
                      value={newUser.email}
                      onChange={(e) => {
                        setNewUser({ ...newUser, email: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className={style.input_group}>
                  <div className={style.title}>Contact number</div>
                  <div
                    className={`${style.input_field} ${
                      newUser.mobile_error ? style.error : ""
                    }`}
                  >
                    <input
                      type="text"
                      placeholder="Enter Contact Number"
                      value={newUser.mobile}
                      onChange={(e) => {
                        setNewUser({ ...newUser, mobile: e.target.value });
                      }}
                    />
                    {newUser.mobile_error && (
                      <div className={style.input_error}>
                        {newUser.mobile_error}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className={style.input_group}>
              <div className={style.title}>Number of Share</div>
              <ShareDD data={[1, 2, 3, 4]} getData={setShareData} />
            </div>

            <div className={style.action_box}>
              <button className={style.btn_action} onClick={resetData}>
                Reset
              </button>
              <button
                className={`${style.btn_action} ${style.submit} `}
                onClick={() => addNewUser()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddOwnerModal;
