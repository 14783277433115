/* eslint-disable eqeqeq */
import { FC, useEffect, useState } from "react";
import { circleDD } from "../type";
import style from "./style.module.scss";
const RadioDropdown: FC<circleDD> = ({
  data,
  customClass,
  getData,
  width,
  title,
  clearAll,
  setClearAll,
}) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const [option, setOption] = useState(
    title && title != "xc235" ? title : data[0],
  );

  const menuToggle = (id: any, value: any) => {
    setOption(value);
    if (getData) getData(id);
    setDropDownActive(!dropDownActive);
  };

  useEffect(() => {
    setOption(title && title != "xc235" ? title : data[0]);
  }, [title]);

  useEffect(() => {
    if (clearAll) {
      setOption(data[0]);
      if (setClearAll) setClearAll(false);
    }
  }, [clearAll]);

  return (
    <div className={style.drop_down_container} style={{ width: `${width}` }}>
      <div
        className={`${style.drop_down_selected} ${
          dropDownActive ? style.dd_active : ""
        } ${customClass}`}
        onClick={() => setDropDownActive(!dropDownActive)}
        style={{ opacity: `${dropDownActive ? "1" : "0.5"}` }}
      >
        {option}
        <img
          src="/images/drop_down_icon.svg"
          className={dropDownActive ? "" : style.rotate_icon}
          alt=""
        />
      </div>

      {
        <div
          className={style.drop_down_box}
          style={{ display: dropDownActive ? "block" : "none", width: width }}
        >
          {data.map((val: any, ind: any) => {
            return (
              <>
                <div
                  id={`${ind}`}
                  key={ind}
                  className={`${style.menu} menu_active `}
                  onClick={(e) => menuToggle(`${ind}`, val)}
                >
                  {option == val ? (
                    <img src="/images/DropDown/circle_active.svg" alt="" />
                  ) : (
                    <img src="/images/DropDown/circle_inactive.svg" alt="" />
                  )}
                  {val}
                </div>
                {ind !== data.length - 1 && (
                  <div className={style.divider}></div>
                )}
              </>
            );
          })}
        </div>
      }
      {dropDownActive && (
        <div
          className={style.overlay}
          onClick={() => setDropDownActive(!dropDownActive)}
        ></div>
      )}
    </div>
  );
};

export default RadioDropdown;
