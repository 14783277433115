export const APIS = {
  OTP: {
    VERIFYOTP: "otp/verify-otp-web",
    VERIFYMOBILE: "otp/send-otp-mobile",
    VERIFYEMAIL: "otp/send-otp-email",
  },

  PROPERTY_PARAMS: {
    CONFIG: "admin/params/config",
    GET_STATES: "admin/params/states",
    GET_DISTICTS: "admin/params/districts/",
    GET_CITIES: "admin/params/cities/",
    CONTACT: "admin/params/get-contact",
  },
  HOME: {
    PENDING_COMPLAINT: "admin/home/complaint-list",
    PENDING_REQUEST: "admin/home/special-request-list",
    PENDING_REQUEST_COUNT: "admin/properties/properties-request-count",
    PROPERTY_LIST: "admin/home/booking-request-complaint-count",
    OWNER_LIST: "admin/home/owner-list",
    PROPERTY_NAME_LIST: "admin/home/property-names-list",
    UPCOMING_BOOKING: "admin/home/upcomming-booking-list",
    BOOKING_DETAILS: "admin/home/booking-details",
    NOTIFICATION: "/admin/home/notification-count-list",
    NOTIFICATION_READ: "/admin/home/update-notification-seen_status",
  },
  PROPERTY: {
    GET_PROPERTY: "admin/property/get/",
    BOOKING_HISTORY: "admin/property/booking-history/",
    REQUEST: "admin/property/requests/",
    COMPLAINT: "admin/property/complaints/",
    UPDATE_REQUEST: "admin/property/update-request/",
    UPDATE_COMPLAINT: "admin/property/update-complaint/",
    ADD_PROPERTY: "admin/property/add-property",
    UPDATE_PROPERTY: "admin/property/update-property/",
    GET_ALL_OWNERS: "admin/property/all-owners",
    GET_OWNERS: "admin/property/get-owners/",
    ADD_OWNER_USER_LIST: "admin/property/get-users/",
    SUSPEND_ACOUNT: "admin/property/suspend-owner/",
    ADD_NEW_OWNER: "admin/property/new-user/",
    UPDATE_USER: "admin/property/update-user/",
    ASSIGN_USER: "admin/property/assign-user/",
    DELETE_PROPERTY: "admin/property/delete-property/",
    ADD_ACTIVITY: "admin/property/add-activity",
    UPDATE_ACTIVITY: "admin/property/update-activity",
  },
  USER_MANAGEMENT: {
    CONFIG: "admin/maintenance/config",
    ADD_VISIT: "admin/maintenance/add-visit/",
    UPDATE_VISIT: "admin/maintenance/update-visit/",
    UPCOMING_VISIT: "admin/maintenance/list-upcoming-visit/",
    HISTORY_VISIT: "admin/maintenance/list-history-visit/",
    DELETE_VISIT: "admin/maintenance/delete-visit/",
    UPCOMING_MAINTANENCE: "admin/maintenance/list-upcoming-maintenance/",
    HISTORY_MAINTANENCE: "admin/maintenance/list-history-maintenance/",
    DELETE_MAINTANENCE: "admin/maintenance/delete-maintenance/",
    UPDATE_MAINTANENCE: "admin/maintenance/update-maintenance/",
    UPDATE_MAINTE_STATUS: "admin/maintenance/update-maintenance-status/",
    ADD_MAINTANENCE: "admin/maintenance/add-maintenance/",
    LIST_SERVICE: "admin/maintenance/services",
  },
  PROFILE: {
    GET_PROFILE_DATA: "admin/profile/get-profile",
    ADD_PROFIE_PIC: "admin/profile/add-profile-pic",
    UPDATE_PROFILE: "admin/profile/update-profile",
    DELETE_PROFILE: "admin/profile/delete-profile-pic",
  },
  MAP: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD74BXw4etNUVqieB_82GYEwvfnyet1o8k",
  DOWLOAD_PDF: "admin/maintenance/visitpdf/",
  SETTING: {
    SEND_FEEDBACK: "admin/home/feedback-manager",
  },
};
