import { Drawer, notification } from "antd";
import React, { FC, useEffect, useState } from "react";
import { APIS } from "../../../constants/apis";
import API from "../../../services/api.service";
import { formatAMPM, formatDateMonthYear } from "../../../utils/helper";
import style from "./style.module.scss";
export interface bookingdrawer {
  data?: any;
  showDrawer: any;
  setShowDrawer: Function;
  onUpdate: Function;
  param?: any;
}
const BookingDrawerComponent: FC<bookingdrawer> = ({
  data,
  showDrawer,
  setShowDrawer,
  onUpdate,
  param,
}) => {
  const [visible, setVisible] = useState(showDrawer);
  const [response, setResponse] = useState({
    res: "",
    id: 0,
    status: 1,
  });
  let guestArray: Array<any> = [];

  const [edit, setEdit] = useState(false);
  // var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  let dateNow = new Date();
  let dateEnd = new Date();
  let start = new Date();
  let end = new Date();
  let checkinTime = new Date();
  let total_members = 0;

  if (!!data) {
    data.bookingRequest = data.details.special;
    data.guest = data.details.guest;
    dateNow = new Date(data.start_date);
    dateEnd = new Date(data.end_date);
    start = new Date(data.start_date);
    end = new Date(data.end_date);
    checkinTime = new Date("2022-01-01 " + data.start_time);
    total_members =
      data.guest.adults_count +
      data.guest.kids_count +
      data.guest.seniorcitizen_count +
      data.guest.infants_count;
    Object.keys(data.guest).map((value, index) => {
      let lastIndx, fielValue;
      if (
        data.guest[value] > 0 &&
        (value.includes("_count") || value.includes("s_count"))
      ) {
        if (value.includes("_count")) {
          lastIndx = value.indexOf("_count");
        }
        if (value.includes("s_count")) {
          lastIndx = value.indexOf("s_count");
        }
        fielValue = value.substring(0, lastIndx);
        guestArray.push({ field: fielValue, count: data.guest[value] });
      }
    });
  }

  const getDayNight = (date_1: any, date_2: any) => {
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));

    return TotalDays + 1;
  };

  const addResponse = async () => {
    const res = await API.post(
      APIS.PROPERTY.UPDATE_REQUEST + data.property_id,
      {
        id: response.id,
        response: response.res,
        status: response.status,
      },
    );
    if (res.data.status === 1) {
      onUpdate(param);
      setShowDrawer(false);
      setVisible(false);
      setEdit(false);
    } else {
      notification.info({
        message: `${res.data.message}`,
        placement: "top",
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    setShowDrawer(false);
    setEdit(false);
  };
  useEffect(() => {
    setVisible(showDrawer);
  }, [showDrawer]);

  // useEffect(() => {
  //   if (!!data) {
  //     setEdit(false);
  //   }
  // }, [data]);

  return (
    <>
      <Drawer
        placement="right"
        onClose={onClose}
        closeIcon={<i className="icon-previous"></i>}
        visible={visible}
        closable={false}
        width={514}
        bodyStyle={{
          padding: 0,
          position: "relative",
          overflow: "scroll",
          height: "100%",
        }}
        className={"ant-drawer-mask-container"}
      >
        {!!data && (
          <>
            <div className={style.drawer_header}>
              <div className={style.close_btn} onClick={onClose}>
                <i className="icon-previous"></i>
              </div>
              <div className={style.title}>{`${formatDateMonthYear(
                dateNow,
              )} - ${formatDateMonthYear(dateEnd)}`}</div>
            </div>
            <div className="drawer">
              <div className="drawer_address">
                <div className="address">
                  {/* Countryside house - Wonderful landscaped... */}
                  {data.propertyDetails.title}
                </div>
                <div className="sub_address">
                  {data.propertyDetails.location}, {data.propertyDetails.city}
                </div>
              </div>
              <hr className="line" />
              <div className="property_data">
                <div className="property_booking">Property booking for</div>
                <div className="booking_name">
                  {data.guest.name}{" "}
                  {data.booking_for !== "MYSELF" ? <>&#40;Guest&#41;</> : " "}
                  {total_members - 1 > 0
                    ? total_members - 1 == 1
                      ? "+ " + (total_members - 1) + " Member"
                      : "+ " + (total_members - 1) + " Members"
                    : ""}{" "}
                </div>
                <div className="total_guest">
                  {guestArray.length > 2 &&
                    guestArray.map((value: any, index: any) => {
                      return index !== guestArray.length - 1
                        ? `${value.count} ${value.field}, `
                        : index === 0
                        ? `${value.count} ${value.field}`
                        : `and ${value.count} ${value.field}`;
                    })}
                  {guestArray.length <= 2 &&
                    guestArray.length > 0 &&
                    guestArray.map((value: any, index: any) => {
                      return index !== guestArray.length - 1
                        ? `${value.count} ${
                            value.count > 1 ? value.field + "s" : value.field
                          } `
                        : index === 0
                        ? `${value.count} ${
                            value.count > 1 ? value.field + "s" : value.field
                          }`
                        : `and ${value.count} ${
                            value.count > 1 ? value.field + "s" : value.field
                          }`;
                    })}
                </div>
                <div className="booking_date_head">Date & time of Arrival</div>
                <div className="booking_dates">
                  <div className="start_date">
                    {formatDateMonthYear(dateNow)}
                  </div>
                  <div className="total_stay">
                    &#40; {getDayNight(end, start)} days |{" "}
                    {getDayNight(end, start) - 1} night &#41;{" "}
                  </div>
                  <div className="end_date">{formatDateMonthYear(dateEnd)}</div>
                </div>
                <div className="booking_time">at {formatAMPM(checkinTime)}</div>
              </div>
              <hr className="line" />
              {!edit &&
                data.status !== 0 &&
                data.details.special &&
                data.details.special.length <= 0 && (
                  <div className="special_request_head">
                    <div className="special">No request</div>
                  </div>
                )}
              {!edit &&
                data.status !== 0 &&
                data.details.special &&
                data.details.special.length > 0 &&
                data.details.special.map((val: any, ind: any) => {
                  return (
                    <div>
                      <div className="special_request_head">
                        <div className="special">Special Request</div>
                        <div className="accepted">
                          {val.status === 0
                            ? "Pending"
                            : val.status === 1
                            ? "Accepted"
                            : "Denied"}
                          <img
                            src={`/images/property/${
                              val.status === 0
                                ? "pending_icon.svg"
                                : val.status === 1
                                ? "accepted_icon.svg"
                                : "denied_icon.png"
                            }`}
                            alt="accept"
                          ></img>
                        </div>
                      </div>
                      <div className="special_request_content">
                        {val.description}
                      </div>
                      <div className="request_time">
                        at {formatAMPM(new Date(val.created_at))}
                      </div>
                      {val.status !== 0 && (
                        <div className={style.reason}>
                          <div className={style.reason_left}>
                            <div className={style.reason_head}>Reason</div>
                            <div className={style.reason_content}>
                              {val.response}
                            </div>
                            {/* <div className="reason_time">at 01:30 AM</div> */}
                            <div className="request_time">
                              at {formatAMPM(new Date(val.created_at))}
                            </div>
                          </div>
                          <div className={style.reason_right}>
                            {data && data.status === 1 && (
                              <div
                                className={style.edit_btn}
                                onClick={() => {
                                  setResponse({
                                    ...response,
                                    res: val.response,
                                    id: val.id,
                                    status: val.status,
                                  });
                                  setEdit(true);
                                }}
                              >
                                Edit
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}

              {data && data.status === 1 && edit && (
                <>
                  <div className={style.special_request}>Add Response</div>

                  <textarea
                    className={style.textfield}
                    value={response.res}
                    placeholder="Add a comment to let the the property owner know more regarding the complaint "
                    onChange={(e) => {
                      setResponse({
                        ...response,
                        res: e.target.value,
                      });
                    }}
                  />
                </>
              )}

              {data && data.status === 1 && edit && (
                <div className={style.buttongroup}>
                  <button
                    className={style.requestbuttons}
                    style={{ marginRight: "25px" }}
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      addResponse();
                    }}
                    className={style.requestbuttons}
                    style={{ background: "#082017", color: "white" }}
                  >
                    {data.status === 0 ? "Confirm" : "Update"}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};

export default BookingDrawerComponent;
