import { FC } from "react";
import style from "./style.module.scss";
import ActivityAdd from "./activityAdd";
import { ActivityType } from "../type";
import { getActivityDefault } from "../helper";

const ActivityComponent: FC<ActivityType> = ({
  config,
  propertyData,
  setPropertyData,
}) => {
  const addNewActivity = () => {
    propertyData.activitiesList.push(getActivityDefault());
    if(setPropertyData)
      setPropertyData({...propertyData, activitiesList: [...propertyData.activitiesList]});
  };

  const updateActivityData = (activity: any, index: number) => {
    propertyData.activitiesList[index] = activity;
    if(setPropertyData)
      setPropertyData({...propertyData, activitiesList: [...propertyData.activitiesList]});
  };

  const deleteActivity = (index: number) => {
    const activities = propertyData.activitiesList;
    activities.splice(index, 1);
    if(setPropertyData)
      setPropertyData({...propertyData, activitiesList: [...activities]});
  };

  return (
    <div className={style.activity_page}>
      {propertyData.activitiesList.map((val: any, ind: any) => {
        return (
          <ActivityAdd
            key={ind}
            categorylist={config.categories}
            activityData={val}
            setActivityData={(data: any) => {
              updateActivityData(data, ind);
            }}
            onDelete={()=>deleteActivity(ind)}
          />
        );
      })}
      <div className={style.add_btn} onClick={addNewActivity}>
        <img src="/images/plus_icon.svg" alt="" />
        Add {propertyData.activitiesList.length === 0 ? "New" : "Another"} Placess
      </div>
    </div>
  );
};

export default ActivityComponent;
