/* eslint-disable no-new-wrappers */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, FC, useEffect, useState } from "react";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";

import style from "./style.module.scss";
import "./style.scss";
import { getProperty } from "../../constants/config";
import { useAuth } from "../../store/context/login";
import AllBookingModal from "../../component/PropertyDetails/Modals/allbooking";
import DetailsTab from "../../component/PropertyDetails/detailsTab";
import { useNavigate } from "react-router-dom";

import { routeMap } from "../../utils/constants";
import { Tooltip } from "antd";
type propertyAPI = {
  update: Function;
};
export const UpdatePropertyData = createContext<propertyAPI | null>({
  update: () => {},
});
const PropertyContainer: FC<any> = ({ id }) => {
  const [data, setData] = useState<any>(null);
  const [activeBookingModal, setActiveBookingModal] = useState(false);
  const { token } = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    getData();
    getUpcomingData();
  }, [token]);

  const getData = async () => {
    const res = await getProperty(token, id);
    setData(res.data.data);
  };

  const [upcommingBookingData, setUpcommingBookingData] = useState<any>({
    count: "",
    rows: [],
  });
  const getUpcomingData = async () => {
    const body = {
      page: 1,
      limit: 50,
      owners: [],
      upcoming: true,
      status: 1,
      sort: [["start_date", "ASC"]],
    };
    const res = await API.post(APIS.PROPERTY.BOOKING_HISTORY + id, body);
    setUpcommingBookingData(res.data.data);
  };

  return (
    <>
      {data && (
        <div className={style.property_card_detail}>
          <div className={style.card_detail_header}>
            <div className={style.property_name}>
              <img
                src="/images/left_arrow.svg"
                alt=""
                onClick={() => {
                  navigate(routeMap.properties);
                }}
              />
              {data.title}
            </div>
            <div className={style.location}>
              <i className=" icon-geo-location"></i>
              {data.location}, {data.state}
            </div>
          </div>
          <div className={style.care_taker_info_section}>
            <div
              className={style.care_taker}
              style={{
                backgroundImage:
                  data.details.images.length > 0 ? `url(${data.details.images[0].file.urls.bucket.large})` : "",
              }}
            >
              {/* <div className={style.details}>
            <div className={style.title}>Care taker</div>
            <div className={style.care_taker_info}>
              <div className={style.name}>Shiva Kumar</div>
              <div className={style.phone}>
                <i className=" icon-phone"></i>
                +91 6363636363
              </div>
            </div>
          </div> */}
            </div>
            <div className={style.owner_part}>
              <div className={style.owners}>
                <div className={style.title}>Owners</div>
                <div className={style.owners_complete}>
                  <div className={style.onwer_imgs}>
                    {!!data &&
                      data.details.owners.length > 0 &&
                      data.details.owners.map((val: any, ind: any) => {
                        // if (ind <= 5)
                        return (
                          <>
                            {val.profile_path && val.profile_path.length > 0 ? (
                              <div>
                                {ind < 4 && (
                                  <Tooltip className={style.tool_tip} placement="bottom" title={val.first_name}>
                                    <img
                                      src={val.profile_path[0].file.urls.bucket.file}
                                      style={{
                                        transform: `translateX(${-8 * ind}px)`,
                                        borderWidth: "2px",
                                      }}
                                      alt="noimg"
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            ) : (
                              <>
                                {ind < 4 && (
                                  <Tooltip className={style.tool_tip} placement="bottom" title={val.first_name}>
                                    <div
                                      className={style.owner_name_img}
                                      style={{
                                        transform: `translateX(${-8 * ind}px)`,
                                        borderWidth: "2px",
                                      }}
                                    >
                                      {new String(val.first_name).substring(0, 1).toUpperCase()}
                                    </div>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        );
                      })}
                    {data.details.owners.length - 4 >= 1 && (
                      <div
                        className={style.owner_name_img}
                        style={{
                          transform: `translateX(${-8 * 4}px)`,
                          borderWidth: "2px",
                        }}
                      >
                        + {Math.abs(data.details.owners.length - 4)}
                      </div>
                    )}
                    {/* {JSON.stringify(data.details.owners.length)} */}
                  </div>
                  {/* <div className={style.view_all}>view all</div> */}
                </div>
              </div>
              <div className={style.upcomming_booking}>
                <div className={style.title}>Upcoming Bookings &#40; {upcommingBookingData.rows.length} &#41;</div>
                <button className={style.view_all} onClick={() => setActiveBookingModal(true)}>
                  View All Upcoming Bookings
                </button>
              </div>
            </div>
          </div>
          <div className={style.details_tab_container}>
            <UpdatePropertyData.Provider
              value={{
                update: getData,
              }}
            >
              <DetailsTab data={data} />
            </UpdatePropertyData.Provider>
          </div>
          {activeBookingModal && (
            <AllBookingModal
              showModal={activeBookingModal}
              setShowModal={setActiveBookingModal}
              data={upcommingBookingData.rows}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PropertyContainer;
// export const updateProperty = () => useContext(UpdatePropertyData);
