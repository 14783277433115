import React, { FC, useEffect, useState } from "react";
import { APIS } from "../../../../constants/apis";
import API from "../../../../services/api.service";
import style from "./style.module.scss";
export interface staffrating {
  data?: any;
  setData: Function;
  status?: any;
  setStatus: Function;
}
const StaffComponent: FC<staffrating> = ({ data, setData, status, setStatus }) => {
  const [staffList, setStaffList] = useState([]);
  const [staffIssue, setStaffIssue] = useState<any>([]);
  const star_patern = [
    { name: "Very bad", img: "/images/property/star_icon.svg", id: 1 },
    { name: "Bad", img: "/images/property/star_icon.svg", id: 2 },
    { name: "Average", img: "/images/property/star_icon.svg", id: 3 },
    { name: "Good", img: "/images/property/star_icon.svg", id: 4 },
    { name: "Very good", img: "/images/property/star_icon.svg", id: 5 },
  ];

  // const [starOverall, setStarOverall] = useState(-1);

  const handleStarColorStaff = (index: any) => {
    setStatus({ ...status, staffrating: true });

    if (index >= 3) {
      setData({ ...data, staff_issue: [] });
      setStatus({ ...status, stafflist: true });
    } else {
      setData({ ...data, staff_issue: staffIssue });
    }
    setData({ ...data, staff_rating: index + 1 });
  };
  // const handleStarColorOverAll = (index: number) => {
  //   setStarOverall(index);
  // };

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = async () => {
    const res = await API.get(APIS.USER_MANAGEMENT.CONFIG);

    setStaffList(res.data.data.STAFF);
  };

  const addIssue = (value: any) => {
    let issue: any = [...staffIssue];
    if (issue.includes(value)) {
      const index = issue.indexOf(value);
      if (index > -1) {
        issue.splice(index, 1);
      }
    } else {
      issue.push(value);
    }
    if (issue.length !== 0) {
      setStatus({ ...status, stafflist: true });
    }
    setData({ ...data, staff_issue: issue });
  };

  useEffect(() => {
    setStaffIssue(data.staff_issue);
  }, [data]);

  return (
    <>
      <div className={style.staff}>
        <img src="/images/property/user_icon.svg" alt="user_logo" />
        How was the staff?
      </div>
      {!status.staffrating && <div className={style.error}>Please rate staff</div>}
      <div className={style.pattern}>
        {star_patern.map((val: any, index: number) => {
          return (
            <div className={style.star_pattern} key={index} onClick={() => handleStarColorStaff(index)}>
              <span className={` ${style.star_icon} ${index <= data.staff_rating - 1 ? style.on : style.off}`}>
                <img src="/images/star.svg" className={style.start_inactive} alt="" />
                <img src="/images/starActive.svg" className={style.start_active} alt="" />
              </span>
              <div className={style.star_criteria}>{val.name}</div>
            </div>
          );
        })}
      </div>

      {data.staff_rating - 1 > -1 && data.staff_rating - 1 < 3 && (
        <div className={style.why_avg_rating_container}>
          <div className={style.why_avg_rating}>Why are you rating {star_patern[data.staff_rating - 1].name}? </div>
          {!status.stafflist && <div className={style.error}>Please select issue</div>}
          <div className={style.body}>
            {staffList.map((value: any, ind: any) => {
              return (
                <div
                  key={ind}
                  className={`${style.card_container} ${staffIssue.includes(value) ? style.active : ""}`}
                  onClick={() => addIssue(value)}
                >
                  <div className={style.card}>{value}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default StaffComponent;
