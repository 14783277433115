import ComplaintComponent from "../../component/Complaint";
const ComplaintContainer = () => {
  return (
    <>
      <ComplaintComponent />
    </>
  );
};

export default ComplaintContainer;
