/* eslint-disable eqeqeq */
import React, { FC, useEffect, useState } from "react";
import style from "./style.module.scss";
import MaintenanceDrawer from "../../maintanaceDrawer";
import API from "../../../../services/api.service";
import { APIS } from "../../../../constants/apis";
import { notification, Modal } from "antd";
import { useAuth } from "../../../../store/context/login";
export interface historyvisitcard {
  data: any;
  callVisitHistory: Function;
}
const HistoryVisitCard: FC<historyvisitcard> = ({ data, callVisitHistory }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taskList, setTaskList] = useState<any>([]);
  const { token } = useAuth();
  const newData = {
    id: data.id,
    visit_date: data.visit_date,
    completed_tasks: data.completed_tasks.split(","),
    property_issue: data.property_issue.split(","),
    property_rating: data.property_rating,
    staff_issue: data.staff_issue.split(","),
    staff_rating: data.staff_rating,
    comment: data.comment,
  };
  const completedTask: any = data.completed_tasks.split(",");
  const [showMenu, setShowMenu] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const star = [1, 2, 3, 4, 5];
  const handleMenuBtn = () => {
    setShowMenu(!showMenu);
  };
  const handleOption = (option: any) => {
    switch (option) {
      case "EDIT":
        setShowDrawer(true);
        break;

      default:
        break;
    }
    setShowMenu(!showMenu);
  };
  const getConfig = async () => {
    const res = await API.get(APIS.USER_MANAGEMENT.CONFIG);
    setTaskList(res.data.data.TASKS);
  };
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const nth = function (d: any) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  useEffect(() => {
    getConfig();
  }, []);

  const deleteVisit = async () => {
    const res = await API.delete(APIS.USER_MANAGEMENT.DELETE_VISIT + data.id);
    if (res.data.status == 1) {
      callVisitHistory();
    } else {
      notification.info({
        message: `Unable to Delete`,
        placement: "top",
      });
    }
    setIsModalVisible(false);
  };

  const downloadPDF = async () => {
    window.location.href = `https://api.yours.devstudioonline.com/${
      APIS.DOWLOAD_PDF + data.id
    }?token=${token}`;
  };

  return (
    <>
      {" "}
      <div className={style.visit_cards}>
        <div className={style.vist_head}>
          <div className={style.head_left}>
            <div className={style.profile_icon}>
              <img
                src="/images/property/user_icon.svg"
                alt=""
                className={style.img_profile}
              />
            </div>
            <div className={style.head_name}>
              Personal
              <div className={style.head_name_part}>
                {" "}
                {new Date(data.visit_date).getUTCDate()}
                {nth(new Date(data.visit_date).getUTCDate())}{" "}
                {month[new Date(data.visit_date).getMonth()]}{" "}
                {new Date(data.visit_date).getFullYear()}{" "}
              </div>
            </div>
          </div>
          <div className={style.head_right}>
            <div className={style.menu_btn} onClick={() => handleMenuBtn()}>
              <img src="/images/property/more_icon.svg" alt="" />
            </div>
            {showMenu && (
              <div className={style.menu_list_show}>
                <div
                  className={style.menu_list}
                  onClick={() => {
                    handleOption("EDIT");
                    setShowMenu(false);
                  }}
                >
                  <span>
                    <img
                      src="/images/edit.svg"
                      className={style.img_icon}
                      alt={""}
                    />
                    Edit
                  </span>
                </div>
                <div className={style.divider}></div>

                <div
                  className={style.menu_list}
                  onClick={() => {
                    setIsModalVisible(true);
                    setShowMenu(false);
                  }}
                >
                  <span>
                    <img
                      src="/images/delete.svg"
                      className={style.img_icon}
                      alt={""}
                    />
                    Delete
                  </span>
                </div>

                <div className={style.divider}></div>
                <div
                  className={style.menu_list}
                  onClick={() => {
                    downloadPDF();
                    setShowMenu(false);
                  }}
                >
                  <span>
                    <img
                      src="/images/download.svg"
                      className={style.img_icon}
                      alt={""}
                    />
                    Download PDF
                  </span>
                </div>
              </div>
            )}
            {showMenu && (
              <div className={style.menu_overlay} onClick={handleMenuBtn}></div>
            )}
          </div>
        </div>
        <div className={style.visit_container}>
          <div className={style.container_head}>
            Completed {newData.completed_tasks.length} of {taskList.length}{" "}
            Tasks
          </div>
          <div className={style.task}>
            {completedTask &&
              completedTask.map((val: any, ind: any) => {
                return (
                  <div className={style.task} key={ind}>
                    <img src="/images/property/check_icon.svg" alt="" />
                    {val}
                  </div>
                );
              })}
          </div>
        </div>
        <div className={style.visit_footer}>
          <div className={style.footer_left}>
            <div className={style.footer_name}> Property remark</div>
            <div className={style.star_icon}>
              {star.map((val, index) => {
                return (
                  <span
                    className={`${style.star_container} ${
                      index < data.property_rating ? style.active : ""
                    }`}
                  >
                    <div className={`${style.img_icon} ${style.star_active}`}>
                      <img src="/images/starActive.svg" alt="" />
                    </div>
                    <div className={`${style.img_icon} ${style.star_inactive}`}>
                      <img src="/images/star.svg" alt="" />
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
          <div className={style.footer_left}>
            <div className={style.footer_name}>Staff remark</div>
            <div className={style.star_icon}>
              {star.map((val, index) => {
                return (
                  <span
                    className={`${style.star_container} ${
                      index < data.staff_rating ? style.active : ""
                    }`}
                  >
                    <div className={`${style.img_icon} ${style.star_active}`}>
                      <img src="/images/starActive.svg" alt="" />
                    </div>
                    <div className={`${style.img_icon} ${style.star_inactive}`}>
                      <img src="/images/star.svg" alt="" />
                    </div>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <MaintenanceDrawer
        onChange={callVisitHistory}
        title={"Update personal visit"}
        visitData={newData}
        showDrawer={showDrawer}
        readonly={true}
        setShowDrawer={setShowDrawer}
      />
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        keyboard={true}
      >
        <div className={style.modal_delete}>
          <div className={style.modal_header}>Delete visit</div>
          <div className={style.modal_content} style={{}}>
            On clicking delete, You’ll be deleting an entry. Are you sure you
            want to continue?
          </div>
          <div className={style.modal_btns}>
            <div
              className={style.cancel}
              onClick={() => setIsModalVisible(false)}
            >
              Cancel
            </div>
            <div className={style.delete} onClick={deleteVisit}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HistoryVisitCard;
