import { useState, useEffect } from "react";
import API from "../../services/api.service";
import { AuthContext } from "./login";
import { APIS } from "../../constants/apis";
import ScriptsComponent from "../../component/Scripts";

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<any>(null);
  const t = window.localStorage.getItem("token") || "";
  const [access, setAccess] = useState<any>(typeof t === "string" && t !== "" ? true : false);
  const [token, setToken] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = window.localStorage.getItem("token") || "";
      if (typeof token === "string" && token !== "") {
        setAccess(true);
        API.defaults.headers.common["Authorization"] = "Bearer " + token;
        getProfileData();
        setToken(token);
        setLoading(false);
      } else {
        setAccess(false);
      }
    }
    loadUserFromCookies();
  }, []);

  const login = async (id: string, otp: string) => {
    let body = { id, otp };
    const res: any = await API.post(APIS.OTP.VERIFYOTP, body);
    if (res.data.status === 1) {
      setAccess(true);
      setToken(res.data.data.token);
      getProfileData();
      window.localStorage.setItem("access", JSON.stringify(true));
      window.localStorage.setItem("token", res.data.data.token);
    }
    return res;
  };

  const logout = () => {
    window.localStorage.clear();
    setUser(null);
    setAccess(false);
    setToken(null);
  };
  const getProfileData = async () => {
    const res = await API.get(APIS.PROFILE.GET_PROFILE_DATA);
    if (res.data.status === 1) {
      setUser(res.data.data);
      window.localStorage.setItem("user", JSON.stringify(res.data.data));
    }
    return res;
  };

  const sendOtpEmail = async (email: string) => {
    let body = { destination: email };
    const res: any = await API.post(APIS.OTP.VERIFYEMAIL, body);
    if (res.status === 1) {
      getProfileData();
    }
    return res;
  };
  const sendOtpPhone = async (phone: string) => {
    let body = { isd: "+91", destination: phone };
    const res: any = await API.post(APIS.OTP.VERIFYMOBILE, body);
    if (res.status === 1) {
      getProfileData();
    }
    return res;
  };

  return (
    <div>
      <AuthContext.Provider
        value={{
          isAuthenticated: !!user,
          user,
          login,
          logout,
          sendOtpEmail,
          sendOtpPhone,
          loading,
          token,
          access,
          getProfileData,
        }}
      >
        {window ? children : ""}
      </AuthContext.Provider>
      <ScriptsComponent />
    </div>
  );
};

export default AuthProvider;
