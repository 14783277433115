/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";

import style from "./style.module.scss";
import API from "../../services/api.service";
import { APIS } from "../../constants/apis";
import { LocationType } from "./type";
import LocationDropdown from "./LocationDropdown";
import MapComponent from "../Map";
import { propertyFieldValidator } from "../AddProperty/helper";

const LocationComponent: FC<LocationType> = ({
  state_id,
  district_id,
  city_id,
  location,
  pin,
  lat,
  long,
  validation,
  onLocationChange,
  pickLatLong,
  city,
  state,
  district,
}) => {
  const [locationData, setLocationData] = useState({
    state_id,
    district_id,
    city_id,
    location,
    pin,
    lat,
    long,
    state: state ? state : "",
    district: district ? district : "",
    city: city ? city : "",
  });
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [beforeBlur, setBeforeBlue] = useState(location);

  useEffect(() => {
    fetchState();
    if (state_id) {
      fetchDistrict(state_id);
    }
    if (district_id) {
      fetchCity(district_id);
    }
  }, []);

  useEffect(() => {
    if (onLocationChange) {
      onLocationChange(locationData);
    }
  }, [locationData]);

  const fetchState = async () => {
    const res = await API.get(APIS.PROPERTY_PARAMS.GET_STATES);
    if (res.data.status === 1) {
      setStates(res.data.data);
      const index = res.data.data.findIndex((state: any) => state.id === state_id);
      if (index > -1) {
        setLocationData({
          ...locationData,
          ...{ state: res.data.data[index].state },
        });
      }
    }
  };

  const fetchDistrict = async (state_id: number) => {
    const res = await API.get(APIS.PROPERTY_PARAMS.GET_DISTICTS + state_id);
    if (res.data.status === 1) {
      setDistricts(res.data.data);
      const index = res.data.data.findIndex((district: any) => district.id === district_id);
      if (index > -1) {
        setLocationData({
          ...locationData,
          ...{ district: res.data.data[index].district },
        });
      }
    }
  };

  const fetchCity = async (district_id: number) => {
    const res = await API.get(APIS.PROPERTY_PARAMS.GET_CITIES + district_id);
    if (res.data.status === 1) {
      setCities(res.data.data);
      const index = res.data.data.findIndex((city: any) => city.id === city_id);
      if (index > -1) {
        setLocationData({
          ...locationData,
          ...{ city: res.data.data[index].city },
        });
      }
    }
  };

  const onStateChange = (data: any) => {
    if (data.id !== locationData.state_id) {
      fetchDistrict(data.id);
      setLocationData({
        ...locationData,
        ...{
          state_id: data.id,
          state: data.state,
          district_id: 0,
          district: "",
          city_id: 0,
          city: "",
        },
      });
    }
  };

  const onDistrictChange = (data: any) => {
    if (data.id !== locationData.district_id) {
      fetchCity(data.id);
      setLocationData({
        ...locationData,
        ...{
          district_id: data.id,
          district: data.district,
          city_id: 0,
          city: "",
        },
      });
    }
  };

  const onCityChange = (data: any) => {
    if (data.id !== locationData.city_id) {
      setLocationData({
        ...locationData,
        ...{
          city_id: data.id,
          city: data.city,
        },
      });
    }
  };

  const updateMapLocation = () => {
    let geocoder = new google.maps.Geocoder();
    let address = locationData.location + ", " + locationData.city + ", " + locationData.state;
    geocoder.geocode({ address }, function (results: any, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        setLocationData({
          ...locationData,
          lat: results[0].geometry.location.lat(),
          long: results[0].geometry.location.lng(),
        });
      }
    });
  };

  return (
    <div className={style.location}>
      <div className={style.input_field_container}>
        <div className={style.input_group}>
          <div className={style.title}>State</div>

          {propertyFieldValidator(locationData, "state") && validation && (
            <div className={style.error}>Please select state</div>
          )}

          <div className={style.input_field}>
            <LocationDropdown
              width={"100%"}
              title={"State"}
              data={states}
              valueKey="state"
              selected={locationData.state_id}
              onChange={onStateChange}
            />
          </div>
        </div>

        <div className={style.input_group}>
          <div className={style.title}>District</div>
          {propertyFieldValidator(locationData, "district") && validation && (
            <div className={style.error}>Please select district</div>
          )}
          <div className={style.input_field}>
            <LocationDropdown
              width={"100%"}
              title={"District"}
              data={districts}
              valueKey="district"
              selected={locationData.district_id}
              onChange={onDistrictChange}
            />
          </div>
        </div>

        <div className={style.input_group}>
          <div className={style.title}>City</div>
          {propertyFieldValidator(locationData, "city") && validation && (
            <div className={style.error}>Please select city</div>
          )}
          <div className={style.input_field}>
            <LocationDropdown
              width={"100%"}
              title={"City"}
              data={cities}
              valueKey="city"
              selected={locationData.city_id}
              onChange={onCityChange}
            />
          </div>
        </div>

        <div className={style.input_group}>
          <div className={style.title}>Street/Area</div>
          {propertyFieldValidator(locationData, "location") && validation && (
            <div className={style.error}>Please select Street/Area</div>
          )}
          <div className={style.input_field}>
            <input
              type="text"
              placeholder="Add Streer/Area"
              value={locationData.location}
              onChange={(e) => {
                setLocationData({
                  ...locationData,
                  location: e.target.value,
                });
              }}
              onBlur={(e) => {
                if (beforeBlur !== e.target.value) {
                  setBeforeBlue(e.target.value);
                  if (pickLatLong) {
                    updateMapLocation();
                  }
                }
              }}
            />
          </div>
        </div>
        {pickLatLong && (
          <MapComponent
            lat={locationData.lat}
            long={locationData.long}
            onChange={(lat: any, long: any) => {
              setLocationData({ ...locationData, lat, long });
            }}
          />
        )}

        <div className={style.input_group}>
          <div className={style.title}>PIN</div>
          {propertyFieldValidator(locationData, "pin") && validation && (
            <div className={style.error}>Please select Pin</div>
          )}
          <div className={style.input_field}>
            <input
              type="text"
              placeholder="Add Pincode"
              value={locationData.pin}
              onChange={(e) => {
                setLocationData({
                  ...locationData,
                  ...{ pin: parseInt(e.target.value) },
                });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationComponent;
